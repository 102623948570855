import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import { shortlistAddRemoveResi,rejectlistAddRemoveResi } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import {
  Icon,
  Button,
} from "semantic-ui-react";



class PropResiSummary extends Component {
  state = {
    id:'',
  };
  
  shortlistAddRemoveHandler = (id) => {
    if (!this.props.userA) {
      this.props.openModal('LoginModal')

    }else{
      this.props.shortlistAddRemoveResi(id);
    }

  };
  //@@
  rejectlistAddRemoveHandler = (id) => {
    if (!this.props.userA) {
      this.props.openModal('LoginModal')

    }else{
      this.props.rejectlistAddRemoveResi(id);
    }

  };
  //@@
  handleOpenModalImage = (id,imageOf) => {   
    this.props.openModal("ShowImagesModal" , {id:id,imageOf:imageOf});
  };
  handleOpenModalContact = (forContactReq) => { 
    if (!this.props.userA) {
      this.props.openModal('LoginModal')
  
    }else{
      this.props.openModal("ContactDetailForPropListModal" , {forContactReq});
    }  
    
  };
  

//*******************************************************

  renderPropertySummary=()=> {
    const {
      imagePath,
      _id,
      propertyFor,
      category,
      propertyType,
      bedroom,
      bathroom,
      additionalRooms,
      furnishedStatus,
      expectedPrice,
      expectedRent,
      floorNo,
      showFloorNo, 
      status,
      projectName,
      projectType,
      locality,
      locationOnMap,
      //createdAt,
      //************ */
      // createdAtProp,
      // propOrProjId,
    } = this.props.propresi;

const id = this.props.propresi.propOrProjId || this.props.propresi._id;
const createdOn = this.props.propresi.createdAtProp || this.props.propresi.createdAt


    const renderadditionalRooms = () => {
      return additionalRooms.map(item => {
        return (
          <span key={item}>

            +{item}{' '}
          </span>  
        );
      });
    };


   // const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));
   const {
     userGroup,
    uname,
    uphone1,
    uphone2,
    uemail,
    uwhatsapp,
  
  } = this.props.userA !== null && this.props.userA !==undefined &&  this.props.userA 
  const  requesterId= this.props.userA !== null && this.props.userA._id        
   const {
    advertisedBy,
    expiredOn,
      name,
      phone1,
      phone2,
      email,
      whatsapp,
   } = this.props.propresi;
   const ownerContactDetail = {
    advertisedBy,
    expiredOn,
      name,
      phone1,
      phone2,
      email,
      whatsapp,
   }
  const contactReq= {
    
    requestFor:'Property',
  uname,
  uphone1,
  uphone2,
  uemail,
  uwhatsapp,
  requesterId,
  propOrProjId:id,
  propertyFor,
    propertyType,
    bedroom,
    additionalRooms,
    projectType,projectName,locality, expectedPrice, expectedRent,
    }
   
   const forContactReq ={
    ownerContactDetail,
    contactReq
   }
   
   const checkID = this.props.userA !== null && this.props.userA.shortlistResi !==undefined &&  this.props.userA.shortlistResi.filter(val => {

      return val.toString() === id;
    });

    const checkIdReject = this.props.userA !== null && this.props.userA.rejectlistResi !==undefined &&  this.props.userA.rejectlistResi.filter(val => {

      return val.toString() === id;
    });

    const setShortlistBgColor= () =>{
      if(checkID.length > 0){
        return {backgroundColor:'#e6f5ff'}
        }
        if(checkIdReject.length > 0){
      return {backgroundColor:'#ffb3b3'}
        }
    }
//&&&
const renderShortRejectAddButton = ()=>{
  if (!this.props.userA) {
    
  return (
    <>
    <span
onClick={() => this.props.openModal('LoginModal')}
className="icon-button ml-010"
>
<img className= 'yimg ' src='/shortlistIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
</span>
<span
onClick={() => this.props.openModal('LoginModal')}
className="icon-button"
>
<img className= 'yimg ' src='/rejectIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
</span>
  
</>
) }
//*** */
  }
  //@@@ 
const renderShortRejectAddRemoveButton = ()=>{
  if( (userGroup === 'Individual') && ((checkID.length > 0) || (checkIdReject.length > 0))) {
  if(checkID.length > 0) {
    return (
      <span
      onClick={() => this.shortlistAddRemoveHandler(id)}
      className="icon-button ml-010"
      >
      <img className= 'yimg ' src='/statusIconOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
      </span>
  ) }
  if(checkIdReject.length > 0) {
    return (
      <>
      <span
      onClick={() => this.rejectlistAddRemoveHandler(id)}
      className="icon-button ml-010"
      >
      <img className= 'yimg ' src='/statusIconNotOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
      </span>
      </>
  ) }
    }
    //&&&
  if( (userGroup === 'Individual') && ((checkID.length <= 0) && (checkIdReject <= 0))) {
    return (
      <>
      <span
 onClick={() => this.shortlistAddRemoveHandler(id)}
 className="icon-button ml-010"
 >
 <img className= 'yimg ' src='/shortlistIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 <span
 onClick={() => this.rejectlistAddRemoveHandler(id)}
 className="icon-button"
 >
 <img className= 'yimg ' src='/rejectIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
    
  </>
  ) }
  //*** */

}
  //@@@

const price = (value) => {
   var val = Math.abs(value)
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
  }
  /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
  return val;
} 

// const cardStatic = 'cardStatic-1'
// const card = 'card-1'
//const classN = (this.props.nameClass ==='static'? `${cardStatic}  ${setShortlistBgColor()} `:`${card} ${setShortlistBgColor()}`)
 const classN = (this.props.nameClass ==='static'? 'cardStatic-1':'card-1')
const classBG = (this.props.nameClass ==='static'? 'card border-2 cardBackground':'card border-2 ')

    return (
<div key={_id}  style={{margin:'5px'}}>




                <div className={classBG}  style={{padding:'0px',borderRadius:'5px',...setShortlistBgColor()}} >

                  <span className="yribbon1">
                    <span style={{ fontSize:'14px',color:'white'}}>
                    <Icon className="rupee" ></Icon>
                    <b>{(propertyFor === 'SALE' && expectedPrice >0 && price(expectedPrice)) || 
                  (propertyFor !== 'SALE' && expectedRent >0 && price(expectedRent))
                  }</b>
                    </span>
                  </span>

                {/* property summary section + image section */}
                <div className={classN} style={{padding:'10px',borderRadius:'5px',...setShortlistBgColor()}}>

                  {/* property summary section  */}
            <div className='flex-value-B 'style={{paddingRight:'10px',margin: 'auto'}}>


            <div>{bedroom && (
                <span  className='bedroom' >
                  {bedroom} BHK
                </span>
            )}
            {bathroom && (
                  <span  className='bathroom' >
                  { ' '}+{bathroom} Bath{' '}
                  </span>
              )}
              {additionalRooms && additionalRooms.length >0 && (
                    <span  className='additionalRooms' >
                    { ' '}{renderadditionalRooms()}{ ' '}
                    </span>
                )}

            {furnishedStatus && (
                <span className='furnishedStatus' >
                 { ' '} {furnishedStatus}{ ' '}
                </span>
            )}{ ' '}
            {showFloorNo === 'Yes' && floorNo && (
                <span  className='floorNo'>
                 { ' '} {floorNo} Floor{ ' '}
                </span>
            )}{ ' '}
            {status && (
                <span className='status' >
                 { ' '} {status}{ ' '}
                </span>
            )}{ ' '}
              
            {category && (
                <span className='category' >
                  { ' '}{category}{ ' '}
                </span>
            )}{ ' '}
            {propertyType && (
              <span>
                <span className='propertyType' >
                { ' '} {propertyType}{ ' '}
                </span>
                <span className='font-14B' style={{color:'black' }}>
                  { ' '} for{ ' '}
                </span></span>
            )} { ' '}
            {propertyFor && (
              <span>
                <span className='propertyFor' style={{color:'rgb(226, 72, 1)' }}>
                { ' '} {propertyFor}{ ' '}
                </span>
                </span>
            )} { ' '}

            </div>  {/* for property type*/}

{/* project and location section for desktop */}
<div className=' nodisplay pxy-10'  >
           <div>
             {projectName && (
                <div className='projectName' >{projectName}</div>
            )}
            </div>  {/* for project name*/}

            <div className='locality' >{locality && (
              <span>
                <span >
                  {locality} Dwarka New Delhi{' '}
                </span>{' '}
              <a target='_blank'  rel="noreferrer noopener" href={`${locationOnMap}`}>
              <span className="ml-05">
              {' '} <img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
              </span>
              </a>
              </span>

            )}
            </div>  {/* for location and city etc.*/}
          
            </div>


            <div className='display-flex a-i-center nodisplay '  >

                <Button
               // inverted
                floated="right"
                color="teal"
                  // basic
                  // inverted

                  type="button"
                  as={Link}
                  to={`/propresi/PropResiDetail/${id}`}
                >
                Property Detail
                </Button>
                <Button
                  // basic

                 // inverted
                  floated="right"
                  color="blue"
                  type="button"
                  onClick={()=>this.handleOpenModalContact(forContactReq)}
                >
                  Contact Detail
                </Button>
               
{renderShortRejectAddRemoveButton()}
{renderShortRejectAddButton()} 
              </div>

                  </div>
                  {/* end property summary section  */}
                  {/* image section */}
                  <div className='flex-value-A' style={{display:'flex',flexDirection:'column'}}>

                  <div onClick={()=>this.handleOpenModalImage(id,'Property')}>
                  <img className= 'yimg image-respo' src={`${imagePath}`} alt="Display_Image" width="170" height="128" />
            </div>
            <div style={{display:'flex',fontSize:'.75rem',fontWeight: 'normal'}}>
            <div>{createdOn && (

                <span>  Advertised On
                   <span> { format(new Date(createdOn), "dd-MM-yyyy")}</span> by {advertisedBy=== "Owner"? "Owner":"Others" }
            </span>

            )}
            </div>
            </div>
                  </div>
                  {/*end image section  */}

                  </div>
{/* project and location section for mobile */}
                  <div className='nodisplay-tab pxy-10'>

                  <div>
             {projectName && (
                <div className='projectName' >{projectName}</div>
            )}
            </div>  {/* for project name*/}

            <div className='locality' >{locality && (
              <span>
                <span >
                  {locality} Dwarka New Delhi{' '}
                </span>{' '}
              <a target='_blank'  rel="noreferrer noopener" href={`${locationOnMap}`}>
              <span className="ml-05">
              {' '} <img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
              </span>
              </a>
              </span>

            )}
            </div>  {/* for location and city etc.*/}
                  </div>
                  {/*  */}
                  <div className='display-flex a-i-center nodisplay-tab' style={{marginBottom:'5px'}}  >

                <Button
               // inverted
                floated="right"
                color="teal"
                  // basic
                  // inverted

                  type="button"
                  as={Link}

                  to={`/propresi/PropResiDetail/${id}`}
                >
                Property Detail
                </Button>
                <Button
                  // basic

                 // inverted
                  floated="right"
                  color="blue"
                  type="button"
                  onClick={()=>this.handleOpenModalContact(forContactReq)}
                >
                  Contact Detail
                </Button>
                
                {renderShortRejectAddRemoveButton()}
                {renderShortRejectAddButton()}

              </div>
                  </div>
</div>
    );
  }

  // ********** 

  render() {
  
    return (

<div >


{this.renderPropertySummary()}

</div>



    );
  }
}


const mapStateToProps = ({ auth }) => {
 
  return {  
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {openModal, shortlistAddRemoveResi,rejectlistAddRemoveResi}
)(PropResiSummary);



