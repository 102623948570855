import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../common/Loading"; 
import {allSalePropertiesInProjectC} from "../../actions/propcomAction";
import PropComSummary from './PropComSummary';
import {price} from '../functions/Functions';


class AllPropComSaleList extends Component {
  

  componentDidMount() {
    const values = {projectId:this.props.projectId};
    this.props.allSalePropertiesInProjectC(values);
  }
  
    //@@@
componentDidUpdate(prevProps) {
  //Typical usage, don't forget to compare the props
  if (this.props.projectId !== prevProps.projectId) {
    const values = {projectId:this.props.projectId};
    this.props.allSalePropertiesInProjectC(values);
    
  }
   }
//@@@
  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }
  renderNoProperty=()=> {
    if (this.props.propcomsCS.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE FOR RENT</b></span>

        </div>
      );
    }
  }


  render() {
    const minVal = ()=> Math.min(...this.props.propcomsCS.map((item)=>item.expectedPrice))
    const maxVal = ()=> Math.max(...this.props.propcomsCS.map((item)=>item.expectedPrice))

    return (


<div>



{/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2'>
<span  > {this.props.countCS} Commercial Property For Resale ({this.props.propcomsCS.length >0 ? price(minVal()):0} ~ {this.props.propcomsCS.length >0 ? price(maxVal()): 0} )</span>
</div>
      
</div>

    <div className='card-attached border-sb pxy-05'  >
    {this.renderNoProperty()}
    {this.props.propcomsCS.map((item)=> (<PropComSummary   key={item._id}  propcom={item}/>))}
    </div>
{/*  */}
</div>

    );
  }
}



const mapStateToProps = ({ propcoms,auth, images }) => {
  const { fetchingCS,limitCS, offsetCS, countCS } = propcoms;

  return {
    fetchingCS,
    limitCS,
    offsetCS,
    countCS,
    //form: form.PropComSaleSearch,
    propcomsCS: propcoms.propcomsCS,
    userA: auth.userA,
    imagesPRY:images.imagesPRY,
  };
};

export default connect(
  mapStateToProps,
 {allSalePropertiesInProjectC,}
)(AllPropComSaleList);
