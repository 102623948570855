import React, { Component } from "react";
import { connect } from "react-redux";

import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import {fetchShortlistProjectC} from "../../actions/projectCAction";

import { openModal } from "../../actions/modalAction";

import ProjectCSummary from './ProjectCSummary';


class ShortlistProjectC extends Component {
  state = {
    pageO:'',
    id:'',
  };
  componentDidMount() {
    const {offsetSH , limitSH} = this.props;
      
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    
    
    if (this.props.auth.isAuthenticated) {
     
      this.props.fetchShortlistProjectC(values, limitSH,offsetSH);
    }else{
      
      this.props.openModal('LoginModal')
    }
  }

    
    //^^^^^^^^^^^^^^^^^^^^^^^^^
      onChange=(e) => {
        this.setState({ [e.target.name]: e.target.value });
      }
    //*******************************************************

  renderGoToPageOrNot=()=> {
    if (this.props.projectCs.length) {
      return (
        <div className='card-attached border-sb '  >
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()} 
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
</div>
      );
    }
  }

  //************************
  

  back = () => {
    const {
      offsetSH,
      limitSH
      
    } = this.props;
    
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offsetSH === 0) {
      return;
    }

    this.props.fetchShortlistProjectC(values, limitSH, (offsetSH - limitSH));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offsetSH,
      limitSH,
      countSH
     
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offsetSH + limitSH > countSH) {
      return;
    }

    this.props.fetchShortlistProjectC(values, limitSH, (offsetSH + limitSH));
    window.scroll(0,0)
  };
//***************** */
handleGoToPage = () => {
  const {
   
    limitSH,
    countSH
    
  } = this.props;

  const values =
    (this.props.form !== undefined && this.props.form.values) || {};
   // use Math.abs for strict equality ===  to work and safe guard against negative value from user
   if (Math.abs(this.state.pageO) === 0) {
    return; 
  }

const page=(Math.abs(this.state.pageO*limitSH) > countSH)?(Math.ceil(countSH/limitSH-1)):(Math.abs(this.state.pageO)-1)


  this.props.fetchShortlistProjectC(values, limitSH, (page * limitSH));
    window.scroll(0,0)

};

//****************** */
  renderPaginator=()=> {
    if (this.props.projectCs.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offsetSH}
          limit={this.props.limitSH}
          count={this.props.countSH}
          
        />
      );
    }
  }


  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }

  renderNoProject=()=> {
    if (this.props.projectCs.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROJECT AVAILABLE</b></span>

        </div>
      );
    }
  }
  render() {
    
    return (

  <div>
  <div className='maindiv-820'>
  

  <div>
  

  

   
{/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2'>
<span  > {this.props.countSH}{' '} Commercial Projects</span>
</div>
      
</div>

    <div className='card-attached border-sb pxy-05'  >
    {this.renderLoading()}
    {this.renderNoProject()}
  {this.props.projectCs.map((item)=> (<ProjectCSummary  key={item._id}   projectCom={item}/>))}

    </div>
{/*  */}
  
{this.renderGoToPageOrNot()}

  <br/><br/><br/>
  </div>
  


  </div>
  </div>

    );
  }
}


const mapStateToProps = ({ projectCs, selection, form ,auth,}) => {
  const { fetching,limitSH, offsetSH, countSH,} = projectCs;

  return {
    auth,
    limitSH,
    offsetSH,
    countSH,
    fetching,
    form: form.ProjectCSearch,
    selection,
    projectCs: projectCs.projectCs,
    userA: auth.userA,
  };
};
export default connect(
  mapStateToProps,
  {fetchShortlistProjectC,openModal}
)(ShortlistProjectC);
