import React from 'react';
import { startOfToday,endOfToday,formatDuration,intervalToDuration, } from 'date-fns'
import { setCurrentUser } from "../../actions/authAction";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../utils/setAuthToken";
import store from "../../store";
import { createBrowserHistory } from "history";
import { FETCH_USER,} from "../../actions/types";
import { toastr } from "react-redux-toastr";
const history = createBrowserHistory()
//****************** ALSO WORKING
// export const days = value => {
//   let duration = intervalToDuration({
//       start: new Date(value),
//       end: new Date(),
//   })
//   const units = ['years', 'months', 'weeks', 'days']
//   const nonzero = Object.entries(duration).filter(([ value]) => value || 0 > 0).map(([unit]) => unit)

// return formatDuration(duration, {
//   format: units.filter(i => new Set(nonzero).has(i)).slice(0, 3),
//   delimiter: ', '
// })

// };
//@@@ BUT BETTER
export const days = value => {
  let duration = intervalToDuration({
      start: new Date(value),
      end: new Date(),
  })
  
return formatDuration(duration,{ format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'] , delimiter: ', '})

};
//@@@
//^^^^^^^^^^^^^^^
export const period =(value)=>{

  if( ( Date.parse(startOfToday()) < Date.parse(value) ) && ( Date.parse(endOfToday()) > Date.parse(value) ) )
  {
    return(
      <span>Today</span>
    )
  }else if((Date.parse(startOfToday()) < Date.parse(value))){

      if(days(value)){
        return(
          <span>in {days(value)}</span>
        )
      }else{
        return(
          <span>Tommorrow</span>
        )
      }

    }else if((Date.parse(startOfToday()) > Date.parse(value))) {
      if(days(value)){
        return(
            <span>{days(value)} ago</span>
        )
      }else{
        return(
          <span>Yesterday</span>
        )
      }
    }

}
//*************
export const price = (value) => {
  var val = Math.abs(value)
 if (val >= 10000000) {
   val = (val / 10000000).toFixed(2) + ' Cr';
 } else if (val >= 100000) {
   val = (val / 100000).toFixed(2) + ' Lac';
 }
 /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
 return val;
} 
//@@@  
export const renderStartEnd = (itemDuration)=>{
  const dateS   = startOfToday();
  if(itemDuration==="All"){
    return {start:{}, end:{}}
  }
  if(itemDuration==="Next Seven day's"){
    const start   = dateS;
    const end   =new Date().setDate(endOfToday().getDate()+7);
   return {start, end}
  }
  if(itemDuration==="Tommorrow's"){
    const start = (Date.parse(dateS)+86400000);
    const end   =(Date.parse(dateS)+86400000*2);
    return {start, end}
  }
  if(itemDuration==="Yesterday's"){
    const start = (Date.parse(dateS)-86400000);
    const end   =(Date.parse(dateS)-5000);
    return {start, end}
  }
  if(itemDuration==="Today's"){

    const start   = dateS;
    const end   = endOfToday();
    return {start, end}
  }


}
//@@@
export const color =(value)=>{
  if((Date.parse(startOfToday()) > Date.parse(value))){
    return 'red'
  }
  if( ( Date.parse(startOfToday()) < Date.parse(value) ) && ( Date.parse(endOfToday()) > Date.parse(value) ) )
  {
    return 'orange'
  }
  if((Date.parse(startOfToday()) < Date.parse(value))){
    return 'yellow'
  }

  }


  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  export const colorAlert =(value)=>{
  if(value=== 'Critical'){
    return 'red'
  }
  if(value=== 'High'){
    return 'orange'
  }
  if(value=== 'Normal'){
    return 'yellow'
  }
  if(value=== 'Low'){
    return 'green'
  }
}
//%%%%
export const capitalizeFirst = (input) => {
  if(input){
  var words = input.split(' ');
  var CapitalizedWords = [];
  words.forEach(element => {
    CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
  });
  return CapitalizedWords.join(' ');
}
}

  
export const logoutUser = () => async dispatch => {
  try {      
  // Remove token from localStorage
  localStorage.removeItem("jwtToken"); 
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch({ type: FETCH_USER, payload: {userA:null,user:{}} });
  toastr.success('Logout Successfully') 
  history.push("/");
  window.location.reload(false)
} catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again");
}
};

export const handleSetRemoveAuth =()=>{
if (localStorage.jwtToken){
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  store.dispatch(setCurrentUser({}));
  store.dispatch({ type: FETCH_USER, payload: {userA:null,user:{}} });
  history.push("/");

  }
}
}



  

