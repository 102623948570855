import {
  FETCH_IMAGES,
  FETCH_IMAGE,
  SEARCH_IMAGES,
  FETCHING_IMAGES,
} from '../actions/types';

const INITIAL_STATE = {
  fetching:true,
  images: [],
  imagesPRY: [],
  imagesPRT: [],
  image: null,
  // for info images
  imagesInfo: null,
  imagesMy: null,
  offset: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_IMAGES:
      return { ...state, ...action.payload };
    case FETCH_IMAGE:
      return { ...state, image: action.payload };
    
    case SEARCH_IMAGES:
      return { ...state, ...action.payload };
      case FETCHING_IMAGES:
        return { ...state, fetching: action.payload };
    
    default:
      return state;
  }
};
