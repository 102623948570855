import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {fetchMyCompany} from "../../actions/companysAction";
import { openModal } from "../../actions/modalAction";
import FinancerPropResiList from "../propertyResi/FinancerPropResiList";
import FinancerPropComList from "../propertyCom/FinancerPropComList";
import format from "date-fns/format";
import ViewImages from "./ViewImages";

import {
  Divider,
  Button,
  Modal,
  Image
} from "semantic-ui-react"; 




class MyCompanyDetail extends Component {
  state = {
      modalOpenImg: false,
    };

  componentDidMount() {
    window.scroll(0,0)
    if (this.props.auth.isAuthenticated) {
     
      this.props.fetchMyCompany();
    }else{
      
      this.props.openModal('LoginModal')
    }
  }

  //**********************************
  handleOpenImg = () => {
    this.setState({ modalOpenImg: true,})
}

  handleCloseImg = () => this.setState({ modalOpenImg: false })
//*********** */
  render() {


    const rendertypeOfDealings = () => {
      return typeOfDealings.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} <span className='pipe'>|</span></b>
          </span>
        );
      });
    };
    const rendertypeOfPropertyDealsIn = () => {
      return typeOfPropertyDealsIn.map(item => {
        return (
          <span key={item}>
            {" "}
            <b>{item} <span className='pipe'>|</span></b>
          </span>
        );
      });
    };
    if (!this.props.company) {
      return (
        <div><span style={{marginLeft: '200px',fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
    const {
      reraNo,
      cname,
      cmobile1,
      cmobile2,
      cemail,
      website,
      cimage,
      address,
      locality,
      city,
      state,
      pincode,
      operatingSince,
      nameCP1,
      mobileCP1,
     // emailCP1,
      designationCP1,
      photoCP1,
      nameCP2,
      mobileCP2,
     // emailCP2,
      designationCP2,
      photoCP2,
      typeOfDealings,
      typeOfPropertyDealsIn,
      locationOnMap,
      description,
      _id
    } = this.props.company;

    
    //***************************
// const values= {
//   _id
// }
//**********************

    const renderButton = () =>
      this.props.userA !== null &&
        this.props.userA.userType === "admin" ? (
          <Button.Group size="mini" floated="right">
            <Button
                // basic
                // inverted
                floated="right"
                color="blue"
                type="button"
                onClick={this.handleOpenImg}
              >
                View Image
              </Button>
            <Button
              // basic
              // inverted
              floated="right"
              color="orange"
              type="button"
              as={Link}
              to={`/company/addimage`}
            >
              Add Image
            </Button>
            <Button
              // basic
              // inverted
              floated="right"
              color="olive"
              type="button"
              as={Link}
              to={`/company/editcompany/${_id}`}
            >
              Edit
            </Button>
          </Button.Group>
      ) : (
        <div></div>
      );


      //*******************************************************
return (
  <div>
   <div className='maindiv-768'>

{/**/}
<div>
      <Modal
          // trigger={<Button onClick={this.handleOpen}>Show Modal</Button>}
          open={this.state.modalOpenImg}
          onClose={this.handleCloseImg}
          basic
          closeOnEscape={false}
          closeOnDimmerClick={false}
          // size='small'
          style = {{
            backgroundColor:'black',
        margin:'0px',
        padding: '0px',
            maxWidth:'768px'
          }}
        >

          <Modal.Content>
      <ViewImages
     handleCloseImg ={this.handleCloseImg}
      />
          </Modal.Content>
          
        </Modal>
        </div>
        {/**/}
  <div>    {/* start */}

  <div>
              {/*  */}
              <div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
              <div className='heading-2'>
              <span  > {cname}</span>
              </div>
              </div>
              {/*  */}
      
              <div className='card-attached border-sb pxy-20'>
                   
          
<div className='media-600 ' style={{display:'flex',justifyContent:'center'}}> {/*start of div containing cp1 and cp2 detail */}
      
      { nameCP1 &&  (<div className='card-cp' > {/* cp1 detail*/}
              <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
              <img src={`${photoCP1}`} alt="Photo_First contact person" style={{width:"100px", height:"100px",padding:'5px'}} />
              </div>


        <div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


        <div>{nameCP1 && (
            <div >
              {nameCP1}
            </div>
        )}
        </div>  {/* */}

        <div>{designationCP1 && (
            <div>{designationCP1}</div>
        )}</div>  {/* */}

        <div>{mobileCP1 && (

            <span >
              {mobileCP1}
            </span>
        )}</div>  {/* */}

        

              </div>
              </div>)}
    {/*end cp1 details */}
             
              { nameCP2 &&  (<div className='card-cp' >{/* cp2 detail*/}
              <div className='flex-value-DA' style={{display:'flex',flexDirection:'column'}}>
              <img src={`${photoCP2}`} alt="Photo_Second contact person" style={{width:"100px", height:"100px",padding:'5px'}} />
              </div>


        <div className='flex-value-DB 'style={{padding:'10px',fontWeight:"bold"}}>


        <div>{nameCP2 && (
            <div >
              {nameCP2}
            </div>
        )}
        </div>  {/* */}
        <div>{designationCP2 && (
            <div>{designationCP2}</div>
        )}</div>  {/* */}
        <div>{mobileCP2 && (

            <span >
              {mobileCP2}
            </span>


        )}</div>  {/* */}
        

              </div>
              </div>)}
          </div> {/*end of div containing cp1 and cp2 detail */}
{/* */}

          <br />
          <div style= {{ marginBottom:'35px'}}>
            {renderButton()}
          </div>
          
          <div>
          
          <Divider fitted />
            <div >
            {address && (
              <div className='row'>
                <div className='side'>
                <Image
                  floated="left"
                  size="tiny"
                  src={`${cimage}`}
                />
                </div>
                <div className='main'style={{ display:'flex',alignItems:'center'}} >
         <b>{address}{' '}{locality}{' '}{city}{' '}{state}{' '}{pincode}
         {' '} <span>
          <a target='_blank'  rel="noreferrer noopener"   href={`${locationOnMap}`}>

          <span style={{fontSize:'1.0rem',color:'blue' }}>
          {' '}<img className= 'yimg' src='/mapIcon.png' alt="Location on Map" style={{width:"16px", height:"16px"}} />
          </span>
          </a>
          </span>
          </b>
       </div>

              </div>
            )}
          </div>
          <div >
          {cmobile1 && (
            <div className='row'>
              <div className='side'>
                <b>Phone</b>
              </div>
              <div className='main'>
                <b>{cmobile1}</b>
                {cmobile2 && (

                <b>,{' '} {cmobile2}</b>

                  )}
              </div>

            </div>
          )}
        </div>

        <div >
        {cemail && (
          <div className='row'>
            <div className='side'>
              <b>Email</b>
            </div>
            <div className='main'>
              <b>{cemail}</b>
            </div>

          </div>
        )}
      </div>
      <div >
      {website && (
        <div className='row'>
          <div className='side'>
            <b>Website</b>
          </div>
          <div className='main'>
            <b>{website}</b>
          </div>

        </div>
      )}
    </div>
    
    <div >
    {reraNo && (
    <div className='row'>
    <div className='side'>
    <b>Company's RERA No.</b>
    </div>
    <div className='main'>
    <b>{reraNo}</b>
    </div>

    </div>
    )}
    </div>

        
    <div >
    {operatingSince && (
      <div className='row'>
        <div className='side'>
          <b>Operating Since</b>
        </div>
        <div className='main'>
          <b>{format(new Date(operatingSince), "dd-MM-yyyy")}</b>
        </div>

      </div>
    )}
  </div>



          <div >
           {typeOfDealings != null &&
                        typeOfDealings.length >= 1 && (
            <div className='row'>
              <div className='side'>
                <b>Type of Dealings</b>
              </div>
              <div className='main'>
                {rendertypeOfDealings()}
              </div>

            </div>
          )}
         </div>
         <div >
         {typeOfPropertyDealsIn != null &&
                      typeOfPropertyDealsIn.length >= 1 && (
            <div className='row'>
              <div className='side'>
                <b>Type Of Property deals in</b>
              </div>
              <div className='main'>
                 {rendertypeOfPropertyDealsIn()}
              </div>

            </div>
          )}
         </div>

         <div >
{description && (
<div className='rowERP-projName'>
<div className='side'>
<b>About Company</b>
</div>
<div className='main display-linebreak'>
<b>{description}</b>
</div>

</div>
)}
</div>

         


          </div>

          

      </div>
    </div>


    <div className='card-attached border-sb border-db-bottom '  >
              <FinancerPropResiList id={_id}  />
              <FinancerPropComList id={_id}  />
      
              </div>
  
  </div>
  {/* end */}
  


  </div>


  <br/><br/><br/><br/>

  </div>

  //**********************************



);

//*************************************************************

  }
}

function mapStateToProps(state) { 
 
  return { 
    company: state.companys.company,
    userA: state.auth.userA,
    auth:state.auth,
  };
}

export default connect(
  mapStateToProps,
  {fetchMyCompany, openModal}
)(withRouter(MyCompanyDetail));
