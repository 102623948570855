import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../common/Loading"; 
import {allRentPropertiesInProjectC} from "../../actions/propcomAction";
import PropComSummary from './PropComSummary';
import {price} from '../functions/Functions';


class AllPropComRentList extends Component {
  state = {
    id:'',
  };

  componentDidMount() {
    const values = {projectId:this.props.projectId};
    this.props.allRentPropertiesInProjectC(values);
  }

    //@@@
componentDidUpdate(prevProps) {
  //Typical usage, don't forget to compare the props
  if (this.props.projectId !== prevProps.projectId) {
    const values = {projectId:this.props.projectId};
    this.props.allRentPropertiesInProjectC(values);
    
  }
   }
//@@@
  
  
  renderLoading=()=> {
    if (this.props.fetchingCR===true) {
      return (<Loading/>)
    }
  }
  renderNoProperty=()=> {
    if (this.props.propcomsCR.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE FOR RENT</b></span>

        </div>
      );
    }
  }


  render() { 
    const minVal = ()=> Math.min(...this.props.propcomsCR.map((item)=>item.expectedRent))
    const maxVal = ()=> Math.max(...this.props.propcomsCR.map((item)=>item.expectedRent))

    return (


<div>


    
    <div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
    
    <div className='heading-2'>
<span  > {this.props.countCR} Commercial Property For Rent ({this.props.propcomsCR.length >0 ? price(minVal()):0} ~ {this.props.propcomsCR.length >0 ? price(maxVal()): 0} )</span>
</div>
      
          </div>

    <div className='card-attached border-sb pxy-05'  >
    {this.renderNoProperty()}
    {this.props.propcomsCR.map((item)=> (<PropComSummary  key={item._id}   propcom={item}/>))}
    </div>


</div>

    );
  }
}



const mapStateToProps = ({ propcoms,auth,  }) => {
  const { fetchingCR,limitCR, offsetCR, countCR } = propcoms;

  return {
    fetchingCR,
    limitCR,
    offsetCR,
    countCR,
    //form: form.PropComRentSearch,
    propcomsCR: propcoms.propcomsCR,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {allRentPropertiesInProjectC,}
)(AllPropComRentList);
