import {
  FETCH_CONTACTUSS,
  FETCH_CONTACTUS,
  SEARCH_CONTACTUSS,
  FETCHING_CONTACTUSS,
} from '../actions/types';

const INITIAL_STATE = {
  fetching:true,
  contactUss: [],
  contactUs: null,
  offset: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONTACTUSS:
      return { ...state, ...action.payload };
    case FETCH_CONTACTUS:
      return { ...state, contactUs: action.payload };
    
    case SEARCH_CONTACTUSS:
      return { ...state, ...action.payload };
      case FETCHING_CONTACTUSS:
        return { ...state, fetching: action.payload };

    default:
      return state;
  }
};
