import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, Route,  } from "react-router-dom";
import { showBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";
import {fetchAllProjectCs} from '../../actions/projectCAction';
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import PropComCreate from "./PropComCreate";

import PropComDetail from "./PropComDetail";


import PropComSaleSearchList from "./PropComSaleSearchList";
import MyPropComList from "./MyPropComList";
import PropComRentSearchList from "./PropComRentSearchList";
import ShortlistCom from "./ShortlistCom";

import AddDealerForProp from "./AddDealerForProp";
import Layout from "../layout/Layout";

class PropComDashboard extends Component {
  componentDidMount() {
    if(this.props.auth.isAuthenticated){
      this.props.getUserForAuth();
    }
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }
    if(this.props.state.projectCs.projectCsForData.length <1){
      this.props.fetchAllProjectCs();
      
    }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };
  render() {

  return (
<div className="mt-10">
<div className='maindiv'>


<Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
      {/* <Redirect exact from="/propcom" to="/propcom/PropComSaleList" /> */}
      
      <Route exact path="/propcom/addDealer/:id" component={AddDealerForProp} />

      <Route
        exact
        path="/propcom/PropComCreate"
        component={PropComCreate}
      />
      <Route
        exact
        path="/propcom/PropComDetail/:id"
        component={PropComDetail}
      />
      


      <Route
        exact
        path="/propcom/MyPropComList"
        component={MyPropComList}
      />
      <Route
        exact
        path="/propcom/PropComSaleSearchList"
        component={PropComSaleSearchList}
      />

      <Route
        exact
        path="/propcom/PropComRentSearchList"
        component={PropComRentSearchList}
      />
      <Route
        exact
        path="/propcom/ShortlistCom"
        component={ShortlistCom}
      />
    </Switch>
    </Layout >
    </div>

<div >
    <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
</div>

</div>
  );
}
}
const mapStateToProps = state => ({
auth: state.auth,
state
});


export default connect(
   mapStateToProps,
  
  { showBackButton,getUserForAuth, logoutUser ,fetchAllProjectCs }
)(PropComDashboard);
