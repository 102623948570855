import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import {updateDealerRanking} from "../../actions/dealerSuggestedAction";
import { getUserForAuth } from "../../actions/authAction";


class CreateDealerSuggested extends Component {

  state = {  
        creditRequireForAd:0 ,
        creditRequireToLock:0,
        adlockAllowed:'',
        adAllowedToDealers:'',
        idsOfDealerAllowedToAd:[],
        adAllowedNumber :0 ,
        idsOfAdvertiser:[] ,
        idOfDealerWhoLockedAd:[] ,
        creditExpiredOn:'',
        balanceCreditProp:0,
        companyId:'',
    
  };

  componentDidMount = async ()  =>{
    
    const { propOrProjId } = this.props.valueDS;
		const res = await axios.get(`/api/PropertyRCreditDetail/${propOrProjId}`);
    
    this.setState({
				creditRequireForAd :res.data.creditRequireForAd ,
				creditRequireToLock :res.data.creditRequireForAd * res.data.adAllowedNumber ,
				//@@
        adlockAllowed :res.data.adlockAllowed ,
        adAllowedToDealers :res.data.adAllowedToDealers ,
        idsOfDealerAllowedToAd :res.data.idsOfDealerAllowedToAd ,
        adAllowedNumber :res.data.adAllowedNumber ,
        idsOfAdvertiser:res.data.idsOfAdvertiser ,
        idOfDealerWhoLockedAd:res.data.idOfDealerWhoLockedAd ,

        creditExpiredOn :res.data.creditExpiredOn,
        balanceCreditProp :res.data.balanceCreditProp,
        companyId:res.data.companyId,
      });
      this.closeModal()
  }

    //*******************************************
    closeModal =()=>{
      setTimeout(this.props.handleCreateModalClose,15000)
    }   


componentWillUnmount() {
  clearTimeout(this.closeModal);
}
 // *****************************************
  
  handleCreateAd = async (values) => {
    if((Date.parse(this.state.creditExpiredOn) > Date.now())  && (this.state.balanceCreditProp >= (values.creditUsed))){
     
      try {
       
        const res = await axios.post(`/api/DealerSuggestedCreate`, values);
        
        if (res.data.msg ==='errorAdLocked'){
          toastr.error("This Space for Advetising is already Locked by another Dealer. You can not advertise here");
        }
        if (res.data.msg ==='errorCredit'){
          toastr.error("You Do Not Have Enough Credit To Create Ad");
        }
        if (res.data.msg ==='errorAdLockNotAllowed'){
          toastr.error("This Space for Advetising can not be Locked by single Dealer.");
        }
        if (res.data.msg ==='errorMaxAd'){
          toastr.error("This Space for Advetising already have maximun number of ad allowed. You can not advertise here");
        }
        
        if (res.data.msg ==='errorNotAllowed'){
          toastr.error("You can not advertise here.");
        }
        if (res.data.msg ==='success'){
          this.props.updateDealerRanking();
          this.props.handleRefresh()
          this.props.handleCreateModalClose()
        toastr.success('Ad Created Successfully');
        }
        
      } catch (error) {
      
        toastr.error("Oops", "Something went wrong.Please Try Again CreateAdAndLock");
      }
    }else if (Date.parse(this.state.creditExpiredOn) < Date.now()){
      toastr.error("Your Credit Have Expired");
  
    }else{
      toastr.error("You Do Not Have Enough Credit To Create Ad");
  
    }
    
  };

  render() { 
    const valCreate = {...this.props.valueDS,creditUsed : this.state.creditRequireForAd}
    const valCreateLock = {...this.props.valueDS,creditUsed : (this.state.creditRequireForAd*this.state.adAllowedNumber),adlocked:'Yes'}
  

  const renderCreateAdAndLockButton = ()=>{
    if((this.state.idsOfAdvertiser.length < 1 ) && (this.state.idOfDealerWhoLockedAd.length < 1 ) && (this.state.adlockAllowed === 'Yes')){
    return(
        <span
        className = 'btnNumber ml-002 bgColor-indigo'
        onClick={()=> this.handleCreateAd (valCreateLock)}
        >
        Create Ad & Lock
        </span>
    )
  }
  }
//*********** */
const renderFieldAndButton = ()=>{
  return(
    <>
    <div >
      { (
      <div className='row'>
        <div className='side flex-70'>
        <b>Total Credit Available</b>
        </div>
        <div className='main flex-30'>
        <b>{this.state.balanceCreditProp}{' '}</b>{' '}
        </div>

      </div>
      )}
      </div>

      <div >
      {this.state.creditRequireForAd && (
      <div className='row'>
        <div className='side flex-70'>
        <b>Credit Required To Create Ad</b>
        </div>
        <div className='main flex-30'>
        <b>{this.state.creditRequireForAd}{' '}</b>{' '}
        </div>

      </div>
      )}
      </div>
      <div >
      {this.state.creditRequireForAd && (
      <div className='row'>
        <div className='side flex-70'>
        <b>Credit Required To Create Ad and Lock</b>
        </div>
        <div className='main flex-30'>
        <b>{(this.state.creditRequireForAd*this.state.adAllowedNumber)}{' '}</b>{' '}
        </div>

      </div>
      )}
      </div>
      
      
      <div className='row'>
        <div className='side flex-70'>
        <b>Ad Will be Created For Days</b>
        </div>
        <div className='main flex-30'> 
        <b>{this.props.valueDS.propertyFor==='SALE'? 30:30} Days{' '}</b>{' '}
        </div>

      </div>
      {/* )}
      </div> */}
      <br/>
      <div className = 'disflex-paginator'>
      <span
        className = 'btnNumber ml-002'
        onClick={()=> this.handleCreateAd (valCreate)}
      >
        Create Ad
      </span>
      {renderCreateAdAndLockButton()}
      <span
        className = 'btnNumber ml-002 bgColor-dodgerblue'
        onClick={this.props.handleCreateModalClose}
      >
        cancel
      </span>
    </div>
    </>
)
}

  
    //*************** */
    return (
      <div className = 'maindiv-768' >
        {/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Create Ad</span>
	</div>

	</div>

	<div className='card-attached border-radius-bottom border-sb pxy-10 mb-010'  >

{/*  */}
      
      {renderFieldAndButton()}
{/*  */}
        </div>
      </div>

    );
  }
}


export default connect(
  null,
  {updateDealerRanking, getUserForAuth}
)(CreateDealerSuggested)
