import React from "react";


const Loading = () => {
  return (
    <div className='card-attached  border-db' style={{textAlign:'center',}}>
      {/*  */}
      
  <div className="loader">
  <span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>
<br/>
    <div id="ld3">
      <div>
      </div>
      <div>
      </div>
      <div>
      </div>
      <div>
      </div>
    </div>
    {/*  */}
    <div id="ld2">
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
          <div>
          </div>
        </div>
    {/*  */}
  </div>
  
      {/*  */}
      
    </div>
  );
};

export default Loading

