import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {fetchUser,updateUser} from "../../actions/authAction";
import { withRouter } from "react-router-dom";

import { Segment, Form, Button,  Header,  } from "semantic-ui-react";
import TextInput from "../form/TextInput";
// import TextArea from "../form/TextArea";
// import SelectInput from "../form/SelectInput";


class UserEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchUser(id);
  }

  onSubmit = values => {
    const { id } = this.props.match.params;
    this.props.updateUser(id, values, this.props.history);
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    
    return (
      <div style={{maxWidth:'700px',margin:'auto'}}>

           
        <Segment
          textAlign="center"
          attached
          inverted
          color="teal"
          style={{ border: "none" }}
        >
        <Header
               // color="blue"
               content="Edit User"
              />
        </Segment>
        <div className="card-form ">
          <div className=" label" >


            <Form
              style={{ padding: 10 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >
                <Button
            color="teal"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          <br/><br/>

          <label htmlFor="">User's Name</label>
              <Field
                name="uname"
                type="text"
                component={TextInput}
                placeholder="User's Name"
              />
                <label htmlFor="">Phone1</label>
                <Field
                  name="uphone1"
                  type="text"
                  component={TextInput}
                  placeholder="Enter Ten Digits Mobile Number"
                />
                <label htmlFor="">Phone2</label>
                <Field
                  name="uphone2"
                  type="text"
                  component={TextInput}
                  placeholder="Phone2"
                />
                
                <label htmlFor="">Whatsapp ID</label>
                <Field
                  name="uwhatsapp"
                  type="text"
                  component={TextInput}
                  placeholder="Enter Ten Digits Mobile Number"
                />

              
              <label htmlFor="">User's Mobile as user ID</label>{' '}<span style={{color:'tomato'}}><b>(To change user ID Contact at support@citsys.in or on whatsapp ID 9810659136)</b></span>
              <Field
              disabled
                name="userId"
                type="text"
                component={TextInput}
                placeholder="Enter Ten Digits Mobile Number"
              />
              <label htmlFor="">User's Email</label>{' '}<span style={{color:'tomato'}}><b>(To change User's Email Contact at support@citsys.in or on whatsapp ID 9810659136)</b></span>
              <Field
              disabled
                name="uemail"
                type="email"
                component={TextInput}
                placeholder="User's Email"
              />
              

              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>
            <br/>
                        </div>
                        </div>
                        </div>
    );
  
}
}

export default  connect(
  (state) => {
    
    return { initialValues: state.auth.userD,userA: state.auth.userA }; // pull initial values from auth reducer
  },
  {fetchUser,updateUser}
)(
  reduxForm({
    form: "UserEditA" // a unique identifier for this form
    // validate
  })(withRouter(UserEdit))
);
