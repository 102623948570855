import {
  FETCH_DEALERSUGGESTEDS,
  FETCH_DEALERSUGGESTED,
  SEARCH_DEALERSUGGESTEDS,
  FETCHING_DEALERSUGGESTEDS,
} from '../actions/types';

const INITIAL_STATE = {
  fetching:true,
  dealerSuggestedsForAdSideColumn: [],
  dealerSuggestedsForProp: [],
  dealerSuggestedsForProj: [],
    adByDealer: [],
  dealerSuggested: null,
  offsetDS: 0,
  limitDS: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_DEALERSUGGESTEDS:
      return { ...state, ...action.payload };
    case FETCH_DEALERSUGGESTED:
      return { ...state, dealerSuggested: action.payload };
    
     case SEARCH_DEALERSUGGESTEDS:
     
      return { ...state, ...action.payload };
      case FETCHING_DEALERSUGGESTEDS:
        return { ...state, fetching: action.payload };

    default:
      return state;
  }
};
