import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, Route, } from "react-router-dom";
import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";

import StaticSearchList from "./StaticSearchList";
import StaticPropAdByDealer from "./StaticPropAdByDealer";
import StaticDealerAdByDealer from "./StaticDealerAdByDealer";
import AddPropertyToStatic from "./AddPropertyToStatic";
import Layout from "../layout/Layout";



class StaticDashboard extends Component {
  componentDidMount() {
    if(this.props.auth.isAuthenticated){
      this.props.getUserForAuth();
    }
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };

  render() {

    return (
    <div className="mt-10">
    <div className='maindiv'>

    <Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
    {/* <Route exact path="/static/AddDealerForStatic/:id" component={AddDealerForStatic} /> */}
    
    <Route
      exact
      path="/static/AddPropertyToStatic/:id"
      component={AddPropertyToStatic}
    />
    <Route
      exact
      path="/static/StaticDealerAdByDealer"
      component={StaticDealerAdByDealer}
    />
    <Route
      exact
      path="/static/StaticPropAdByDealer"
      component={StaticPropAdByDealer}
    />
      
      
      <Route
        exact
        path="/static/StaticSearchList"
        component={StaticSearchList}
      />

    </Switch>
        </Layout >
        </div>

    <div >
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>
      );


}
}
const mapStateToProps = state => ({
auth: state.auth
});


export default connect(
   mapStateToProps,
  
  {showBackButton, getUserForAuth, logoutUser  }
)(StaticDashboard);
