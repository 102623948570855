import axios from "axios";
import { toastr } from "react-redux-toastr";
import { closeModal,openModal } from "./modalAction";

import {
  FETCH_REQRESIS,
  FETCH_REQRESI,
  SEARCH_REQRESIS,
  FETCHING_REQRESIS,
  MESSAGE,
} from "./types";


//search  ReqResis

export const searchReqResis = (
  values,
  limit,
  offset
) => async dispatch => {
  

  try {
    const res = await axios.post("/api/ReqResiSearch", {
    values,
    limit,
    offset
    });

    
    dispatch({ type: SEARCH_REQRESIS, payload: res.data });
    dispatch({ type:FETCHING_REQRESIS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


//****************************
export const searchReqResisUnlocked = (
  values,
  limit,
  offsetULock
) => async dispatch => {
 

  try {
    const res = await axios.post("/api/ReqResiSearchUnlocked", {
      values,
      limit,
      offsetULock
    });

    
    dispatch({ type: SEARCH_REQRESIS, payload: res.data });
    dispatch({ type:FETCHING_REQRESIS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};


//****************************
//update reqcom  FOR unlock contact
// export const updateReqResiForUnlockContact = (id, balanceCreditReq) => async dispatch => {
//   try {
//     const res = await axios.put(`/api/ReqResiEditForUnlockContact`, {id:id,balanceCreditReq:balanceCreditReq});

//       switch (res.data.msg) {
//     case 'errorAlready':
//     toastr.error('Already Unlocked');
//     break;
//     case 'success':
//     toastr.success('Unlocked Successfully');
//     break;
//     default:
//       toastr.success(' ');
//   }
//       dispatch({ type: FETCH_USER, payload: res.data });
//   } catch (error) {
//     toastr.error("Oops", "Something went wrong.Please Try Again");
//   }
// };


//fetch single ReqResi
export const fetchReqResi = id => async dispatch => {
  try {
    const res = await axios.get(`/api/ReqResiDetail/${id}`);
   
    dispatch({ type: FETCH_REQRESI, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//#######################################################
//fetch individual  MyReqResi
export const fetchMyReqResis = () => async dispatch => {
  try {
    const res = await axios.post(`/api/MyReqResis`);

    dispatch({ type: FETCH_REQRESIS, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};



// CREATE REQRESIMENT
export const createReqResi = (values, history) => async dispatch => {
  try {
    //const createdAt = startOfToday();
    const res = await axios.post(`/api/ReqResiCreate`, {
      values
    });

    dispatch({ type: MESSAGE, payload: res.data });
    history.push('/');
    dispatch(openModal('MessageModal'));
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//resetOffset

export const resetOffsetReqResis = () => async dispatch => {
  
  try {
        
    dispatch({ type: SEARCH_REQRESIS, payload: {offset:0} });
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};