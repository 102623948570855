import axios from "axios";
import { toastr } from "react-redux-toastr";


import {
  
  SEARCH_VIDEOS,
 
} from "./types"; 



//fetch PROPERTY Videos

export const fetchPropertyVideos = ( id) => async dispatch => {

  try {
    const res = await axios.post("/api/FetchPropertyVideos", {id:id });

    
    dispatch({ type: SEARCH_VIDEOS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch PROPERTY Videos

export const fetchProjectVideos = ( id) => async dispatch => {

  try {
    const res = await axios.post("/api/FetchProjectVideos", {id:id });

    
    dispatch({ type: SEARCH_VIDEOS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};



//delete Video
export const deleteVideo = (id,val) => async () => {
  try {
    const res = await axios.delete(`/api/VideoDelete/${id}`,{val});
    switch (res.data.msg) {
  case 'errorAlready':
  toastr.error('Already Approved');
  break;
  case 'success':
  toastr.success('Deleted Successfully');
  break;
  default:
        toastr.success(' ');
}
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
