import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { closeModal } from "../../actions/modalAction";

import axios from "axios";
import { toastr } from "react-redux-toastr";
import { CSVLink } from "react-csv";

import format from "date-fns/format";
import {  Form, Button,  } from "semantic-ui-react";

import DateInput_1 from "../form/DateInput_1";
import {
  // composeValidators,
   combineValidators,
   isRequired,
 } from 'revalidate';
 
 
 const validate = combineValidators({
   from:isRequired('Created From') ,
   to:isRequired('Created Up To') ,
   
 });

//$$$$$$$$$$
class CSVDownload extends Component {
  state = {
    data:[],
    headers:[],
    filename:'',
    form:true,
  }
  componentDidMount() {

    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
  }
  backButtonHandler =()=>{
    this.props.history.goBack();
  }
  handleShowForm=()=>{
    this.setState({form:true})
  }

//********************
contactReqHandler=(val)=>{
  const  headers = [
    {label:"Name" , key:"name" },
  {label:"Mobile" , key:"phone1" },
  {label:"Phone2" , key:"phone2" },
  {label:"Email" , key:"email" },
  {label:"Whatsapp Id" , key:"whatsapp" }, 
  {label:"Request Received On" , key:"createdAt" },
  {label:"Request Received For" , key:"reqDetail" },
    
    ];

    
  const Data = () => {
    return val.data.map(value => {

      const requestFor = (value.requestFor !== undefined && value.requestFor === 'General') ? `${value.requestFor} Request ` : ''
const bhk = ((value.projectType!== undefined && value.projectType === 'Residential') && (value.bedroom!== undefined && value.bedroom)) ? `${value.bedroom} BHK ` : ''
const additionalRooms = (value.projectType !== undefined && value.additionalRooms.length >0) ? value.additionalRooms : ''
const carpetArea = (value.carpetArea!== undefined && value.carpetArea >0) ? `${value.carpetArea} sq ft` : ''
const propertyType = (value.propertyType!== undefined && value.propertyType) ? `${value.propertyType}` : ''
const propertyFor = (value.propertyFor!== undefined && value.propertyFor) ? `For ${value.propertyFor} ` : ''
const expectedPrice = (value.expectedPrice!== undefined && value.expectedPrice >0) ? `For INR ${value.expectedPrice} In` : ''
const expectedRent = (value.expectedRent!== undefined && value.expectedRent >0) ? `For INR ${value.expectedRent} In` : ''
const projectName = (value.projectName!== undefined && value.projectName) ? `${value.projectName}` : ''
const locality = (value.locality!== undefined && value.locality) ? `${value.locality} Dwarka, New Delhi` : ''
  



     return { 
      name: value.name,
   phone1: value.phone1,
   phone2: value.phone2,
   email: value.email,
   whatsapp: value.whatsapp,
   createdAt:format(new Date(value.createdAt), "MMM d yyyy") ,
   reqDetail : requestFor.concat(' ',bhk ,' ',additionalRooms,' ' ,carpetArea,' ' , propertyType,' ' , propertyFor,' ' , expectedPrice,' ' , expectedRent,' ' , projectName,' ' ,locality)
    }
    });
  };

  this.setState({data:Data(),headers:headers,filename:val.filename,form:false})
}

//********************
//###################
  onSubmit = async (values) => {

  //************
    try {

const res = await axios.post(`/api/CSVDownload`, values);
console.log('res.data',res.data)
  //^^^^^^^^^^^^^
if((res.data.name ==='contactReq')){
  this.contactReqHandler(res.data)
}

//****
      }catch (error) {
       
        if(error && error.response && error.response.status==401){
          this.props.openModal('LoginModal')
          toastr.error(`${JSON.stringify(error.response.data) }`);
        }else if(error){
          console.log('error@',error)
          toastr.error("Oops", "Something went wrong.Please Try Again_agreementEdit");
        }
    }
  //**********
}



  render() {
    
    const { handleSubmit, pristine, reset, submitting } = this.props;

    
  
//@@@@@@

//******************
 const renderFormOrButton = () =>

  (this.state.form === true) ? (
    <div className="card-form ">
      <div className=" label" >

        <Form
          style={{ padding: 25 }}
          onSubmit={handleSubmit(this.onSubmit)}
        >


        <br />
        <div  className="masthead  font-18B textAlign-center pxy-10 color-white" >
              {/*  */}
              It will take some time to fetch Data, Please be patient after hitting Download button.
          {/*  */}
          </div>
        <div><span className='color-mandatory'>* </span>Mandatory Fields</div>
        <br />
        <label >Created FROM</label><span className='color-mandatory'> * </span>
          <Field
            name="from"
            type="text"
            component={DateInput_1}
            dateFormat="dd-MM-yyyy"
            placeholder="Start Date To Search Created Between Two Dates"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select" 
            minDate={new Date( Date.now()-(93*24*60*60*1000) ) }
            maxDate={new Date( Date.now() ) }
          />
          <label >Created Up To</label><span className='color-mandatory'> * </span>
          <Field
            name="to"
            type="text"
            component={DateInput_1}
            dateFormat="dd-MM-yyyy"
            placeholder="End Date To Search Created Between Two Dates"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            minDate={new Date( Date.now()-(93*24*60*60*1000) ) }
            maxDate={new Date( Date.now() ) }

          />
     {/*  */}

     
          <br />

          <Button
          size="mini"
            color="blue"
            type="submit"
           // disabled={pristine || submitting}
          >
            Download
          </Button>
          <Button
          size="mini"
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <Button
          color="teal"
          size="mini"
          type="button"
          onClick={this.backButtonHandler}

        >
          Cancel
        </Button>
        </Form>
        </div>
        </div>
  ) : (
    <div className='card'>
    <br/><br/>
    <div className='display-center'>
    <div
    style={{margin:'auto',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}>
     Your File Is Ready For Download</div>
     </div>
     <br/>
    <div className='display-center'>

  <div
  onClick={this.handleShowForm}
  >  <CSVLink
  className='btntask'
    filename={this.state.filename}
    data={this.state.data}
    headers={this.state.headers}>
    Download

    </CSVLink></div>
    <div
    onClick={this.handleShowForm}
    className='btntask'>

      Cancel
    </div>
    </div>
    <br/><br/><br/>
    </div>
  );
  //******************
    return (
      <div style={{maxWidth:'700px',margin:'auto'}}>

                   {/**/}
                   <div className='margin-auto-H box-shadow' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Download Contact Requests As CSV File
          {/*  */}
          </div>
          </div>
          
          {/*form*/}
        {renderFormOrButton()}
            <br/><br/><br/>
            {/**/}

            {/**/}

            </div>
    );
  }
}

const mapStateToProps = ({  auth, form }) => {

  return {
    form_values: form.CSVDownload,
    userA: auth.userA,
  };
};


export default connect(
  mapStateToProps,
  {closeModal}
)(
  reduxForm({
    form: "CSVDownload", // a unique identifier for this form
    destroyOnUnmount: false,
    validate
  })(CSVDownload)
);
