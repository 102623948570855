import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import {capitalizeFirst} from "../functions/Functions";

import {createReqResi} from "../../actions/reqresiAction";
import { withRouter } from "react-router-dom";

import {
  Form,
  Button,
} from "semantic-ui-react";
import TextInput from "../form/TextInput";
import TextArea from "../form/TextArea";
import SelectInput from "../form/SelectInput";
import NumberInput from "../form/NumberInput";

import {
  requiredFor,
  propertyType,
  bedroom,
  bathroom,
  additionalRooms,
  furnishedStatus,
  entry,
  floorNo,
  locality,
  //city,
 // booleanOption
} from "./Data";
import {
  composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {isValidEmail,isValidPhone,} from "../validator/revalidator";

const validate = combineValidators({
  phone1:composeValidators(
    isRequired('Phone1'),
    isValidPhone({}),

  )(),
  phone2:isValidPhone({}),
  email:isValidEmail({}),
  whatsapp: isValidPhone({}),
  name:isRequired('Name') ,
  requiredFor:isRequired('Required For'),
  
});
class ReqResiCreate extends Component {

  projectName = () => {
    return this.props.state.projectRs.projectRsForData.map(value => ({
      key: value.projectName,
      text: `${value.projectName}` ,
      value: value.projectName,style:{fontSize:'18px'}
    }));
  };
  onSubmit = value => {
    const userS = this.props.userA !== null && this.props.userA._id
    const values={...value,userS}
    this.props.createReqResi(values, this.props.history);
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  render() {
    const { budgetMin, budgetMax,areaCarpetMax,areaCarpetMin } =
      this.props.state.form &&
      this.props.state.form.ReqResiCreate !== undefined &&
      this.props.state.form.ReqResiCreate.values !== undefined &&
      this.props.state.form.ReqResiCreate.values;
    const { handleSubmit, pristine, reset, submitting } = this.props;



      return (
        <div style={{maxWidth:'700px',margin:'auto'}}>
          
        {/*  */}
        <div className='margin-auto' >
              <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px',textAlign:'center'}}  >
              Post your Requirement  so that potential Seller or Landlord 
        can contact you for their property 
              
           </div>
           </div>
           {/*  */}
           <div className='margin-auto-H'>
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
Create Requirement for Residential Property 

</div>
</div>


           {/*  */}
        
        <div className="card border">
      <div className=" label" >


              <Form  style={{ padding: 10 }} onSubmit={handleSubmit(this.onSubmit)}>

              <Button
            color="teal"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
              <br /><br />
          <div>(All field marked with <span style={{color:'red'}}><b>*</b></span> are required)
          </div>
                <label >Name</label>{' '}<span style={{color:'red'}}><b>*</b></span>
                <Field
                  name="name"
                  type="text"
                  component={TextInput}
                  placeholder="Name"
                />
                <label >Mobile</label>{' '}<span style={{color:'red'}}><b>*</b></span>
                <Field
                  name="phone1"
                  type="text"
                  component={TextInput}
                  placeholder="Mobile Number"
                />
                <label >Phone2</label>
                <Field
                  name="phone2"
                  type="text"
                  component={TextInput}
                  placeholder="Phone2"
                />
                <label >Email</label>
                <Field
                  name="email"
                  type="text"
                  component={TextInput}
                  placeholder="Email"
                />
                <label >Whatsapp ID</label>
                <Field
                  name="whatsapp"
                  type="text"
                  component={TextInput}
                  placeholder="Whatsapp ID"
                />
                <label >Required For</label>{' '}<span style={{color:'red'}}><b>*</b></span>
                <Field
                  name="requiredFor"
                  placeholder="Required For"
                  type="text"
                  options={requiredFor()}
                  //search={true}
                  component={SelectInput}
                />
                <label >Property Type</label>
                <Field
                  name="propertyType"
                  placeholder="Property Type"
                  type="text"
                  options={propertyType()}
                  //search={true}
                  multiple={true}
                  component={SelectInput}
                />
                <label >Bedroom</label>
                <Field
                  name="bedroom"
                  placeholder="Bedroom"
                  type="text"
                  options={bedroom()}
                  //search={true}
                  multiple={true}
                  component={SelectInput}
                />
                <label >Bathroom</label>
                <Field
                  name="bathroom"
                  placeholder="Bathroom"
                  type="text"
                  options={bathroom()}
                  //search={true}
                  multiple={true}
                  component={SelectInput}
                />
                <label >Additional Rooms</label>
                <Field
                  name="additionalRooms"
                  placeholder="Additional Rooms"
                  type="text"
                  options={additionalRooms()}
                  //search={true}
                  multiple={true}
                  component={SelectInput}
                />
                <label >
                  Minimum Carpet Area In Square Foot{" "}
                  <span>
                  ({areaCarpetMin && capitalizeFirst(writtenNumber(areaCarpetMin, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                    
                  </span>
                </label>
                <Field
                  name="areaCarpetMin"
                  placeholder="Minimum Carpet Area"
                  type="number"
                  component={NumberInput}
                />
                <label >
                  Maximum Carpet Area In Square Foot{" "}
                  <span>
                  ({areaCarpetMax && capitalizeFirst(writtenNumber(areaCarpetMax, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                    
                  </span>
                </label>
                <Field
                  name="areaCarpetMax"
                  placeholder="Maximum Carpet Area"
                  type="number"
                  component={NumberInput}
                />

                <label >Furnished Status</label>
                <Field
                  name="furnishedStatus"
                  placeholder="Furnished Status"
                  type="text"
                  options={furnishedStatus()}
                  //search={true}
                  multiple={true}
                  component={SelectInput}
                />

                <label >
                  Minimum Budget{" "}
                  <span>
                  ({budgetMin && capitalizeFirst(writtenNumber(budgetMin, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                    
                  </span>
                </label>
                <Field
                  name="budgetMin"
                  placeholder="Minimum Budget"
                  type="number"
                  component={NumberInput}
                />
                <label >
                  Maximum Budget{" "}
                  <span>
                  ({budgetMax && capitalizeFirst(writtenNumber(budgetMax, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                    
                  </span>
                </label>
                <Field
                  name="budgetMax"
                  placeholder="Maximum Budget"
                  type="number"
                  component={NumberInput}
                />


                <label >Entry</label>
                <Field
                  name="entry"
                  placeholder="Entry"
                  type="text"
                  options={entry()}
                  //search={true}
                  multiple={true}
                  component={SelectInput}
                />
                <label >Floor No</label>
                <Field
                  name="floorNo"
                  placeholder="Floor No"
                  type="text"
                  options={floorNo()}
                  //search={true}
                  multiple={true}
                  component={SelectInput}
                />
                <label >Complex/Project Preferred </label>
                <Field
                  name="projectName"
                  placeholder="Complex/Project Preferred"
                  type="text"
                  options={this.projectName()}
                  search={true}
                  multiple={true}
                  component={SelectInput}
                />
                <label >Locality</label>
                <Field
                  name="locality"
                  placeholder="Locality"
                  type="text"
                  options={locality()}
                  //search={true}
                  multiple={true}
                  component={SelectInput}
                />
                {/* <label>City/District</label>{' '}<span style={{color:'red'}}><b>*</b></span>
                <Field
                //search={true}
                name="city"
                type="text"
                component={SelectInput}
                options={city()}
                placeholder="City/District"
                />                 */}
                <label >Note/Comments</label>
                <Field
                  name="note"
                  placeholder="Note/Comments"
                  type="text"
                  component={TextArea}
                  rows={3}
                />

                <br />

                <Button
                  color="olive"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Submit
                </Button>
                <Button
                  color="orange"
                  type="button"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Clear Values
                </Button>
              </Form>
              <br/><br/><br/>
              </div>
              </div>
              </div>
      );

  }
}
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA,

  };
};
export default connect(
  mapStateToProps,
  {createReqResi,}
)(
  reduxForm({
    form: "ReqResiCreate", // a unique identifier for this form
     validate
  })(withRouter(ReqResiCreate))
);
