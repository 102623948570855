import React, { Component } from "react";
import { withRouter, } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import {capitalizeFirst} from "../functions/Functions";
import {resetOffsetReqComs} from "../../actions/reqcomAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,   } from "semantic-ui-react";
import NumberInput from "../form/NumberInput";
import SelectInput from "../form/SelectInput";



import {
  requiredFor,
  propertyType,
  
} from "./Data";


  class ReqComSearch extends Component {
    // projectName = () => {
    //   return this.props.state.projectCs.projectCsForData.map(value => ({
    //     key: value.projectName,
    //     text: `${value.projectName}` ,
    //     value: value.projectName,style:{fontSize:'18px'}
    //   }));
    // };
   onSubmit = () => {
    this.props.resetOffsetReqComs();
    this.props.closeModal()
    this.props.history.push(`/reqcom/ReqComSearchList`);
  };
  render(){
  const { handleSubmit,reset,  submitting } = this.props;
  const { budgetMinL,budgetMinH,budgetMaxL,budgetMaxH,areaCarpetMinL,areaCarpetMinH,areaCarpetMaxL,areaCarpetMaxH  } =
    this.props.state.form &&
    this.props.state.form.ReqComSearch !== undefined &&
    this.props.state.form.ReqComSearch.values !== undefined &&
    this.props.state.form.ReqComSearch.values;



  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search  Requirement for Commercial Property
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(this.onSubmit)}
          >
             #  Leave All Field Blank (Clear Values) to View All Available Requirements 
           <br /><br />


<Button
              color="teal"
              type="button"
              onClick={()=>this.props.closeModal()}

            >
              Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <br/><br/>
          <label >Required For</label>
          <Field
            name="requiredFor"
            placeholder="Required For"
            type="text"
            options={requiredFor()}
            //search={true}
            component={SelectInput}
          />
            <label >Property Type</label>
            <Field
              name="propertyType"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />


            <label >Minimum Carpet Area Low {" "}
                        <span>
                          ({areaCarpetMinL && capitalizeFirst(writtenNumber(areaCarpetMinL, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                          
                        </span></label>
                      <Field
                        name="areaCarpetMinL"
                        placeholder="Lower Value of Minimum Carpet Area to Search Requirement in a Range"
                        type="number"
                        component={NumberInput}
                      />
                      <label >Minimum Carpet Area High {" "}
                        <span>
                          ({areaCarpetMinH && capitalizeFirst(writtenNumber(areaCarpetMinH, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                          
                        </span></label>
                      <Field
                        name="areaCarpetMinH"
                        placeholder="Higher Value of Minimum Carpet Area to Search Requirement in a Range"
                        type="number"
                        component={NumberInput}
                      />
                      <label >Maximum Carpet Area Low {" "}
                        <span>
                          ({areaCarpetMaxL && capitalizeFirst(writtenNumber(areaCarpetMaxL, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                          
                        </span></label>
                      <Field
                        name="areaCarpetMaxL"
                        placeholder="Lower Value of Maximum Carpet Area to Search Requirement in a Range"
                        type="number"
                        component={NumberInput}
                      />
                      <label >Maximum Carpet Area High {" "}
                        <span>
                          ({areaCarpetMaxH && capitalizeFirst(writtenNumber(areaCarpetMaxH, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                          
                        </span></label>
                      <Field
                        name="areaCarpetMaxH"
                        placeholder="Higher Value of Maximum Carpet Area to Search Requirement in a Range"
                        type="number"
                        component={NumberInput}
                      />

            <label >Minimum Budget Low {" "}
            <span>
              ({budgetMinL && capitalizeFirst(writtenNumber(budgetMinL, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMinL"
            placeholder="Lower Value of Minimum Budget to Search Requirement in a Range"
            type="number"
            component={NumberInput}
          />
          <label >Minimum Budget High {" "}
            <span>
              ({budgetMinH && capitalizeFirst(writtenNumber(budgetMinH, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMinH"
            placeholder="Higher Value of Minimum Budget to Search Requirement in a Range"
            type="number"
            component={NumberInput}
          />
          <label >Maximum Budget Low {" "}
            <span>
              ({budgetMaxL && capitalizeFirst(writtenNumber(budgetMaxL, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMaxL"
            placeholder="Lower Value of Maximum Budget to Search Requirement in a Range"
            type="number"
            component={NumberInput}
          />
          <label >Maximum Budget High {" "}
            <span>
              ({budgetMaxH && capitalizeFirst(writtenNumber(budgetMaxH, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
              
            </span></label>
          <Field
            name="budgetMaxH"
            placeholder="Higher Value of Maximum Budget to Search Requirement in a Range"
            type="number"
            component={NumberInput}
          />

            <br />

            <Button
               color="blue"
               type="submit"
               disabled={submitting}
              //  as={Link}
              //  to={`/reqcom/ReqComSearchList`}
             >
               Search
             </Button>
             <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
             <Button
               color="teal"

               type="button"
               onClick={()=>this.props.closeModal()}

             >
               Cancel
             </Button>
          </Form>
          <br/><br/><br/>
              </div>
              </div>
              </div>
  );
}
}
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA
  };
};
export default connect(
  mapStateToProps,
  {resetOffsetReqComs,closeModal}
)(
  reduxForm({
    form: "ReqComSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(withRouter(ReqComSearch))
);
