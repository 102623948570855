import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import ContactUs from '../landing/ContactUs';
import { closeModal } from '../../actions/modalAction';

const actions = { closeModal };

class ContactUsModal extends Component {
  render() {
    return (
      <Modal  
      className='maxWidth-500' 
      open={true} 
      onClose={this.props.closeModal}
      //closeOnEscape={false}
     // closeOnDimmerClick={false}
      style = {{
        //backgroundColor:'black',
        background:'transparent',
        //opacity: 0.7,
        margin:'0px',
        padding: '0px',
        height: 'auto',
        //width: 'auto',
        //zIndex:5
      }}
      >
       
        <Modal.Content 
        style = {{
        borderRadius: 3,
        fontSize:"16px",
        fontWeight:"bold",
        background:'transparent',
        // backgroundColor:'black',
        //opacity: 0.7,
        marginBottom:'300px',
        padding: '0px',
        // height: 'auto',
        // width: 'auto',
        // height:'50px',
        // width:'50px'
        //zIndex:5
      }}>
            <ContactUs />
          
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(ContactUsModal);
