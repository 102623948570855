import React from 'react';
import { Link} from 'react-router-dom';
import {  
    Button,
    Icon,
   } from "semantic-ui-react";
   import LinkItem from "./LinkItem";

const ProfileActivityLink = (props) => {

const userA=props.userA !== undefined && props.userA !== null && props.userA
      
const { isAuthenticated,user  } = props.auth !== undefined && props.auth !== null && props.auth;
const fn = props.fn !== undefined ? props.fn : null 

const renderLoginLogoutButton=()=>{
  return isAuthenticated ? (
    <>
<span className="display-user">
  <span className=" btn font-30 mr-20" 
  onClick={fn}
  >x</span>
      <Button onClick={props.onLogoutClick}
      color='red'
      size="mini"
      style={{maxWidth:'105px',padding:'7px',fontSize:'16px'}}
      ><Icon name="power" />Sign Out</Button>
      </span>
      </>
    ) : (
      <>
      
      <Button
      onClick={props.onSignInClick}
      size="mini"
      color='teal'
      style={{maxWidth:'105px',padding:'7px',fontSize:'16px'}}
      >
      <Icon name="power" />
      Sign In
      </Button>
      </>
    )}

    //@@@ 
    const renderRegisterButton=()=>{
      return  (
        <Button 
        onClick={props.fn}
        as={Link}
        to={`/register`}
        color='blue'
        size="mini"
        style={{maxWidth:'150px',padding:'7px',fontSize:'18px'}}
        >
          <Icon name="signup" />
        Register
        </Button>
        ) 
      }


    //########################################
        const renderProfileActivityLink=() => {

          const linkHeadClass = 'linkFP-head textAlign-center '
          const linkClass = 'linkFP textAlign-center'
          
          
          if (userA.userGroup ==='Individual') {
        
          return (<div >
            <div className='pxy-10 textAlign-center'> 
            {renderLoginLogoutButton()}
            
            </div>

            <div className={linkClass}
            style={{backgroundColor:'dodgerblue',padding:'5px',cursor:'default'}}
            >
          
          <b><span className='font-18B-mobile' style={{color:'white'}}>{user.name}{' '}</span></b>
        
          </div>
            
            
          <LinkItem
          url={`/company/UserDetail/${userA._id}`}
          title={'User Details'}
          // class={' bgColor-bisque'}
          fn={fn}
          />
          <LinkItem
          url={`/passchange`}
          title={'Change Password'}
          fn={fn}
          />
          

          <div className={linkHeadClass} >
            SHORTLISTS</div>

            
          <LinkItem
          url={'/company/ShortlistCompany'}
          title={'Dealers'}
          fn={fn}
          />
          
          <LinkItem
          url={'/projectR/ShortlistProject'}
          title={'Projects'}
          fn={fn}
          />

          <LinkItem
          url={'/propresi/ShortlistResi'}
          title={'Residential Properties'}
          fn={fn}
          />
          <LinkItem
          url={'/propcom/ShortlistCom'}
          title={'Commercial Properties'}
          fn={fn}
          />
        
          <div className={linkHeadClass} >
            MY PROPERTIES</div>

            <LinkItem
          url={'/propresi/MyPropResiList'}
          title={'Residential'}
          fn={fn}
          />
          
          <LinkItem
          url={'/propcom/MyPropComList'}
          title={'Commercial'}
          fn={fn}
          />
                    
          <div className={linkHeadClass} >
            MY REQUIREMENTS</div>

            <LinkItem
          url={'/reqresi/MyReqResiList'} 
          title={'For Residential Properties'}
          fn={fn}
          />
          
          <LinkItem
          url={'/reqcom/MyReqComList'}
          title={'For Commercial Properties'}
          fn={fn}
          />
          

          </div>)
          
          } else if (userA.userGroup ==='Dealer'){
            
          return (<div >

            <div className='pxy-10 textAlign-center'>
            
            {renderLoginLogoutButton()}
            
            </div>

            <div className={linkClass}
            style={{backgroundColor:'dodgerblue',padding:'5px',cursor:'default'}}
            >
          
          <b><span className='font-18B-mobile' style={{color:'white',}}>{user.name}{' '}</span></b>
        
          </div>
            
          <LinkItem
          url={'/activity/CreditDetailList'}
          title={'Credit Detail'}
          // class={' bgColor-bisque'}
          fn={fn}
          />
            
          <LinkItem
          url={`/company/UserDetail/${userA._id}`}
          title={'User Details'}
          // class={' bgColor-bisque'}
          fn={fn}
          />
          <LinkItem
          url={'/company/mycompany'}
          title={'Company Details'}
          fn={fn}
          />
          
          <LinkItem
          url={`/passchange`}
          title={'Change Password'}
          fn={fn}
          />

          <div className={linkHeadClass} >
            REQUESTS</div>

            <LinkItem
          url={'/activity/ContactReqForDealerList'}
          title={'Contact Requests Received'}
          fn={fn}
          />
          
          <LinkItem
          url={'/download/CSVDownload'}
          title={'Download Contact Requests As CSV File'}
          fn={fn}
          />
          <div className={linkHeadClass} >
            BOOK ADVERTISEMENTS</div>

            <LinkItem
          url={'/static/StaticSearchList'}
          title={'Book Static Advertisement'}
          fn={fn}
          />
                      
          <LinkItem
          url={'/activity/BookAdPropResi'}
          title={'Book Residential Property Advertisement'}
          fn={fn}
          />
            
            <LinkItem
          url={'/activity/BookAdPropCom'}
          title={'Book Commercial Property Advertisement'}
          fn={fn}
          />

<LinkItem
          url={'/activity/BookAdProjectResi'}
          title={'Book Residential Project Advertisement'}
          fn={fn}
          />
            
            <LinkItem
          url={'/activity/BookAdProjectCom'}
          title={'Book Commercial Project Advertisement'}
          fn={fn}
          />
                          {/*  */}

                          <div className={linkHeadClass} >
            ADVERTISEMENTS DETAIL</div>

            {/* <LinkItem
          url={'/static/StaticSearchList'}
          title={'Book Static Advertisement'}
          fn={fn}
          /> */}
                      
          <LinkItem
          url={'/activity/AdByFinancerList'}
          title={'Financer Advertisement Details'}
          fn={fn}
          />
            
          <LinkItem
          url={'/activity/AdByDealerList'}
          title={'Dynamic Advertisement Details'}
          fn={fn}
          />
          
          <LinkItem
          url={'/static/StaticDealerAdByDealer'}
          title={'Static Dealer Advertisement Details'}
          fn={fn}
          />
          
          <LinkItem
          url={'/static/StaticPropAdByDealer'}
          title={'Static Property Advertisement Details'}
          fn={fn}
          />
                          {/*  */}

          <div className={linkHeadClass} >
            REQUIREMENTS</div>

            <LinkItem
          url={'/reqresi/ReqResiUnlockedList'}
          title={'Unlocked Residential Requirements'}
          fn={fn}
          />
          
          <LinkItem
          url={'/reqcom/ReqComUnlockedList'}
          title={'Unlocked Commercial Requirements'}
          fn={fn}
          />
          
          </div>)
            
          }else if (userA.userGroup ==='Builder'){

            
          return (<div  style={{paddingBottom:'280px',paddingTop:'20px'}}>

          <div className='pxy-10 textAlign-center'>
          
          {renderLoginLogoutButton()}
          
          </div>

          <div className={linkClass}
          style={{backgroundColor:'dodgerblue',padding:'5px',cursor:'default'}}
          >
        
        <b><span className='font-18B-mobile' style={{color:'white',}}>{user.name}{' '}</span></b>
      
        </div>
          
          
          
        <LinkItem
          url={`/company/UserDetail/${userA._id}`}
          title={'User Details'}
          // class={' bgColor-bisque'}
          fn={fn}
          />
          <LinkItem
          url={'/builder/mycompany'}
          title={'Company Details'}
          fn={fn}
          />
          
          <LinkItem
          url={`/passchange`}
          title={'Change Password'}
          fn={fn}
          />
        <div className={linkHeadClass} >
          REQUESTS</div>
          <LinkItem
          url={'/activity/ContactReqForBuilderList'}
          title={'Contact Requests Received'}
          fn={fn}
          />
        

        </div>
        )
            
          }else{
            return (
              <div className=' pxy-20 '
              style={{minHeight:'60vh',minWidth:'40vh'}}
              >
                <span className=" btn font-30 mb-20" 
                onClick={fn}
                >x</span>
                
                <div className="border pxy-20">
                <b>To View Please</b>
                <div>{renderLoginLogoutButton()}</div>
                </div>
                
                <div className="border pxy-20">
                <b>If Not Registered Please </b>
                <div>{renderRegisterButton()}</div> 
                </div>
  
                </div>
                
                )
          }
                      
        }

        //@@@@@@@@@@@@@@@@

  return (
<div>
{renderProfileActivityLink()}
</div>
  );
};

export default ProfileActivityLink;
