
import React, { Component } from "react";
//import { Link } from "react-router-dom";
import axios from 'axios';
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
//import Paginator from "../paginator/Paginator";
import {fetchImages,deleteImageInfo} from "../../actions/imageAction";
import { fetchAppParameter } from "../../actions/commonAction";
import {
  // Header,
  // Container,
  // Divider,
  // Segment,
  // Button,
  Confirm,
  Image,
  Icon,
} from "semantic-ui-react";




class ImageList extends Component {

  state = {

      path:'',
      open: false,
      rotation: 0,
  };
  componentDidMount() {
    this.props.fetchImages()
    if (this.props.parameter === null){
      this.props.fetchAppParameter()
        }
  }

  //**********************

    show = (path) => {

      this.setState({ open: true,  path:path });
    };
    //**********************

      handleConfirm = async () => {
        this.setState({ open: false});
        const  value = {path:this.state.path}
        const imageURL_F=this.state.path.substr(0, 23)

         try {

           const resImage = await axios.post(`${imageURL_F}/api/ImageDelete`, value);

           if(resImage.data.msg ==='success'){

             this.props.deleteImageInfo(value)
         }


        } catch (error) {
          toastr.error("Oops", "Something went wrong.Please Try Again");
        }


      };

      handleCancel = () => this.setState({ result: "cancelled", open: false });
      //*********************

      rotate=()=>{
        let newRotation = this.state.rotation + 90;
        if(newRotation >= 360){
          newRotation =- 360;
        }
        this.setState({
          rotation: newRotation,
        })
      }

      rotateleft=()=>{
        let newRotation = this.state.rotation - 90;
        if(newRotation >= 360){
          newRotation =- 360;
        }
        this.setState({
          rotation: newRotation,
        })
      }
  //******************* */

  renderList=(item)=> {

        return (
          <div key={item}>
      {/**/}
      <div className='disflex-paginator'>

      <br/>

      <Image
      cache='reload'
      alt="image_"
      style={{transform: `rotate(${this.state.rotation}deg)`}}
      src={`${item}`}
      />

      </div>

      {/**/}
      <div className='disflex-paginator'>

      <span className='icon-button border1 pxy-2-3'>{`${item.substr(61, 7)}`}</span>
      <span className='icon-button border1'
      onClick={this.props.handleCloseImg}
      > <Icon name='close' />
      </span >
      <span
      onClick={() => this.show(item)}
      className="icon-button"
      >
      <img className= 'yimg ' src='/deleteIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
      </span>

      <div className='nodisplay'>
      <span
      onClick={this.rotateleft}
      className="icon-button"
      >
      <img className= 'yimg ' src='/rotateLeftIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
      </span>
      <span
      onClick={this.rotate}
      className="icon-button"
      >
      <img className= 'yimg ' src='/rotateRightIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
      </span>

      </div>

      </div>
      <br />



      {/**/}


          </div>
          )
        }
//^^^^^^^^^^^^^^^^^^^^^^^^^^^


  render() {
const { open,  } = this.state;
const renderNoImage = ()=>{
  if((this.props.images === null) || (this.props.images !== null && this.props.images.imagePaths.length < 1))
  return (
    <div className='card-noShow' >
     Oops ! No Image Available
    </div>
  )
}
    return (
      <div className='main-div' >
              <div style={{padding:'15px'}}>
      {/**/}
      <Confirm
         header='You Want To Delete !'
        open={open}
        onCancel={this.handleCancel}
        onConfirm={() => this.handleConfirm()}
      />
      {/**/}
<div >

      {/**/}
      <div className='margin-auto-H smalldiv border-radius-3'  >

       <div  style={{display: 'flex',flex: '70%',justifyContent: 'center',}} >
       <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px',textAlign:'center'}}  >
       All Images For Info
       </div>
       </div>

       <div
       style={{display: 'flex',flex: '30%',justifyContent: 'center',alignItems: 'center',fontSize:'1.1rem',fontWeight:'bold'}}
       onClick={this.props.handleCloseImg} >
       <span className='icon-button border1 pxy-5-10' >Close</span >
       </div>

       </div>
       {/**/}
{/**/}
{renderNoImage()}
        {this.props.images !== null && this.props.images.imagePaths.sort().map(this.renderList)}


        {/**/}

        </div>
        </div>
              </div>
    );

  }
  }

const mapStateToProps = state => {

  return {
    parameter:state.commons.parameter,
    images:state.images.imagesMy,
  };
};


export default connect(
  mapStateToProps,
  {fetchImages,deleteImageInfo, fetchAppParameter}
)(ImageList);
