import axios from "axios";
import { toastr } from "react-redux-toastr";
import { openModal,closeModal } from "./modalAction";

import {
  FETCH_CREDITADDS,
  SEARCH_CREDITADDS,
  FETCHING_CREDITADDS,
} from "./types";

export const resetFetching = () => async dispatch => {
  dispatch({ type:FETCHING_CREDITADDS, payload: true });
};

//search  CreditAdds
export const searchCreditAdds = (
  values,
  limit,
  offset
) => async dispatch => {

  try {
    const res = await axios.post("/api/CreditAddSearch", {
      values,
      limit,
      offset
    });
   
    dispatch({ type: SEARCH_CREDITADDS, payload: res.data });
    dispatch({ type:FETCHING_CREDITADDS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  
  }
};
//fetch all CreditAdds for one Dealer
export const fetchCreditAdds = (
  values,
  limit,
  offset) => async dispatch => {

  try {
    const res = await axios.post("/api/CreditAddList",{
      values,
      limit,
      offset
    } );

    dispatch({ type: FETCH_CREDITADDS, payload: res.data });
    dispatch({ type:FETCHING_CREDITADDS, payload: false });
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }
};

