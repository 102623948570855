import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import PrivacyPolicy from '../landing/PrivacyPolicy';
import { closeModal } from '../../actions/modalAction';

const actions = { closeModal }; 

class PrivacyPolicyModal extends Component {
  render() {
    return (
      <Modal size="small" 
      open={true} onClose={this.props.closeModal}
      style={{maxWidth:'700px'}}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      >
       
        <Modal.Content className='border'  style={{maxWidth:'700px',padding:'10px'}}>
          <Modal.Description>
            <PrivacyPolicy />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(PrivacyPolicyModal);
