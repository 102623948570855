import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, Route,  } from "react-router-dom";
import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";
import {fetchAllProjectCs} from '../../actions/projectCAction';
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import ReqComCreate from "./ReqComCreate";

import ReqComDetail from "./ReqComDetail";

import ReqComSearchList from "./ReqComSearchList";
import ReqComUnlockedList from "./ReqComUnlockedList";
import MyReqComList from "./MyReqComList";

import Layout from "../layout/Layout";

class ReqComDashboard extends Component {
  componentDidMount() {
    if(this.props.auth.isAuthenticated){
      this.props.getUserForAuth();
    }
    if(this.props.history.location.pathname !=='/'){
          this.props.showBackButton()
        }
        if(this.props.state.projectCs.projectCsForData.length <1){
          this.props.fetchAllProjectCs();
          
        }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };
  render() {
    return (
    <div className="mt-10">
    <div className='maindiv'>

      <Layout onLogoutClick={this.onLogoutClick}>

          <Switch>
            <Route
              exact
              path="/reqcom/ReqComCreate"
              component={ReqComCreate}
            />

            <Route
              exact
              path="/reqcom/ReqComDetail/:id"
              component={ReqComDetail}
            />
            



            <Route
              exact
              path="/reqcom/ReqComUnlockedList"
              component={ReqComUnlockedList}
            />
            <Route
              exact
              path="/reqcom/ReqComSearchList"
              component={ReqComSearchList}
            />
            <Route
              exact
              path="/reqcom/MyReqComList"
              component={MyReqComList}
            />

          </Switch>
          </Layout >
          </div>

      <div >
          <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

        <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
      </div>

      </div>
        );

  }
  }

const mapStateToProps = state => ({
auth: state.auth,
state
});

export default connect(
   mapStateToProps,
  
  {showBackButton, getUserForAuth, logoutUser,fetchAllProjectCs  }
)(ReqComDashboard);
