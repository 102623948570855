import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import {fetchContactReqsForDealer,updateContactReqToInactiveForDealer} from "../../actions/contactReqAction";

import { openModal } from "../../actions/modalAction";
import {
  Icon,
  Button,
  // Confirm,
} from "semantic-ui-react";
import Confirm_2 from "../common/Confirm_2"


class ContactReqForDealerList extends Component {
  state = {
    id: "",
    open:false,
    module:'',
    color:''
  };
  componentDidMount() {
    const {offset, limit} = this.props;
      
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
  
  
  if (this.props.auth.isAuthenticated) {
     
    this.props.fetchContactReqsForDealer(values, limit,offset);
  }else{
    
    this.props.openModal('LoginModal')
  }
    
  }
//^^^^^^^^^^^^^

show = (val) => {

  this.setState({ open: true,id: val.id, module:val.module,color:val.color  });
};
handleConfirm = () => {
  const did = this.state.id;
  this.setState({ open: false });
  
  const {
    offset,
  } = this.props;
  const values =
    (this.props.form !== undefined && this.props.form.values) || {};
  
    this.props.updateContactReqToInactiveForDealer(did,values,offset);
    
};
handleCancel = () => this.setState({ result: "cancelled", open: false });
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
  handleSearchContactReqsForDealer = () => {
    this.props.openModal("SearchContactReqsForDealerModal");
  };

  renderList=(contactReq)=> {
        const {
        requestFor,
        name,
        phone1,
        phone2,
        email,
        whatsapp,
        propOrProjId,
        propertyFor,
        propertyType,
        bedroom,
        additionalRooms,
        expectedPrice,
        expectedRent,
        carpetArea,
        area,
        areaUnit,
        projectType,
        projectName,
        //projectId,
        locality,
        createdAt,

        _id
        } = contactReq;

//**************************

  const renderDetailButton=() => {
    if(requestFor==="Property" && projectType==='Residential' ){
      return (<Button
        inverted
       //floated="right"
       color="blue"
         type="button"
         as={Link}
         to={`/propresi/PropResiDetail/${propOrProjId}`}
       >
        Detail
       </Button>)
    }
    if(requestFor==="Property" && projectType==='Commercial' ){
      return (<Button
        inverted
       //floated="right"
       color="blue"
         type="button"
         as={Link}
         to={`/propcom/PropComDetail/${propOrProjId}`}
       >
        Detail
       </Button>)
    }
    if(requestFor==="Project" && projectType==='Residential' ){
      return (<Button
        inverted
       //floated="right"
       color="blue"
         type="button"
         as={Link}
         to={`/projectR/ProjectRDetail/${propOrProjId}`}
       >
        Detail
       </Button>)
    }
    if(requestFor==="Project" && projectType==='Commercial' ){
      return (<Button
        inverted
       //floated="right"
       color="blue"
         type="button"
         as={Link}
         to={`/projectC/ProjectCDetail/${propOrProjId}`}
       >
        Detail
       </Button>)
    }
            


          }


    //**************************
    const renderadditionalRooms = () => {
          return additionalRooms.map(item => {
          return (
          <span key={item}>
          {" "}
          {item}|
          </span>
          );
          });
          };
      const price = (value) => {
        var val = Math.abs(value)
        if (val >= 10000000) {
        val = (val / 10000000).toFixed(2) + ' Cr';
        } else if (val >= 100000) {
        val = (val / 100000).toFixed(2) + ' Lac';
        }
        // /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
        return val;
        }

    //const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));

    return (
            <div key={_id}>
            <div className='card border-contactReq pxy-15' >
            {/*requester details start*/}
            <div><div className='border-bottom font-18B'>Contact Request Details
            <span style={{color:'dodgerblue',marginLeft:'20px'}}>{ format(new Date(createdAt), "dd-MM-yyyy")}</span>

            <span className='display-button-dlmp nodisplay mb-1'><Button
            // basic
            inverted
            floated="right"
            // fluid
            color="red"
            type="button"
            onClick={() => this.show({id: _id, module:'Delete Contact Request',color:'red'})} 
            >
            Delete
            </Button></span>
            </div></div>
            {/*  */}
            <div style={{fontSize:'1rem'}}>
            <div >
            {name && (
            <div className='row'>
            <div className='side'>
            <b>Name</b>
            </div>
            <div className='main'>
            <b>{' '} {name}</b>
            </div>

            </div>
            )}
            </div>
            <div >
            {phone1 && (
            <div className='row'>
            <div className='side'>
            <b>Phone1</b>
            </div>
            <div className='main'>
            <b>{phone1}</b>
            </div>

            </div>
            )}
            </div>

            <div >
            {phone2 && (
            <div className='row'>
            <div className='side'>
            <b>Phone2</b>
            </div>
            <div className='main'>
            <b>{phone2}</b>
            </div>

            </div>
            )}
            </div>

            <div >
            {email && (
            <div className='row'>
            <div className='side'>
            <b>Email</b>
            </div>
            <div className='main'>
            <b>{email}</b>
            </div>

            </div>
            )}
            </div>

            <div >
            {whatsapp && (
            <div className='row'>
            <div className='side'>
            <b>Whatsapp ID</b>
            </div>
            <div className='main'>
            <b>{whatsapp}</b>
            </div>

            </div>
            )}
            </div>
{/*  */}
<div >
        {requestFor === 'General' && (
        <div className='row'>
        <div className='side'>
        <b>Request Received For</b>
        </div>
        <div className='main'>
        <b>{requestFor}{'  '}Request</b>
        </div>

        </div>
        )}
        </div>
{/*  */}
            <div >
            {projectName && (
            <div className='row'>
            <div className='side'>
            <b>Request Received For</b>
            </div>
            <div className='main'>
            <b>

            <div>
            { (projectType === 'Residential' && bedroom) && (

            <span >
            {bedroom} BHK
            </span>)

            }{' '}
            {additionalRooms.length >0 && (

            <span >
            +{renderadditionalRooms()}
            </span>)

            }{' '}
            {carpetArea && (

            <span >
            {carpetArea} sq ft
            </span>)

            }{' '}
            {area && (

            <span >
            {area}
            </span>)

            }{' '}
            {areaUnit && (

            <span >
            {areaUnit}
            </span>)

            }{' '}
            {propertyType && (

            <span >
            {propertyType}
            </span>)

            }{' '}
            {propertyFor && (

            <span >
            For  {propertyFor}
            </span>)

            }{' '}
            {expectedPrice>0 && (

            <span >
            For  <Icon className="rupee" ></Icon>
            {price(expectedPrice)}{' '}In
            </span>)

            }{' '}
            {expectedRent>0 && (

            <span >
            For  <Icon className="rupee" ></Icon>
            {expectedRent}{' '}In
            </span>)

            }{' '}
            {projectName && (

            <span >
            {' '}{projectName}
            </span>)

            }{' '}
            {locality && (

            <span >
            {locality} Dwarka, New Delhi
            </span>)

            }{' '}
            {renderDetailButton()}
            </div>

            </b>
            </div>

            </div>
            )}
            </div>

            </div>


            </div>
            </div>


            );
            }

  back = () => {
    const {
      offset,
      limit
      
    } = this.props;
    //const values = this.props.form.ProjectSearch.values;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
      return;
    }

    this.props.fetchContactReqsForDealer(values, limit, (offset - limit));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
      limit,
      count
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
      return;
    }

    this.props.fetchContactReqsForDealer(values, limit, (offset + limit));
    window.scroll(0,0)
  };


  handleGoToPage = () => {
    const {
    limit,
    count
    } = this.props;

    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    // use Math.abs for strict equality ===  to work and safe guard against negative value from user
    if (Math.abs(this.state.pageO) === 0) {
    return; 
    }

    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)

    this.props.fetchContactReqsForDealer(values, limit, (page * limit));
    window.scroll(0,0)

    }
  renderPaginator() {
    if (this.props.contactReqs.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }


  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      ); 
    }
  }

//^^^^^^^^^^^^^^^^^^^^^^^^^
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//^^^^^^^^^^^^^^^^^^^^
  render() { 
    const { open,module,color  } = this.state;
    const header =`You Want To ${module} !`
    return (

      <div className='maindiv-820'>
      <Confirm_2
        header={header}
        action={module}
        color={color}
        size="mini"
        open={open}
        onCancel={this.handleCancel}
        onConfirm={this.handleConfirm}
      />
  <div >
  

  <div>
  
{/**/}
<div className='cashbook-H box-shadow mb-05'>

<div
className="disflex-center flex-15 pxy-05 ml-05"
onClick={this.handleSearchContactReqsForDealer}>
      <span className='icon-button2 border1' > <Icon name='search' /></span >
    </div>



    <div  className="disflex-center flex-85" >
<div  className="disflex-center pxy-05 font-20B"   >
{this.props.count} {''}Contact Requests Received
</div>
</div>


</div>
{/**/}
  {this.renderLoading()} 
  {this.props.contactReqs.map(this.renderList)}

    {/*  */}
<div className='disflex-paginator card-paginator media-600 border-db'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
{/*  */}
  <br/><br/>
  
  </div>



  </div>
  </div>

    );
  }
}


const mapStateToProps = ({ auth,contactReqs, selection, form }) => {
  const { fetching,limit, offset, count,} = contactReqs;

  return {
    auth,
    limit,
    offset,
    count,
    fetching,
    form: form.SearchContactReqsForDealer,
    selection,
    contactReqs: contactReqs.contactReqs
  };
};
export default connect(
  mapStateToProps,
  {fetchContactReqsForDealer,updateContactReqToInactiveForDealer,openModal}
)(ContactReqForDealerList);
