import React, { Component } from "react";
import { closeModal } from "../../actions/modalAction";
import { connect } from 'react-redux';
import {
  Image,
  Icon,
} from "semantic-ui-react";

class ContactUs extends Component {

  
  
  render() {
    
    //*************** */
    return (
      <div > 

    {/* Navbar */}

    {/*  */}

<section  className="masthead pxy-10 ">
<div>

{/*  */}
<button

onClick={this.props.closeModal}

className='back-btn-white'
>
<Icon name="arrow left" />

</button>
{/*  */}
    </div>
  </section>

  <footer id="main-footer" className="bgColor-dodgerblue text-center py-1">
<div className="containerP">
<div className="row" style={{border:'none'}}>
<div className="disflex-nav-footer" style={{ flex: '50%',padding: 20,flexDirection:'column'}}>

<div className = ' border-logo '><Image size='mini' src='/logo.png'  /></div>
  {/*  */}
  <div className='' style={{fontSize:'1.2rem',fontWeight:'bold'}}>
  <span style={{color:'skyblue'}}>FIND</span>
  <span style={{color:'blue'}}>PROP</span>
  <span style={{color:'orange'}}>.IN</span>
  </div>
  
{/*  */}


</div>




</div>
<div className='font-16B color-white'> 
  {/*  */}
        <p className='disflex-paginator'>
        
        <img className= 'yimg ' src='/phoneIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle',marginRight:'10px'}} />
       
        9810659136
        </p>
{/*  */}
<p className='disflex-paginator '>
      
      <img className= 'yimg ' src='/whatsappIcon.jpg' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle',marginRight:'10px'}} />
       9810659136
      </p>
      {/*  */}
      <p className='disflex-paginator'> 
      
      <img className= 'yimg ' src='/emailIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle',marginRight:'10px'}} />
        info@citsys.in 

      </p>
    {/*  */}
     </div>
<p ><span className="color-white">Opens : </span> Monday-Saturday </p>
<p ><span className="color-white">Timing : </span> 10:00am–5:00pm</p>
<p ><span className="color-red">Closed : </span> Sunday </p>
<br/>
</div>
</footer>



{/*  */}
    </div>
    );
  }
}

export default connect(
  null,
  { closeModal}
)(ContactUs);