import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, Route } from "react-router-dom";

import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";


import BuilderSearchList from "./BuilderSearchList";
//import ShortlistBuilder from "./ShortlistBuilder";

import OtherBuilderDetail from "./OtherBuilderDetail";
import BuilderEdit from "./BuilderEdit";
import AddImage from "./AddImage";
import MyCompanyDetail from "./MyCompanyDetail";


import Layout from "../layout/Layout";
//#####################



  class BuilderDashboard extends Component {
    componentDidMount() {
      this.props.getUserForAuth();
      if(this.props.history.location.pathname !=='/'){
        this.props.showBackButton()
      }
    }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
    //history.push('/');  Not working
    //  window.location.href = '/login';
  };
  render(){
    return (
    <div className="mt-10">
    <div className='maindiv'>

    <Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
    <Route exact path="/builder/editbuilder/:id" component={BuilderEdit} />
    <Route exact path="/builder/addimage" component={AddImage} />
    <Route
      exact
      path="/builder/builder/:id"
      component={OtherBuilderDetail}
    />

    <Route exact path="/builder/mycompany" component={MyCompanyDetail} />

    {/* <Route
      exact
      path="/builder/ShortlistBuilder"
      component={ShortlistBuilder}
    /> */}
    
    <Route
      exact
      path="/builder/BuilderSearchList"
      component={BuilderSearchList}
    />




    </Switch>
        </Layout >
        </div>

    <div >
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>
      );


}
}
// const mapStateToProps = state => ({
// auth: state.auth
// });

export default connect(
// mapStateToProps,
null,
  {showBackButton, getUserForAuth, logoutUser  }
)(BuilderDashboard);
