// import React from 'react';

// import store from "../../store";

//  console.log('store@dataFunction#',store.getState())
//const state = store.getState()

  //** */
  //data = state.appParameters.projectNameResis
  export const projectNameResi = (data) => {
    return data.sort().map(value => ({
      key: value,
      text: `${value}` ,
      value: value,style:{fontSize:'18px'}
    }));
  };
  //data = state.appParameters.projectNameComs
  export const projectNameCom = (data) => {
    return data.sort().map(value => ({
      key: value,
      text: `${value}` ,
      value: value,style:{fontSize:'18px'}
    }));
  };
  //data = state.projectRs.projectRsForData
  export const projectResi = (data) => {
    return data.map(value => ({
      key: value.projectName,
      text: `${value.projectName}` ,
      value: value.projectName,
    }));
  };
  //data = state.projectRs.projectRsForData
  export const projectResiId = (data) => {
    return data.map(value => ({
      key: value._id,
      text: `${value._id} ( ${value.projectName} )` ,
      value: value._id
    }));
  };
  //data = state.projectCs.projectCsForData  
  export const projectCom = (data) => {
    return data.map(value => ({
      key: value.projectName,
      text: `${value.projectName} ( ${value._id} )` ,
      value: value.projectName,
    }));
  };
  //data = state.projectCs.projectCsForData
  export const projectComId = (data) => {
    return data.map(value => ({
      key: value._id,
      text: `${value._id} ( ${value.projectName} )` ,
      value: value._id
    }));
  };
  //data = state.appParameters.localitys
  export const locality = (data) => {
    return data.sort().map(value => ({
      key: value,
      text: `${value}` ,
      value: value,style:{fontSize:'18px'}
    }));
  };
  //** */ 
//data = state.builders.buildersForData
  export const builderName = (data) => {
    return [{cname:'',_id:''},...data].map(value => ({
      key: value.cname,
      text: `${value.cname} ( ${value._id} )` ,
      value: value.cname,
    }));
  };
  //data = state.builders.buildersForData
  export const builderId = (data) => {
    return [{cname:'',_id:''},...data].map(value => ({
      key: value._id,
      text: `${value._id} ( ${value.cname} )` ,
      value: value._id
    }));
  };

  //**** */
  //data = state.auth.usersForData
  export const userName = (data) => {
    return [{uname:'',userId:''},...data].map(value => ({
      key: value.uname,
      text: `${value.uname} ( ${value.userId} )` ,
      value: value.uname,
    }));
  };
  //data = state.auth.usersForData 
  export const userId = (data) => {
    return [{_id:'',userId:''},...data].map(value => ({
      key: value._id,
      text: `${value._id} ( ${value.userId} )` ,
      value: value._id
    }));
  };
  //** */
//data = state.companys.companysForData
  export const financerName = (data) => {
    return [{cname:'',_id:''},...data].map(value => ({
      key: value.cname,
      text: `${value.cname} ( ${value._id} )` ,
      value: value.cname,
    }));
  };
  //data = state.companys.companysForData
  export const financerId = (data) => {
    return [{cname:'',_id:''},...data].map(value => ({
      key: value._id,
      text: `${value._id} ( ${value.cname} )` ,
      value: value._id
    }));
  }; 
  //** */
//data = state.companys.companysForData
  export const companyId = (data) => {
  return [{cname:'',_id:''},...data].map(value => ({
    key: value._id,
    text: `${value._id} ( ${value.cname} )` ,
    value: value._id
  }));
};
//** */

// import {projectNameResi, projectNameCom, projectResi, projectResiId,
//   projectCom, projectComId, locality, builderName, builderId,
//   userName, userId, financerName, financerId, companyId

// } from "../functions/DataFunctions";