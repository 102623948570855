import React, { Component } from "react";
import { connect } from "react-redux";
import {fetchDealerSuggestedsForProp} from "../../actions/dealerSuggestedAction";
import DealerSuggested from "./DealerSuggested";
import {
  //Button, 
} from "semantic-ui-react";



class DealerSuggestedListForProp extends Component {
  
  componentDidMount = () => {
    const id = this.props.contactReq.propOrProjId;
    this.props.fetchDealerSuggestedsForProp(id);
  };
  //@@@
  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.contactReq.propOrProjId !== prevProps.contactReq.propOrProjId) {
      const id = this.props.contactReq.propOrProjId;
      this.props.fetchDealerSuggestedsForProp(id);      
    }
     }
  //@@@
  renderLoading() {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }
  renderNoDealer=()=> {
    if (this.props.dealerSuggestedsForProp.length===0) {
      return (
        <div className='pxy-20' ><span style={{padding:'20px', fontSize: "18px",color: "teal"}}><b>NO DEALER AVAILABLE</b></span>

        </div>
      );
    }
  }

  render() {
   
    return (
      <div className='disflex-DLMP media-600'>
         
        {this.renderNoDealer()}
        {this.props.dealerSuggestedsForProp.map((item)=>(<DealerSuggested key={item._id} company={item} contactReq = {this.props.contactReq}/>))}

      </div>
    );
  }
}


const mapStateToProps = ({ dealerSuggesteds, auth }) => {
  
  return {
    userA: auth.userA,
    dealerSuggestedsForProp: dealerSuggesteds.dealerSuggestedsForProp,

  };
};

export default connect(
  mapStateToProps,
  {fetchDealerSuggestedsForProp}
)(DealerSuggestedListForProp);
