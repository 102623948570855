const sortingO = [
  'Demand-Low To High',
  'Demand-High To Low',
  'Newest',
  'Oldest'
];
export const sorting = () => {
  return sortingO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};

//#########
const advertisedByO = [ 'Owner','Financer', 'Others'];
const advertisedByForSearchO = [ 'Owner', 'Others'];
export const advertisedBy = () => {
  return advertisedByO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
export const advertisedByForSearch = () => {
  return advertisedByForSearchO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//#########
const propertyForO = [ 'SALE','RENT', 'PG ACCOMMODATION'];
export const propertyForOption = () => {
  return propertyForO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
export const propertyFor = () => {
  return propertyForO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
const brokerResponseO = ['Brokers Please Do Not Contact', 'Brokers Can Contact'];
export const brokerResponse = () => {
  return brokerResponseO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//############
const categoryO = ['CGHS', 'DDA','BUILDER'];
export const category = () => {
  return categoryO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//############
const areaUnitO = ['', 'sq ft','sq yd','sq m'];
export const areaUnit = () => {
  return areaUnitO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//###############
const propertyTypeO = [

  'Flat',
  'Floor',
  'Penthouse',
  'Duplex House',
  'Residential Land /Plot'
];
export const propertyType = () => {
  return propertyTypeO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//** */
const frequencyO = [
  'One-Time',
  'Monthly',
  'Quaterly',
  'Half-Yearly',
  'Yearly',
  'Other'
];
export const frequency = () => {
  return frequencyO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
const booleanOptionO = ['','Yes', 'No'];
export const booleanOption = () => {
  return booleanOptionO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

//#######
const bedroomO = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const bedroom = () => {
  return bedroomO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//############
const bathroomO = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const bathroom = () => {
  return bathroomO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//###############
const balconyO = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const balcony = () => {
  return balconyO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
const additionalRoomsO = ['Servant Room', 'Study Room', 'Store', 'Pooja Room'];
export const additionalRooms = () => {
  return additionalRoomsO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

//#######
const furnishedStatusO = ['Newly Furnished','Furnished', 'Semi-Furnished', 'Unfurnished'];
export const furnishedStatus = () => {
  return furnishedStatusO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//############
const numberOptionO = ['','N/A','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const numberOption = () => {
  return numberOptionO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//############
const wardrobesO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const wardrobes = () => {
  return wardrobesO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//###############
const acO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const ac = () => {
  return acO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//#######
const fanO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const fan = () => {
  return fanO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//############
const bedO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const bed = () => {
  return bedO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//###############
const tvO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const tv = () => {
  return tvO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//#######
const geyserO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const geyser = () => {
  return geyserO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//############
const bathtubO = ['','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const bathtub = () => {
  return bathtubO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//###############
const interiorO = ['','New', 'Old'];
export const interior = () => {
  return interiorO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const chimneyO = ['','Yes', 'No'];
export const chimney = () => {
  return chimneyO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//############
const iglGasConnectionO = ['','Yes', 'No'];
export const iglGasConnection = () => {
  return iglGasConnectionO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############
const modularKitchenO = ['','Yes', 'No'];
export const modularKitchen = () => {
  return modularKitchenO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

//#######
const willingToRentOutToO = [
  'Family',
  'Single Women Only',
  'Single Men Only',
  "Doesn't Matter"
];
export const willingToRentOutTo = () => {
  return willingToRentOutToO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//############
export const tenantsProfessionO = [
  '',
  'govt job',
  'private job',
  'bussiness men',
  "Doesn't Matter"
];
export const tenantsProfession = () => {
  return tenantsProfessionO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############
const tenantsWhoAreNonVegetariansO = ['Yes', 'No', "Doesn't Matter"];
export const tenantsWhoAreNonVegetarians = () => {
  return tenantsWhoAreNonVegetariansO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const tenantsWithPetsO = ['Yes', 'No', "Doesn't Matter"];
export const tenantsWithPets = () => {
  return tenantsWithPetsO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//############
const companyLeaseO = ['Yes', 'No', "Doesn't Matter"];
export const companyLease = () => {
  return companyLeaseO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############
const agreementO = ['Registrar', 'Notary', "Doesn't Matter"];
export const agreement = () => {
  return agreementO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const occupancyStatusO = ['Owner', 'Tenant', 'Vacant'];
export const occupancyStatus = () => {
  return occupancyStatusO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//############
const loanOfferedByO = ['', 'All The Leading Banks'];
export const loanOfferedBy = () => {
  return loanOfferedByO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############
const statusO = ['Ready To Move', 'Under Construction'];
export const status = () => {
  return statusO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

//#######
const typeOfOwnershipO = ['Freehold', 'Leasehold','Power of Attorney'];
export const typeOfOwnership = () => {
  return typeOfOwnershipO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//############
const facingO = [
'',
  'East',
  'West',
  'North',
  'South',
  'North - East',
  'North - West',
  'South - East',
  'South -West'
];
export const facing = () => {
  return facingO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############
const entryO = [
  '',
  'East',
  'West',
  'North',
  'South',
  'North - East',
  'North - West',
  'South - East',
  'South -West'
];
export const entry = () => {
  return entryO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const OverlookingO = ['','Park', 'Road', 'Other Building'];
export const Overlooking = () => {
  return OverlookingO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//############
const carParkingCoveredO = ['','1', '2', '3', '4', '5', '6', '6+'];
export const carParkingCovered = () => {
  return carParkingCoveredO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############
const carParkingOpenO = ['','1', '2', '3', '4', '5', '6', '6+'];
export const carParkingOpen = () => {
  return carParkingOpenO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const floorNoO = [
  'Ground',
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '15+',
  'Top'
];
export const floorNo = () => {
  return floorNoO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//############
const totalFloorsO = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '15+'
];
export const totalFloors = () => {
  return totalFloorsO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############

//###############
const localityO = [
  'Sector-01',
  'Sector-02',
  'Sector-03',
  'Sector-04',
  'Sector-05',
  'Sector-06',
  'Sector-07',
  'Sector-08',
  'Sector-09',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18A',
  'Sector-18B',
  'Sector-19A',
  'Sector-19B',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
const cityO = ['Dwarka', 'Gurugram','Noida', 'Rohini'];
export const city = () => {
  return cityO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};