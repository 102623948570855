import React, { Component } from "react";
import { connect } from "react-redux";
import {allRentPropertiesInProjectR} from "../../actions/propresiAction";
import PropResiSummary from './PropResiSummary';
import {price} from '../functions/Functions';

class AllPropResiRentList extends Component {
  

  componentDidMount() {
    const values = {projectId:this.props.projectId};
    this.props.allRentPropertiesInProjectR(values);
  }
    
  //@@@
  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.projectId !== prevProps.projectId) {
      const values = {projectId:this.props.projectId};
      this.props.allRentPropertiesInProjectR(values);
      
    }
     }
  //@@@
  renderLoading=()=> {
    if (this.props.fetchingRR===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }
  renderNoProperty=()=> {
    if (this.props.propresisRR.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE </b></span>

        </div>
      );
    }
  }


  render() {
    const minVal =  ()=> Math.min(...this.props.propresisRR.map((item)=>item.expectedRent)) 
    const maxVal = ()=> Math.max(...this.props.propresisRR.map((item)=>item.expectedRent))

    return (


<div>


{/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2'>
<span  > {this.props.countRR} Residential Property For Rent|PG ({this.props.propresisRR.length >0 ? price(minVal()):0} ~ {this.props.propresisRR.length >0 ? price(maxVal()): 0} )</span>
</div>
      
</div>

    <div className='card-attached border-sb pxy-05'  >
    {this.renderNoProperty()}
{this.props.propresisRR.map((item)=> (<PropResiSummary   key={item._id}  propresi={item}/>))}
    </div>
{/*  */}

</div>

    );
  }
}



const mapStateToProps = ({ propresis,auth,  }) => {
  const { fetchingRR,limitRR, offsetRR, countRR } = propresis;

  return {
    fetchingRR,
    limitRR,
    offsetRR,
    countRR,
    //form: form.PropResiRentSearch,
    propresisRR: propresis.propresisRR,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {allRentPropertiesInProjectR, }
)(AllPropResiRentList);
