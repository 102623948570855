import React from 'react';
import { Form, Label } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
//import 'react-datepicker/dist/react-datepicker-cssmodules.css'
// import setMinutes from 'date-fns/setMinutes'
// import setHours from 'date-fns/setHours'
import {isToday, setMinutes,setHours,getMinutes,getHours, } from 'date-fns';

const DateInput = ({
  input: { value, onChange,onBlur, ...restInput },
  width,
  minDate,
  maxDate,
  
  timeClassName,
  placeholder,
  meta: { touched, error },
  ...rest
}) => { 
  
  
  if (value) {
    value = new Date(value);   
  }
  return (  
    
    <Form.Field error={touched && !!error} width={width}>
      <DatePicker
        style={{ textAlign: 'left' }}
        {...rest}
        placeholderText={placeholder}
        //value={!value ? null : new Date(value)}
        // selected={!value ? (setHours(setMinutes(new Date(), 0), 12)) : value}
        selected={!value ? null : value}
        //selected={selected}
        onChange={onChange}
         minDate={minDate}
         maxDate={maxDate}
         
         minTime={isToday(new Date(value)) ? setHours(setMinutes(new Date(),getMinutes(new Date())),getHours(new Date())) : setHours(setMinutes(new Date(),0),0)}         
         maxTime={setHours(setMinutes(new Date(),59),23) }
         timeClassName={timeClassName}
        onBlur_={onBlur} //fix for date input proper working as onBlur required
        // onkeydown="return false" //disable the input field for key board typing which cause some bug in date field
        onKeyDown={(e) => {
          e.preventDefault();
       }}
        {...restInput}
        className='input'
        autoComplete="off"
      />
      {touched &&
        error && (
          <Label basic color="red">
            {error}
          </Label>
        )}
    </Form.Field>
  );
};

export default DateInput;
