import React, { Component } from "react";
import { connect } from "react-redux";

import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import {searchStatics} from "../../actions/staticAction";
import { getUserForAuth } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import {
  Icon,
  Button,
  Modal,
} from "semantic-ui-react";
import BookStaticAd from './BookStaticAd'



class StaticSearchList extends Component {
  state = {
    pageO:'',
    id: "",
    modalOpen: false,
  };
  componentDidMount() {
    const {offset, limit} = this.props;
      
      const values =
        (this.props.form !== undefined && this.props.form.values) || {};
    
    this.props.searchStatics(values, limit,offset);
     
  }

  handleSearchStatic = () => {
    this.props.openModal("StaticSearchModal");
  };

//**************** */
handleRefresh = () =>{
	const {offset,limit} = this.props;
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    this.props.getUserForAuth()
    this.props.searchStatics(values, limit,offset);
}
	//**************** */
  
  handleModalOpen = (value) => {
    if (!this.props.userA) {
      this.props.openModal('LoginModal')
      }else{
      this.setState({ modalOpen:true , valueToBook: value});
      }	
  }
  handleModalClose = () => this.setState({ modalOpen:false, });

  //*************************** */
 
//^^^^^^^^^^^^^^^^^^^^^^^^^
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//***************************

  renderList=(item)=> {
    const {
      creditUsed,
      start,
       expire,
       adFor,
       adCategory,
       adLocation,
       adSubLocation,
       position,
       //dealerName,
       dealer,   //id of dealer
      //****************** */
           
      _id
    } = item;

    const {balanceCreditProp,}=this.props.userA !== null && this.props.userA
 //const companyId = this.props.userA !== null && this.props.userA.companyId
  const valueToBook = {id:_id,balanceCreditProp,creditUsed}

//************************** render active/inactive/expired
const startOn= Date.parse(start)
const expireOn = (Date.parse(expire))
const now = Date.now()
//**************************


const renderStatus=() => {
  if ((now<startOn)){
    return (<span >
      Inactive
     </span>)
  }

  if( (now>startOn && now<expireOn )){
    return (<span >
       Active
     </span>)
  }
  if ((now>expireOn)){
  return (<span className='color-red'>
      Expired
     </span>)
  }
  return (<span >
    No Status
   </span>)
  }


  //**************************
  //   const renderDealer = () =>
  //  (
  //   <div >
  //   {dealerName && (
  //   <div className='row'>

  //   <div className='side'>
  //   <b>Dealer</b>
  //   </div>
  //   <div className='main'>
  //   <b>{dealerName}{' '}</b>{' '}
  //   </div>

  //   </div>
  //   )}
  //   </div>
  //   ) 

  //**************************
  

  const renderBookStaticAdButton = () =>
  (dealer) ? 
  (
  <Button
  disabled
  floated="right"
  color="blue"
  //  onClick={() => this.handleUpdateForApprove(_id)}
  >
  Static Ad Booked
  </Button>
  ) 
  
  : 
  (
  <Button
  floated="right"
  color="blue"
  onClick={() => this.handleModalOpen(valueToBook)}
  >
  Book Static Ad
  </Button>
  );

            
  

    //**************************
    
    return (
      <div key={_id}>

            <div className='card pxy-10 border3 mt-0 '  >
{/*ad detqils start*/}
<div className="display-center font-18B border-bottom" >

<div className=' mt-05 mb-05'>{(adFor !== 'Dealer') && adCategory}{' '}  {adFor}{' '}
At {adLocation}</div>
<div className="color-dodgerblue ml-20 ">{ renderStatus()}</div>

</div>

    <div style={{fontSize:'1rem'}}>
    <div >
    {start && (
    <div className='row'>
    <div className='side'>
    <b>Duration</b>
    </div>
    <div className='main'>
    <b>{ format(new Date(start), "dd-MM-yyyy")}{' '}To{' '}{ format(new Date(expire), "dd-MM-yyyy")}{' '}   </b>
    </div>

    </div>
    )}
    </div>

          

			  {/* {renderDealer()} */}
    {/* static ad location start */}

    <div >
    {adFor && (
    <div className='row'>
    <div className='side'>
    <b>Ad Location</b>
    </div>
    <div className='main'>
    <b>

    <div>
    {(adFor !== 'Dealer') && adCategory && (

    <span >
    {adCategory}
    </span>)

    }{' '}

    {adFor && (

    <span >
    {adFor}
    </span>)

    }{' '}

    {adLocation && (

    <span >
    At {adLocation}
    </span>)

    }{' '}
    {/*  */}
    {((adLocation==='STATIC')||(adLocation==='STATIC SECTOR')) 
    && adSubLocation && (
    <span >
    {adSubLocation}
    </span>)

    }{' '}
    {position && (

    <span >
    On Position  {position}
    </span>)

    }{' '}
    {/*  */}



    </div>

    </b>
    </div>

    </div>
    )}
    </div>

{/* static ad location end */}
    
    {/*  */}
    <div className='row'> 
    {renderBookStaticAdButton()}
          </div>
{/*  */}
    </div>


            </div>
      </div>


    );
  }

  back = () => {
    const {
      offset,
      limit
     
    } = this.props;
    //const values = this.props.form.ProjectSearch.values;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
      return;
    }

    this.props.searchStatics(values, limit, (offset - limit));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
      limit,
      count
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
      return;
    }

    this.props.searchStatics(values, limit, (offset + limit));
    window.scroll(0,0)
  };

  handleGoToPage = () => {
    const {
      
      limit,
      count
      
    } = this.props;
  
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
      // use Math.abs for strict equality ===  to work and safe guard against negative value from user
      if (Math.abs(this.state.pageO) === 0) {
        return; 
      }
  
    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)
  
  
    this.props.searchStatics(values, limit, (page * limit));
      window.scroll(0,0)
  
  }
  
  //****************** */
  renderPaginator=()=> {
    if (this.props.statics.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
          //values={this.props.values}
        />
      );
    }
  }


  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }


  render() {
   
    return (

      <div className='maindiv-820'>
      <div >
  
  
      <div>
        {/**/}
    
        <Modal
    open={this.state.modalOpen}
    onClose={this.handleModalClose}
    basic
    size='small'
    style={{top:'auto'}} // for positioning of modal at center of view port
    >

    <Modal.Content
    className="disflex-center"
    >
    <BookStaticAd 
  handleModalClose = {this.handleModalClose}
  handleRefresh ={this.handleRefresh}
  valueToBook={this.state.valueToBook}

  />
    </Modal.Content>

    </Modal>
{/**/}
        {/**/}
  <div className='cashbook-H box-shadow mb-005'>
  
  <div
  className="disflex-center flex-15 pxy-05 ml-05"
  onClick={this.handleSearchStatic}>
        <span className='icon-button2 border1' > <Icon name='search' /></span >
      </div>
  
  
  
      <div  className="disflex-center flex-85" >
<div  className="disflex-center pxy-05 font-20B"   >
  All Locations to Book Static Advertisement in {this.props.city} ({this.props.count})
  </div>
  </div>
  
  
  </div>
  {/**/}
      
  
      {this.renderLoading()}
      {this.props.statics.map(this.renderList)}
  {/*  */}
  <div className='disflex-paginator card-paginator border media-600'>
  {this.renderPaginator()}
  <div className="disflex-paginator  " >
    <div className= 'font-paginator'>Go To Page</div>
    <form>
          <input
          className='inputPage'
            placeholder="number"
            name="pageO"
            type="number"
            value={this.state.pageO}
            onChange={this.onChange}
          />
          <span  className="btnNumber"  onClick={this.handleGoToPage} >
            Submit
          </span>
        </form>
  
          
  </div>
  <br/>
  </div>
  {/*  */}
      
  
      <br/><br/><br/><br/>
  
      <div></div>
      <div></div>
      </div>
      <div className='nodisplay'>
      {/* side grid space*/}
      </div>
  
  
      </div>
      </div>
  
      );
    //@@@@@@@@@

  }
}


const mapStateToProps = ({ statics, auth,form }) => {
  const {city, fetching,limit, offset, count } = statics;
  return {
    city,
    userA: auth.userA,
    fetching,
    limit,
    offset,
    count,
    form:form.StaticSearch,
    statics: statics.statics,

  };
};

export default connect(
  mapStateToProps,
  {searchStatics,getUserForAuth,openModal}
)(StaticSearchList);
