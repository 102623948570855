import React, { Component } from 'react';
import { Link, } from 'react-router-dom';
import { reduxForm, Field } from "redux-form";
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authAction';
import { closeModal } from "../../actions/modalAction";
import {
  Button,
  Form,
  Image,
} from 'semantic-ui-react';
import {userGroupOption } from "./Data";
import CheckboxInput_2 from "../form/CheckboxInput_2"
import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import {
  combineValidators,
  isRequired,
} from 'revalidate';

const validate = combineValidators({
//************user */

  cid:isRequired('Company ID') ,
  userId:isRequired('UserId') ,
  password:isRequired('Password') ,
  userGroup:isRequired('Login As') ,
  
});

class Login extends Component {
  state = {
    showP:false,
  }

   
  onSubmit = values => {
    this.props.loginUser(values);
  };
  

  toggle = () => this.setState({ showP: !this.state.showP })
  render() {
  
    const { handleSubmit} = this.props;
    const showPass = this.state.showP ? "text" : "password"
    const { userGroup} =
      this.props.state.form &&
      this.props.state.form.Login !== undefined &&
      this.props.state.form.Login.values !== undefined &&
      this.props.state.form.Login.values;

      const renderCompanyIDField = ()=>{
        if(userGroup==='Dealer' || userGroup==='Builder'){
          return (
              <>
              <label>Company ID</label>{' '}<span style={{color:'red'}}><b>*</b></span>
              <Field
              name="cid"
              type="text"
              component={TextInput}
              placeholder="Company ID"
              />

              </>
              )
          }
        }

    return (
      <div className='border borderwidth-5'
      style={{borderWidth:'5px'}}
      >
            <div  className="card-attached-2 " style={{maxWidth:'500px',margin:'auto'}}>
            {/*  */}  
        
<div className='disflex-paginator' >
  
  <Image size='mini' src='/logo.png' style={{ marginRight: '.5em' }} />
<div  style={{fontSize:'1.2rem',fontWeight:'bold',marginRight:'5px'}}>
  <span style={{color:'dodgerblue'}}>FIND</span>
  
  <span style={{color:'blue'}}>PROP</span><span>.IN</span></div>
</div>


{/*  */}
      <div className='masthead format-login'>  In order to use all the features like
Shortlist properties you like,
Contact sellers of properties you are interested in,
Ask Dealer to Contact You,
Advertise your property,
Post Your Requirement,
View Your Profile/Activities Details and many more.<span style={{color:'orange',fontSize:'16px',fontWeight:'bold'}}> Please Login</span>
</div>
{/*  */}
            <div className="card-attached-2 ">
            <div className=" label pxy-10" >
          
            <Form  onSubmit={handleSubmit(this.onSubmit)}>
            <label>Login As</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              //search={true}
              name="userGroup"
              type="text"
              component={SelectInput}
              options={userGroupOption()}
              placeholder="Login As"
            />
            {renderCompanyIDField()}
            <label >User Id</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="userId"
              type="text"
              component={TextInput}
              placeholder="User Id"
            />
              
              <label >Password</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="password"
              type={showPass}
              //type="password"
              component={TextInput}
              placeholder="Password"
            />
            
            <CheckboxInput_2 
              label='Show Password'
              onChange={this.toggle}
              checked={this.state.showP}
              />
              <br/><br/>
              <div className='disflex-paginator' >
              <Button 
              color="teal" 
              //type="button"
               >
                Login
              </Button>
              <Button
            color="blue"
            type="button"
            onClick={()=>this.props.closeModal()}

          >
            Cancel
          </Button>
              </div>
              
            </Form>
          </div>

          <div 
          className='format-login2 flex-direction-column border-db-top '>
          <div > <span> New to us?</span> {' '}
          <span>
          <Button
              //floated="right"
              color="teal"
              type="button"
              as={Link}
              to={`/register`}
              onClick={()=>this.props.closeModal()}
            >
              Register
            </Button>
            </span>
          {/* <span className='btnlogin'>
            <Link onClick={()=>this.props.closeModal()}  to={`/register`}>Register </Link>
            </span> */}
            </div>
          <div >Forgot Your Password?{' '}
          <Button
              //floated="right"
              color="blue"
              type="button"
              as={Link}
              to={`/formpassreset`}
              onClick={()=>this.props.closeModal()}
            >
              Reset
            </Button>
          {/* <Link onClick={()=>this.props.closeModal()}  to={`/auth/formpassreset`}>Reset </Link> */}
          </div>
          </div>




      </div>
      

      </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  state,
});

export default connect(
  mapStateToProps,
  { loginUser, closeModal }
)(
  reduxForm({
    form: "Login",   // a unique identifier for this form
    //destroyOnUnmount: false,
     validate
  })(Login)
  
  );
