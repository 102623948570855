import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modalAction';
import ReqComSearchUnlocked from '../requireCom/ReqComSearchUnlocked';
//import * as actions from '../../actions/companysAction';
const actions = { closeModal };

class SearchReqComUnlockedModal extends Component {
  render() {
    return (
      <Modal 
      className='maxWidth-500'
      size='small' 
      open={true} 
      onClose={this.props.closeModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      >
        
        <Modal.Content className='border'>
          <Modal.Description>
            <ReqComSearchUnlocked />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(SearchReqComUnlockedModal);
