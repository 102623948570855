import React from 'react';
//import axios from 'axios';
import {
  //Image,
  Segment,
  //Header,
  Container,
  // Button,
  // Icon
} from 'semantic-ui-react';


import AddImageProfile from "./AddImageProfile";
import AddImageInfo from "./AddImageInfo";

class AddImage extends React.Component {
  state = {
    addProfileImages: false,
    addInfoImages: false,
  };
  componentDidMount() {
    window.scroll(0,0)
  }
  handleAddProfileImages = () => {
    this.setState({ addProfileImages: true,addInfoImages: false, });
  };

  handleAddInfoImages = () => {
    this.setState({ addProfileImages:false ,addInfoImages:true , });
  };

  renderAddImages=()=>{
    if(this.state.addProfileImages=== true){
      return (
        <AddImageProfile/>
      )
    }
    if(this.state.addInfoImages=== true){
      return (
        <AddImageInfo/>
      )
    }
  }
  render() {

    return (

      <Container text>
      {/**/}
<div className='cashbook-H'>



<div  style={{display: 'flex',flex: '45%',justifyContent: 'center',fontSize:'1.1rem',fontWeight:'bold',padding:'5px'}}
onClick={this.handleAddProfileImages}
 >
<span className='icon-button border1 pxy-5-10 textAlign-center' >Add Profile Images</span >
</div>

<div  style={{display: 'flex',flex: '45%',justifyContent: 'center',fontSize:'1.1rem',fontWeight:'bold',padding:'5px'}}
onClick={this.handleAddInfoImages}
 >
<span className='icon-button border1 pxy-5-10 textAlign-center' >Add Info Images</span >
</div>



</div>
{/**/}
      <Segment attached>

      {this.renderAddImages()}
      </Segment>
      <br/><br/><br/><br/>
      </Container >
    );
  }
}

export default AddImage;
