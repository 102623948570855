import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
//import writtenNumber from "written-number";

import {fetchStaticPropAdByDealer} from "../../actions/staticAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button, } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";

import {
  
  adForPropertySearch,
  adCategoryOption,
  adLocationPropertyCommonO,
  adLocationPropertyBFloorO,
  //adLocationDealer,
  adSubLocation,
  // position,
  // locality,

} from './Data';



const StaticSearchProperty = props => {
 
  const { handleSubmit,  reset, submitting } = props;

  const { adCategory,adLocation } =
  props.state.form &&
  props.state.form.StaticSearchProperty != undefined &&
  props.state.form.StaticSearchProperty.values != undefined &&
  props.state.form.StaticSearchProperty.values;

  const onSubmit = values => {
    const offsetP = 0
    const valuesF= ((values.adLocation === 'SIMILAR PROPERTY') || (values.adLocation === 'BUILDER FLOOR SIMILAR PROPERTY')) ?{...values,adSubLocationA:[]} :{...values}
 
const { limitP} = props.state.statics;
    props.fetchStaticPropAdByDealer(valuesF, limitP,offsetP);
  };

  const adLocationProperty = ()=>{
    const data =()=>{
      if(adCategory==='Commercial'){
        return adLocationPropertyCommonO
      }
      if(adCategory==='Residential'){
        return [...adLocationPropertyCommonO,
          ...adLocationPropertyBFloorO]
      }
    }
  return data() !==undefined && data().map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
  }

  // ^^^
const renderAdSubLocationField = ()=>{

  if( 
    adLocation && ((adLocation === 'STATIC') || (adLocation === 'BUILDER FLOOR STATIC') 
)
) {

return (
  <>
      <label > Ad Sub Location</label>
      <Field
      search={true}
      name="adSubLocationA"
      type="text"
      component={SelectInput}
      multiple={true}
      options={adSubLocation()}
      placeholder="Ad Sub Location "
      />
      </>
      )
}else{
  return(<div></div>)
}
}
// ^^^
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Booked Static Property Ad
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
 #  Leave All Field Blank (Clear Values) to View All Booked Static Property Ad 
           <br /><br />
            

          <Button
          color="teal"
          type="button"
          onClick={()=>props.closeModal()}

          >
          Cancel
          </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          <label >Ad Category</label>
            <Field
              search={true}
              name="adCategory"
              type="text"
              component={SelectInput}
              options={adCategoryOption()}
              placeholder="Ad Category"
            />

            <label >Ad For</label> 
            <Field
              search={true}
              name="adFor"
              type="text"
              component={SelectInput}
              options={adForPropertySearch()}
              placeholder="Ad For"
            />
            <label >Ad Location</label>
          
          <Field
            search={true}
            name="adLocation"
            type="text"
            component={SelectInput}
            //multiple={true}
            options={adLocationProperty()}
            placeholder="Ad Location "
            />
            {/*  */}
            {renderAdSubLocationField()}
            {/*  */}
            <label >Lower Started On Date</label>
            <Field
              name="startL"
              type="text"
              component={DateInput}
              placeholder= "Lower Started On Date To Search Static Property Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Higher Started On Date</label>
            <Field
              name="startH"
              type="text"
              component={DateInput}
              placeholder="Higher Started On Date To Search Static Property Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Lower Expired On Date</label>
            <Field
              name="expireL"
              type="text"
              component={DateInput}
              placeholder= "Lower Expired On Date To Search Static Property Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Higher Expired On Date</label>
            <Field
              name="expireH"
              type="text"
              component={DateInput}
              placeholder="Higher Expired On Date To Search Static Property Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />




            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting} 
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
  
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {fetchStaticPropAdByDealer,closeModal}
)(
  reduxForm({
    form: "StaticSearchProperty", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(StaticSearchProperty)
);
