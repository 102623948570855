import React, { Component } from 'react';
import { Icon,
  //Image
 } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import { closeModal } from '../../actions/modalAction';
import {fetchImagesOther} from "../../actions/imageAction";


class ShowImagesCompany extends Component {
  
  componentDidMount = () =>{
    const id = this.props.id
    this.props.fetchImagesOther(id)

  } 
  
  render() {
   
    const  images = () => {
      
        return this.props.images.imagePaths!== undefined && this.props.images.imagePaths.sort().map(value => ({
          original: `${value}`,
          //thumbnail:`${value.path}`,
          description: value.imageOf,
          }));     
      };

const renderImages = ()=>{
  if((this.props.images!== undefined && this.props.images!== null && this.props.images.imagePaths.length)>0 ){
    return (
<ImageGallery items={images()} showPlayButton={false} showThumbnails = {false}  />
    )
  }else{
    return (
      <div className='card-noShow' >
       Oops ! No Image Available
      </div>
    )
  }
}
      //***** */
    return (
      <div
      style = {{
        borderRadius: 3,
        fontSize:"16px",
        fontWeight:"bold",
        //background:'transparent',
         backgroundColor:'black',
        margin:'0px',
        padding: '0px',
      }}
      >
        <div className='disflex-paginator'>
        <span className='icon-button border1 '
        onClick={this.props.closeModal}
        style={{color:'white'}}
        > 
        <Icon name='close' />
        </span >
        </div>
        {renderImages()}
        </div>    
    );
  }
}

const mapState = state => ({
  state,
  images:state.images.imagesInfo,
});
export default connect(
  mapState,
  {closeModal,fetchImagesOther}
)(ShowImagesCompany);
