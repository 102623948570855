import React from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import {
  Image,
  //Segment,
  Header,
  Button,
  Icon
} from 'semantic-ui-react';
import Resizer from 'react-image-file-resizer';
import { toastr } from "react-redux-toastr";

import { fetchAppParameter } from "../../actions/commonAction";
import { getUserForAuth} from "../../actions/authAction";

class AddImage extends React.Component {
  state = {
    files: [],
    imageName: '',
    cropResult: null,
    image: {},
    imageURL: ''
  };

  componentDidMount() {
    if (!this.props.userA ){
      this.props.getUserForAuth()
        }
    if (!this.props.parameter ){
      this.props.fetchAppParameter()
        }
  }

  fileChangedHandler=(event)=> {
        var fileInput = false
        this.setState({files:event.target.files})
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
          Resizer.imageFileResizer(
                event.target.files[0],
                400,
                400,
                'JPEG',
                90,
                0,
                uri => {

                    let imageUrl = URL.createObjectURL(uri);
                    this.setState({
                      cropResult: imageUrl,
                      image: uri
                    });

                },
                'blob'
            );
        }
    }

    // ****************
  handleChange = event => {
    this.setState({ imageName: event.target.value });
  };
  cancelImage = () => {
    this.setState({
      files: [],
      image: {},
      cropResult: null,
    });
  };



  handleUploadImage = async ev => {
    ev.preventDefault();
    const imageURL=this.props.parameter !== null && this.props.parameter.imageProfileURL
    //const imageURL='https://image01.pp2p.in'
    const data = new FormData();
    data.append('file', this.state.image);
    data.append('imageName', this.state.imageName);
    data.append('imageURL', imageURL);
    data.append('id', this.props.userA.companyId);
     //await axios.post(`/api/company/addImage`, data);

     try {
       if(this.state.imageName){
                                   // `${imageURL}/${this.props.path}`
      const resImage = await axios.post(`${imageURL}/api/ImageAddProfile`, data);
if(resImage.data.msg ==='success'){
  const values = {
    imageName: this.state.imageName,
    path:resImage.data.path
  }
  
  const res= await axios.put(`/api/ImageCreateProfileBuilder`, values);
  if(res.data.msg ==='success'){
    // this.props.handleRefresh()
    // this.props.handleAddImageModalClose()
    toastr.success('Image Added Successfully')
  }
}
}else{
  toastr.error("select whose this image is");
}// end bracket for first if statement

    } catch (error) {

      toastr.error("Oops", "Something went wrong.Please Try Again_addimage");
    }
  //  this.props.history.push('/company/mycompany');

  };

  render() {
    const renderPreview =()=>{
      if(this.state.files.length >0){
        return(
          <React.Fragment>
          <div
          //style={{padding:'15px'}}
          >
          <br/>
          <Header  color="teal" content="Preview and Upload" />
            <br/>
            <Image
            src={this.state.cropResult}/>

            </div>


            <br /><br />
      <div className='disflex-paginator'>
            <Button.Group>
              <Button
                //loading={loading}
                onClick={this.handleUploadImage}
                // style={{ width: '120px' }}
                positive
                icon="check"
                content="upload"
              />
              <Button
                size='mini'
                onClick={this.cancelImage}
                // style={{ width: '100px' }}
                icon="close"
                content="cancell"
              />
            </Button.Group>

        </div>
        </React.Fragment>
        )
      }
    }
    return (


      <div>
      <br/>
      {/**/}
      <div className='margin-auto-H  border-radius-3'  >
      <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
      {/*  */}
      Add Profile Images
      {/*  */}
      </div>
      </div>
      {/**/} 
      <br/>
      <div className='disflex-paginator'>
        <form onSubmit={this.handleSubmit}>
          <Header color="teal" sub content="select whose this image is" />
          <label>
            <select value={this.state.value} onChange={this.handleChange}>
              <option value="">select whose this image is </option>
              <option value="cimage">company logo </option>
              <option value="photoCP1">first contact person</option>
              <option value="photoCP2">second contact person</option>
            </select>
          </label>
        </form>
        </div>
        <br />

        {/**/}
        <div className='disflex-paginator'>
                      <div>
          <label size="tiny" for="hidden-new-file" className="ui icon button">

            <span className="icon-size">
            <Icon  name="plus circle"/>
        </span><span className="icon-size">Add Image</span>

          </label>
          <input
          type="file" id="hidden-new-file" accept="image/*"
          style={{display: 'none'}}
          onChange={this.fileChangedHandler}
          />
        </div>
        {/*<Button
        color="teal"
        type="button"
        onClick={this.props.handleAddImageModalClose}
        >
        Close
        </Button>*/}
        </div>
        {/**/}

        {renderPreview()}

      </div>

    );
  }
}

const mapStateToProps = (state) => {

  return {
    parameter:state.commons.parameter,
    userA: state.auth.userA
  };
};

export default connect(
  mapStateToProps,
{ getUserForAuth,fetchAppParameter}
)(AddImage);
