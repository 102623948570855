import React, { Component } from "react";
import { connect } from "react-redux";
import {allSalePropertiesInProjectR} from "../../actions/propresiAction";
import PropResiSummary from './PropResiSummary';
import {price} from '../functions/Functions';


class AllPropResiSaleList extends Component {
  

  componentDidMount() {
    const values = {projectId:this.props.projectId};
    this.props.allSalePropertiesInProjectR(values);
  }
    
  //@@@
componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.projectId !== prevProps.projectId) {
      const values = {projectId:this.props.projectId};
      this.props.allSalePropertiesInProjectR(values);
      
    }
     }
  //@@@
  renderLoading=()=> {
    if (this.props.fetchingRS===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }
  renderNoProperty=()=> {
    if (this.props.propresisRS.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE </b></span>

        </div>
      );
    }
  }


  render() {
    const minVal = ()=> Math.min(...this.props.propresisRS.map((item)=>item.expectedPrice))
    const maxVal = ()=> Math.max(...this.props.propresisRS.map((item)=>item.expectedPrice))

    return (


<div>

{/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2'>
<span  > {this.props.countRS} Residential Property For Resale ({this.props.propresisRS.length >0 ? price(minVal()):0} ~ {this.props.propresisRS.length >0 ? price(maxVal()): 0} )</span>
</div>
      
</div>

    <div className='card-attached border-sb pxy-05'  >
    {this.renderNoProperty()}
{this.props.propresisRS.map((item)=> (<PropResiSummary  key={item._id}   propresi={item}/>))}
    </div>
{/*  */}

</div>

    );
  }
}



const mapStateToProps = ({ propresis,auth, form,images }) => {
  const { fetchingRS,limitRS, offsetRS, countRS } = propresis;

  return {
    fetchingRS,
    limitRS,
    offsetRS,
    countRS,
    form: form.PropResiSaleSearch,
    propresisRS: propresis.propresisRS,
    userA: auth.userA,
    imagesPRY:images.imagesPRY,
  };
};

export default connect(
  mapStateToProps,
 {allSalePropertiesInProjectR, }
)(AllPropResiSaleList);
