import axios from "axios";
import { toastr } from "react-redux-toastr";

import {
  // CREATE_IMAGES,
  // FETCH_IMAGES,
  // FETCH_IMAGE,
  SEARCH_IMAGES,
} from "./types";



//fetch PROPERTY Images

export const fetchPropertyImages = ( id) => async dispatch => {

  try {
    const res = await axios.post("/api/FetchPropertyImages", {id:id });

    
    dispatch({ type: SEARCH_IMAGES, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch PROject Images

export const fetchProjectImages = ( id) => async dispatch => {

  try {
    const res = await axios.post("/api/FetchProjectImages", {id:id });

    dispatch({ type: SEARCH_IMAGES, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch  Images

export const fetchImages = () => async dispatch => {

  try {
    const res = await axios.post("/api/FetchImages");

    dispatch({ type: SEARCH_IMAGES, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch others Images

export const fetchImagesOther = (id) => async dispatch => {

  try {
    const res = await axios.post("/api/FetchImagesOther",{id});

    dispatch({ type: SEARCH_IMAGES, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//delete Image
export const deleteImage = (id,val) => async () => {
  try {
    const res = await axios.delete(`/api/ImageDelete/${id}`,{val});
    switch (res.data.msg) {
  case 'errorAlready':
  toastr.error('Already Approved');
  break;
  case 'success':
  toastr.success('Deleted Successfully');
  break;
  default:
    toastr.success(' ');
}
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//********
//delete ImageInfo
export const deleteImageInfo = (val) => async dispatch => {
  
  try {
    const res = await axios.put(`/api/EditForImageInfoDelete`,val);
    if(res.data.msg ==='success'){
    dispatch({ type: SEARCH_IMAGES, payload: res.data });
    toastr.success('Image Deleted Successfully')
    }

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//create Image document for company and add info image first time
export const addFirstImageInfo = (val) => async dispatch => {
  
  try {
    const res = await axios.post(`/api/AddFirstImageInfo`,val);
    if(res.data.msg ==='success'){
    dispatch({ type: SEARCH_IMAGES, payload: res.data });
    toastr.success('Image Added Successfully')
    }

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};