import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
//import writtenNumber from "written-number";
import { withRouter} from "react-router-dom";
import { closeModal } from "../../actions/modalAction";
import { fetchPropComToBookAd } from "../../actions/propcomAction";
import {
  Form,
  Button,
} from "semantic-ui-react";
import SelectInput from "../form/SelectInput";
//import DateInput from "../form/DateInput";
//import NumberInput from "../form/NumberInput";

import {
  propertyForOption,
  propertyType,
  category,
  
} from '../propertyCom/Data';
import { projectCom, locality,
} from "../functions/DataFunctions";





class SearchBookAdPropCom extends Component {
    
  
  //** */
  onSubmit =  (values) => { 
    
      this.props.fetchPropComToBookAd(values)
  };
  
  render() {

    const { handleSubmit,  reset, submitting } = this.props;

    
      return (
        <div style={{maxWidth:'700px',margin:'auto'}}>
              {/**/}
    <div className='margin-auto-H' >
          <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
            {/*  */}
            Search Commercial Property To Book Ad
        {/*  */}
        </div>
        </div>
        {/**/}
        <div className="card-form mt-10">
          <div  >

              <Form onSubmit={handleSubmit(this.onSubmit)}
              >
              #  Leave All Field Blank (Clear Values) to View All Available Properties To Book Ad 
                        <br /><br />
                         

              <Button
            color="teal"
            type="button"
            onClick={()=>this.props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <br/><br/>

             


        <label >Property For</label>
        <Field
        name="propertyFor"
        placeholder="Property For"
        type="text"
        options={propertyForOption()}
        //search={true}
        component={SelectInput}
        />
        <label >Category</label>
        <Field
        name="category"
        placeholder="Category"
        type="text"
        options={category()}
        //search={true}
        component={SelectInput}
        />
        <label >Property Type</label>
        <Field
        name="propertyType"
        placeholder="Property Type"    
        type="text"
        options={propertyType()}
        //search={true}
        component={SelectInput}
        />
        {/* {renderSaleOrRentField()} */}
        {/* <label >Carpet Area In Square Foot</label>
        <Field
        type="number"
        component={NumberInput}
        placeholder="Carpet Area"
        name="carpetArea"
        /> */}
        
        {/* <label >Bedrooms</label>
        <Field
        name="bedroomA"
        placeholder="Bedrooms"
        type="text"
        options={bedroom()}
        search={true}
        multiple={true}
        component={SelectInput}
        /> */}
        {/* <label >Additional Rooms</label>
        <Field
        name="additionalRooms"
        placeholder="Additional Rooms"
        type="text"
        options={additionalRooms()}
        //search={true}
        multiple={true}
        component={SelectInput}
        /> */}
        
        <label >Society/Complex Name</label>
        <Field
        name="projectName"
        placeholder="Society/Complex Name"
        type="text"
        options={projectCom(this.props.state.projectCs.projectCsForData)}
        search={true}
        component={SelectInput}
        />
        

        <label >Locality</label>
        <Field
        name="localityA"
        placeholder="Locality"
        type="text"
        options={locality(this.props.state.appParameters.localitys)}
        search={true}
        multiple={true}
        component={SelectInput}
        />


        
        {/* <label >Advertised By</label>
        <Field
        name="advertisedBy"
        placeholder="You Are"
        type="text"
        options={advertisedBy()}
        //search={true}
        component={SelectInput}
        /> */}
        


{/* {propertyFor === 'SALE' &&
        (<>
        <label >Financer's Name</label>
        <Field
        name="financerName"
        placeholder="Dealer's Name"
        type="text"
        search={true}
        component={SelectInput}
        options={financerName(this.props.state.companys.companysForData)}
        />
        </>)
        } */}


            {/*  */}
                <br />

                <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"
              type="button"
              onClick={()=>this.props.closeModal()}

            >
              Cancel
            </Button>
              </Form>
              <br/><br/><br/>
            </div>
            </div>
            </div>
      );
    }
    
  }


export default connect(
  state => {

    return {
      state,
      userA: state.auth.userA,
    }; 
  },
   {closeModal,fetchPropComToBookAd}
)(
  reduxForm({
    form: "SearchBookAdPropCom", // a unique identifier for this form
    destroyOnUnmount: false
    //validate
  })(withRouter(SearchBookAdPropCom))
);

    
   
