const sortingO = [
  'Demand-Low To High',
  'Demand-High To Low',
  'Newest',
  'Oldest'
];
export const sorting = () => {
  return sortingO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};


//#########
const categoryO = [ 'DDA', 'CGHS','BUILDER'];
export const category = () => {
  return categoryO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};

//###############
const projectTypeO = [
  'Residential',
  'Commercial'
];
export const projectType = () => {
  return projectTypeO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############
const propertyTypeProjO = [

  'Flat',
  'Floor',
  'Penthouse',
  'Duplex House',
  'Residential Land /Plot'
];
export const propertyTypeProj = () => {
  return propertyTypeProjO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

const configurationO = ['1BHK','2BHK','2BHK +Servant',
'2BHK +Store','2BHK +Study', '3BHK','3BHK BIG' ,'3BHK DUPLEX',
'3BHK PENTHOUSE','3BHK +Servant','3BHK +Servant+Study',
'3BHK +Servant+Store','3BHK +Store','3BHK +Study', 
 '4BHK','4BHK DUPLEX','4BHK PENTHOUSE','4BHK +Servant','4BHK +Store','4BHK +Study',
 '4BHK +Servant+Study','4BHK +Servant+Store', '4BHK +Study+Store',
 '5BHK','5BHK +Servant','5BHK +Store','5BHK +Study'];
export const configuration = () => {
  return configurationO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

//#######
const amenitiesBasicO = [

'Power Back Up'	,
 'Piped Gas',
 '24 Hour Water Supply'	,
'Car Parking'	,
"Children's Play Area",
'Community Hall',
'Club House'	,
'Fire Fighting Systems',
'InterCom',
'Lift(s)',
'RO Water System',
'Water Storage',
'Visitor Parking',
'Service/Goods Lift',
'Escalators',
];
export const amenitiesBasic = () => {
  return amenitiesBasicO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const amenitiesConvSecO = [

  'Maintenance Staff'	,
  'Internet/wi-fi connectivity',
  'Intercom Facility',
  'Laundry Service'	,
  'Centrally Air Conditioned',
'Changing Area',
'Entrance Lobby',
'Piped Gas',
'Water Softener Plant',
'Wi-Fi Connectivity',
'Gated Community',
'CCTV Camera Security',
'24x7 Security',
'Security Cabin',
'Electronic Security',
'Security / Fire Alarm'	,
'Video Security'	,
'Security Personnel',
'Fire Fighting Equipment',
'Earthquake Resistant'
];
export const amenitiesConvSec = () => {
  return amenitiesConvSecO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const amenitiesEntSocO = [

  'Bar/Chill-Out Lounge',
'Shopping Malls',
'Shopping Center'	,
'Restaurant',
'Food Court',
'Cafeteria',
'Barbecue',
'Party Lawn',
'Reading Lounge',
'Sun Deck',
'Theatre',
'Club House',
'Multipurpose Hall',
'Community Hall',
'Banquet Hall',
'Waiting Lounge',
 'Conference Room'
];
export const amenitiesEntSoc = () => {
  return amenitiesEntSocO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const amenitiesServO = [

'Creche/Day care',
'Grocery Shop',
'School',
'Day Care Centre',
'Bank & ATM'
];
export const amenitiesServ = () => {
  return amenitiesServO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const amenitiesSportFitO = ['Badminton Court',
'Indoor Games',
'Lawn Tennis Court',
'Squash Court',
'Steam Room',
'Swimming Pool',
'Table Tennis',
'Toddler Pool',
'Yoga/Meditation Area',
'Aerobic room',
'Sports Facility',
'Kids Play Area',
'Fitness Centre / GYM',
 'Golf Course'	,
 'Billiards',
 'Jacuzzi',
'Spa'		,
 'Indoor Games Room',
'Indoor/Outdoor Tennis Courts',
'Jogging and Strolling Track'	,
'Cycling  Track',
'Play Ground',


];
export const amenitiesSportFit = () => {
  return amenitiesSportFitO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const amenitiesEcoO = [

  'Fountain',
'Garbage Disposal',
'Landscape Garden/Park',
'Paved Compound',
'Sewage Treatment Plant',
'Terrace Garden',
'Waste Disposal',
'green house',
'solar power'	,
'Feng Shui / Vastu Compliant',
'Rain Water Harvesting'
];
export const amenitiesEco = () => {
  return amenitiesEcoO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//############
const ageOfConstructionO = ['0+','01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '10+','15+','20+'];
export const ageOfConstruction = () => {
  return ageOfConstructionO.sort().map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};

//#######
const transactionTypeO = ['Resale', 'New Property', 'New Booking'];
export const transactionType = () => {
  return transactionTypeO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};


//#######
const statusO = ['New launch','Under Construction','Ready To Move','Upcoming']; //Immediate Possession /possession by date

export const status = () => {
  return statusO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
const possessionO = ['','Ready To Move', 'Ongoing']; //Immediate Possession

export const possession = () => {
  return possessionO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//############
const totalFloorsO = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '15+'
];
export const totalFloors = () => {
  return totalFloorsO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############

//###############
const localityO = [
  'Sector-01',
  'Sector-02',
  'Sector-03',
  'Sector-04',
  'Sector-05',
  'Sector-06',
  'Sector-07',
  'Sector-08',
  'Sector-09',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18A',
  'Sector-18B',
  'Sector-19A',
  'Sector-19B',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
const cityO = ['Dwarka', 'Rohini', 'Gurugram'];
export const city = () => {
  return cityO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
const stateO = ['Delhi', 'Haryana', 'U P'];
export const state = () => {
  return stateO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
