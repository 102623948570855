import React, { Component } from "react";
import { Link,withRouter  } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import {fetchAdByDealerToAddPropToStatic,} from "../../actions/dealerSuggestedAction";

import { localityO } from "./Data";
import {
  Icon,
  Button,
  Form,
} from "semantic-ui-react";



class AddPropertyToStatic extends Component {
state={
  valuesAddToProp:'',
  id:'',
  form:false,
  
}
  componentDidMount() {
    const val= this.props.valuesAddToProp !== null && this.props.valuesAddToProp
    
    const values = {projectType:val.adCategory,propertyFor:val.propertyFor};
    this.props.fetchAdByDealerToAddPropToStatic(values);
        
  }

   
   onSubmit = () => {
    const val= this.props.valuesAddToProp !== null && this.props.valuesAddToProp
    const values = {projectType:val.adCategory,propertyFor:val.adType,locality:this.state.locality};
    
    this.props.fetchAdByDealerToAddPropToStatic(values);
  };



handleUpdateToAddProp = async (value) => {
    
  const  id  = this.props.valuesAddToProp._id;
  const values = {...value,id}

    try {
      
      const res = await axios.put(`/api/StaticEditToAddProp`, values);

      if (res.data.msg ==='success'){
        this.props.handleModalClose()
        this.props.handleRefresh()
        toastr.success('Added Successfully')
      }
      
    } catch (error) {
      toastr.error("Oops", "Something went wrong.Please Try Again CreateAd");
    }
 
  
};
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

localityOption = () => {
  return localityO.sort().map(value => <option key={value} value={value}>{value}</option>);
};
//^^^^^^^^^^^^^^^^^^^^^^
  renderList=(item)=> {
    const {
    startedOn,
    expiredOn,
    creditUsed,
    //****************** */
    adFor,
    projectType,
    propOrProjId,
    propertyFor,
    propertyType,
    bedroom,
    additionalRooms,
    expectedPrice,
    expectedRent,
    carpetArea,
    area,
    areaUnit,
    projectName,
    //	projectId,
    locality,
    //createdAt,

    _id
    } = item;
//^^^^^^^^^^^^^^^^^
const value ={projectType, propOrProjId,}
//************************** render active/inactive/expired

    const renderStatus=() => {


    if (Date.now()<Date.parse(startedOn)){
      return (<span >
        Inactive
       </span>)
    }
    if (Date.now()>Date.parse(startedOn) && Date.now()<Date.parse(expiredOn) ){
      return (<span >
         Active
       </span>)
    }
    if (Date.now()>Date.parse(expiredOn)){
      return (<span >
        Expired
       </span>)
    }
    return (<span >
      No Status
     </span>)
    }


//**************************
const roomOrBHK = (projectType==='Residential') ? 'BHK' :'Room'
const renderAddPropButton = () =>
 (
        <Button
          
         // floated="right"
          color="blue"
          onClick={() => this.handleUpdateToAddProp(value)}
        >
          Add Property
        </Button>
      )

  
  //**************************
    const renderDetailButton=() => {

      if(adFor==="Property" && projectType==='Residential' ){
        return (<Button
          inverted
         //floated="right"
        //  size ='mini'
         color="blue"
           type="button"
           as={Link}
           to={`/propresi/PropResiDetail/${propOrProjId}`}
         >
          Detail
         </Button>)
      }
      if(adFor==="Property" && projectType==='Commercial' ){
        return (<Button
          inverted
         //floated="right"
        //  size ='mini'
         color="blue"
           type="button"
           as={Link}
           to={`/propcom/PropComDetail/${propOrProjId}`}
         >
          Detail
         </Button>)
      }
      if(adFor==="Project" && projectType==='Residential' ){
        return (<Button
          inverted
         //floated="right"
        //  size ='mini'
         color="blue"
           type="button"
           as={Link}
           to={`/projectR/ProjectRDetail/${propOrProjId}`}
         >
          Detail
         </Button>)
      }
      if(adFor==="Project" && projectType==='Commercial' ){
        return (<Button
          inverted
         //floated="right"
        //  size ='mini'
         color="blue"
           type="button"
           as={Link}
           to={`/projectC/ProjectCDetail/${propOrProjId}`}
         >
          Detail
         </Button>)
      }


            }

    //**************************
    const renderadditionalRooms = () => {
    return additionalRooms.map(item => {
    return (
    <span key={item}>
    {" "}
    {item}|
    </span>
    );
    });
    };
    const price = (value) => {
    var val = Math.abs(value)
    if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
    }
    /*else if(val >= 1000) val = (val/1000).toFixed(2) + ' K';*/
    return val;
    }

    //const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));

    return (
    <div key={_id}>
    <div className='card pxy-10 border3 mt-0 '  >
    {/*requester detqils start*/}
    <div ><h6 className='border-bottom mt-05 mb-05'>Advertisement Details
    <span className="color-dodgerblue ml-20 ">{ renderStatus()}</span>
    </h6></div>
    <div style={{fontSize:'1rem'}}>
    <div >
    {startedOn && (
    <div className='row'>
    <div className='side'>
    <b>Duration</b>
    </div>
    <div className='main'>
    <b>{ format(new Date(startedOn), "dd-MM-yyyy")}{' '}To{' '}{ format(new Date(expiredOn), "dd-MM-yyyy")}{' '}   </b>
    </div>

    </div>
    )}
    </div>

    <div >
    {creditUsed && (
    <div className='row'>
    <div className='side'>
    <b>Credit Used</b>
    </div>
    <div className='main'>
    <b>{creditUsed}{' '}</b>
    </div>

    </div>
    )}
    </div>




    <div >
    {projectName && (
    <div className='row'>
    <div className='side'>
    <b>Location</b>
    </div>
    <div className='main'>
    <b>

    <div>
    {bedroom && (

    <span >
    {bedroom}  {' '}{roomOrBHK}
    </span>)

    }{' '}
    {additionalRooms.length >0 && (

    <span >
    {renderadditionalRooms()}
    </span>)

    }{' '}
    {carpetArea >0 && (

    <span >
    {carpetArea} sq ft
    </span>)

    }{' '}
    {area && (

    <span >
    {area}
    </span>)

    }{' '}
    {areaUnit && (

    <span >
    {areaUnit}
    </span>)

    }{' '}
    {propertyType && (

    <span >
    {propertyType}
    </span>)

    }{' '}
    {propertyFor && (

    <span >
    For  {propertyFor}
    </span>)

    }{' '}
    {expectedPrice>0 && (

    <span >
    For  <Icon className="rupee" ></Icon>
    {price(expectedPrice)}{' '}In
    </span>)

    }{' '}
    {expectedRent>0 && (

    <span >
    For  <Icon className="rupee" ></Icon>
    {expectedRent}{' '}In
    </span>)

    }{' '}
    {projectName && (

    <span >
    {' '}{projectName}
    </span>)

    }{' '}
    {locality && (

    <span >
    {locality} Dwarka, New Delhi
    </span>)

    }{' '}
    <div>{renderDetailButton()}{renderAddPropButton()}</div>
    </div>

    </b>
    </div>

    </div>
    )}
    </div>

    </div>


    </div>
    </div>


    );
    }

    back = () => {
    const {
    offset,
    limit

    } = this.props;
    
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
    return;
    }

    this.props.fetchAdByDealerToAddPropToStatic(values, limit, (offset - limit));
    window.scroll(0,0)
    };

    advance = () => {
    const {
    offset,
    limit,
    count

    } = this.props;
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
    return;
    }

    this.props.fetchAdByDealerToAddPropToStatic(values, limit, (offset + limit));
    window.scroll(0,0)
    };

    handleGoToPage = () => {
      const {
  
      limit,
      count
  
      } = this.props;
  
      const values =
      (this.props.form !== undefined && this.props.form.values) || {};
      // use Math.abs for strict equality ===  to work and safe guard against negative value from user
      if (Math.abs(this.state.pageO) === 0) {
      return; 
      }
  
      const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)
  
      this.props.fetchAdByDealerToAddPropToStatic(values, limit, (page * limit));
      window.scroll(0,0)
  
      }

    renderPaginator() {
    if (this.props.adByDealer.length) {
    return (
    <Paginator
    advance={this.advance}
    back={this.back}
    offset={this.props.offset}
    limit={this.props.limit}
    count={this.props.count}
    //values={this.props.values}
    />
    );
    }
    }


    renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
    }

    renderNoProperty=()=> {
      if (this.props.adByDealer.length===0) {
        return (
          <div className='card-attached pxy-20'><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE </b></span>
  
          </div>
        );
      }
    }
//^^^^^^^^^^^^^^^^^^^^^^^^^
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//^^^^^^^^^^^^^^^^^^^^
    hideFormDispaly = ()=> this.setState({form:false})
    showFormDispaly = ()=> this.setState({form:true})
    renderSearchForm = ()=>
    (this.state.form === true)?(
      <div className='card-attached  pxy-20 mb-05' >
      <div>
        <Form onSubmit={this.onSubmit}>
    
        <div className='nodisplay'>
          <Form.Group>
                
            <Form.Field inline>
              <label>Locality</label>
              <select
                name="locality"
                value={this.state.locality}
                onChange={this.onChange}
              >
                <option value="">All</option>
                {this.localityOption()}
                {/* {options()} */}
              </select>
            </Form.Field>
            <Button 
            //inverted 
             color="blue" type="submit">
              Search
            </Button>
            <Button 
            //inverted 
             color="teal" onClick={this.hideFormDispaly}>
              Cancel
            </Button>
          </Form.Group>
          </div>
          {/*  */}
          <div className='nodisplay-tab'>
    
                
            <Form.Field >
            <label>Locality</label>
            <select
              name="locality"
              value={this.state.locality}
              onChange={this.onChange}
            >
              <option value="">All</option>
              {this.localityOption()}
              {/* {options()} */}
            </select>
            </Form.Field>
            <Button.Group>
            <Button 
            //inverted 
             color="blue" type="submit">
              Search
            </Button>
            <Button 
            //inverted 
            style={{marginLeft:'10px'}}
             color="teal" onClick={this.hideFormDispaly}>
              Cancel
            </Button>
          </Button.Group>
          </div>
        </Form>
    
        </div>
      </div>
    
    ):(
      <div></div>
    )
      //########## RENDER ###############################
    
    render() {
    
    return (

    <div className='maindiv-820'>
    <div  >


    <div>
      {/**/}
<div className='cashbook-H box-shadow mb-05'>
<button

onClick={this.props.handleModalClose}

className='back-btn'
>
<Icon name="arrow left" />

</button>
<div
className="disflex-center flex-15 pxy-05 ml-05"
onClick={this.showFormDispaly}>
      <span className='icon2-button border1' > <Icon name='search' /></span >
    </div>



    <div  className="disflex-center flex-85" >
<div  className="disflex-center pxy-05 font-20B"   >
All Advertisements
</div>
</div>


</div>
{/**/}
{this.renderSearchForm()}
        {/**/}
    {this.renderLoading()}
    {this.renderNoProperty()}
    {this.props.adByDealer.map(this.renderList)}

    {/*  */}
    {this.props.adByDealer !== undefined && this.props.adByDealer.length >0 &&
(<div className='disflex-paginator card-paginator border media-600'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>)}
{/*  */}
    <br/><br/>
    
    </div>
    <div className='nodisplay'>
    {/* side grid space*/}
    </div>


    </div>
    </div>

    );
    }
    }


    const mapStateToProps = ({ dealerSuggesteds, auth,
      //commons 
    }) => {
    const { fetching,limit, offset, count } = dealerSuggesteds;

    return {
    userA: auth.userA,
    fetching,
    limit,
    offset,
    count,
    adByDealer: dealerSuggesteds.adByDealer,
    //valuesAddToProp:commons.transporter
    };
    };

    export default connect(
    mapStateToProps,
    {fetchAdByDealerToAddPropToStatic,}
    )(withRouter(AddPropertyToStatic));
