import {
  FETCH_STATICS,
  FETCH_STATIC,
  SEARCH_STATICS,
  FETCHING_STATICS,
} from '../actions/types';

const INITIAL_STATE = {
  city:'', 
  fetching:true,
  statics: [],
  staticsC: [],
  staticsProperty: [],
  staticsDealer: [],
  staticTop1:[],
  staticTop2:[],
  staticBottom1:[],
  staticBottom2:[],
  static: null,
  offset: 0, // for static all
  limit: 10,
  offsetP: 0, // for static property
  limitP: 10,
  offsetD: 0, // for static dealer
  limitD: 10,
  offsetStatic: 0,
  limitStatic: 10,
  
  offsetStaticRSBF: 0,
  limitStaticRSBF: 1,
  offsetStaticRS: 0,
  limitStaticRS: 1,
  offsetStaticRR: 0,
  limitStaticRR: 1,
  offsetStaticCS: 0,
  limitStaticCS: 1,
  offsetStaticCR: 0,
  limitStaticCR: 1,
  offsetStaticDealerRSBF: 0,
  limitStaticDealerRSBF: 5,
  offsetStaticDealerRS: 0,
  limitStaticDealerRS: 5,
  offsetStaticDealerRR: 0,
  limitStaticDealerRR: 5,
  offsetStaticDealerCS: 0,
  limitStaticDealerCS: 5,
  offsetStaticDealerCR: 0,
  limitStaticDealerCR: 5,
  offsetStaticDealerMP: 0,
  limitStaticDealerMP: 20,
  
  
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_STATICS:
      return { ...state, ...action.payload };
    case FETCH_STATIC:
      return { ...state, static: action.payload };
    
    case SEARCH_STATICS:
      
      return { ...state, ...action.payload };
      case FETCHING_STATICS:
        return { ...state, fetching: action.payload };

    default:
      return state;
  }
};
