import React, { Component } from "react";

import { connect } from "react-redux";
import writtenNumber from "written-number";
import format from "date-fns/format";

import {fetchMyReqResis} from "../../actions/reqresiAction";

import { getUserForAuth } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import {
  Icon,
} from "semantic-ui-react";



class ReqResiSearchList extends Component {
  state = {

      open: false,
      result: "show the modal to capture a result"
    };
  componentDidMount() {
    
    if (this.props.auth.isAuthenticated) {
     
      this.props.fetchMyReqResis();
    }else{
      
      this.props.openModal('LoginModal')
    }
  }

    capitalizeFirst=(input)=> {
    var words = input.split(' ');
    var CapitalizedWords = [];
    words.forEach(element => {
    CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    });
    return CapitalizedWords.join(' ');
    }

    renderList=(reqresi)=> {
    const {
    name,
    phone1,
    phone2,
    email,
    whatsapp,
    _id,
    requiredFor,
    propertyType,
    bedroom,
    bathroom,
    additionalRooms,
    furnishedStatus,
    entry,
    floorNo,
    projectName,
    locality,
    budgetMin,
    budgetMax,
    areaCarpetMin,
    areaCarpetMax,

    note,
    createdAt,
    } = reqresi;


    const renderpropertyType = () => {
    return propertyType.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };
    const renderbedroom = () => {
    return bedroom.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };

    const renderbathroom = () => {
    return bathroom.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };

    const renderadditionalRooms = () => {
    return additionalRooms.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };
    const renderfurnishedStatus = () => {
    return furnishedStatus.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };
    const renderentry = () => {
    return entry.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };

    const renderfloorNo = () => {
    return floorNo.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };
    const renderprojectName = () => {
    return projectName.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };
    const renderlocality = () => {
    return locality.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };




    //**************************


    return (
    <div key={_id}>
      {/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Requirement For Residential Property For {requiredFor}</span>
	</div>

	</div>

	<div className='card-attached border-radius-bottom border-sb pxy-20 mb-010'  >

  		{/*  */}
    

    <div style={{padding:'10px',}} >

    <div >
    {name && (
    <div className='row'>
    <div className='side'>
    <b>Name</b>
    </div>
    <div className='main'>
    <b>{name}</b>
    </div>

    </div>
    )}
    </div>
    <div >
    {phone1 && (
    <div className='row'>
    <div className='side'>
    <b>Phone1</b>
    </div>
    <div className='main'>
    <b>{phone1}</b>
    </div>

    </div>
    )}
    </div>

    <div >
    {phone2 && (
    <div className='row'>
    <div className='side'>
    <b>Phone2</b>
    </div>
    <div className='main'>
    <b>{phone2}</b>
    </div>

    </div>
    )}
    </div>

    <div >
    {email && (
    <div className='row'>
    <div className='side'>
    <b>Email</b>
    </div>
    <div className='main'>
    <b>{email}</b>
    </div>

    </div>
    )}
    </div>

    <div >
    {whatsapp && (
    <div className='row'>
    <div className='side'>
    <b>WhatsApp ID</b>
    </div>
    <div className='main'>
    <b>{whatsapp}</b>
    </div>

    </div>
    )}
    </div>


    <div >
    {requiredFor && (
    <div className='row'>
    <div className='side'>
    <b>Require For</b>
    </div>
    <div className='main'>
    <b>{requiredFor}</b>
    </div>

    </div>
    )}
    </div>

    <div >
    {propertyType != null &&
    propertyType.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Type Of Property</b>
    </div>
    <div className='main'>
    {renderpropertyType()}
    </div>

    </div>
    )}
    </div>

    <div >
    {bedroom != null &&
    bedroom.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Bedroom</b>
    </div>
    <div className='main'>
    {renderbedroom()}
    </div>

    </div>
    )}
    </div>
    <div >
    {bathroom != null &&
    bathroom.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Bathroom</b>
    </div>
    <div className='main'>
    {renderbathroom()}
    </div>

    </div>
    )}
    </div>
    <div >
    {additionalRooms != null &&
    additionalRooms.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Additional Rooms</b>
    </div>
    <div className='main'>
    {renderadditionalRooms()}
    </div>

    </div>
    )}
    </div>



    <div >
    {furnishedStatus != null &&
    furnishedStatus.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Furnished Status</b>
    </div>
    <div className='main'>
    {renderfurnishedStatus()}
    </div>

    </div>
    )}
    </div>
    {/**/}



    <div >
    {floorNo != null &&
    floorNo.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Floor No</b>
    </div>
    <div className='main'>
    {renderfloorNo()}
    </div>

    </div>
    )}
    </div>

    <div >
    {entry != null &&
    entry.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Entry</b>
    </div>
    <div className='main'>
    {renderentry()}
    </div>

    </div>
    )}
    </div>

    {/**/}


    <div >
    {budgetMin > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Minimum Budget</b>
    </div>
    <div className='main'>
    <Icon className="rupee" ></Icon>
    <b> {budgetMin}</b>
    <b>
    {' '} ({this.capitalizeFirst(writtenNumber(budgetMin, {
    lang: "enIndian"
    }))}
    ){" "}
    </b>
    </div>

    </div>
    )}
    </div>

    <div >
    {budgetMax > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Maximum Budget</b>
    </div>
    <div className='main'>
    <Icon className="rupee" ></Icon>
    <b> {budgetMax}</b>
    <b>
    {' '} ({this.capitalizeFirst(writtenNumber(budgetMax, {
    lang: "enIndian"
    }))}
    ){" "}
    </b>
    </div>

    </div>
    )}
    </div>
    <div >
    {areaCarpetMin > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Minimum Carpet Area</b>
    </div>
    <div className='main'>

    <b> {areaCarpetMin}</b>
    <b>
    {' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMin, {
    lang: "enIndian"
    }))}
    ){" "} sq ft
    </b>
    </div>

    </div>
    )}
    </div>

    <div >
    {areaCarpetMax > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Maximum Carpet Area</b>
    </div>
    <div className='main'>

    <b> {areaCarpetMax}</b>
    <b>
    {' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMax, {
    lang: "enIndian"
    }))}
    ){" "} sq ft
    </b>
    </div>

    </div>
    )}
    </div>

    <div >
	{projectName  != null &&
	projectName.length > 0 && (
	<div className='rowERP-projName'>
	<div className='side'>
	<b>Complex/Project Preferred</b>
	</div>
	<div className='main display-linebreak'>
	{renderprojectName ()}
	</div>

	</div>
	)}
	</div>

    <div >
    {locality != null &&
    locality.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Locality</b>
    </div>
    <div className='main'>
    {renderlocality()}
    </div>
    </div>
    )}
    </div>


    <div >
    {createdAt && (
    <div className='row'>
    <div className='side'>
    <b>Created On</b>
    </div>
    <div className='main'>

    <b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
    </div>

    </div>
    )}
    </div>

    <div >
	{note && (
	<div className='rowERP-projName'>
	<div className='side'>
	<b>Note/Comments</b>
	</div>
	<div className='main display-linebreak'>
	<b>{note}</b>
	</div>

	</div>
	)}
	</div>


    {/**/}

    {/**/}
    </div >{/* end  3rd div */}

    </div>
    </div>

    );
    }

    renderLoading() {
    if (this.props.fetching===true) {
    return (
    <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

    </div>
    );
    }
    }
    renderNoRequirement() {
      if (this.props.reqresis.length <1) {
        return (
        <div className='maindiv-820 card-attached border-radius-top border-db'style={{textAlign:'center',paddingBottom:'100px',paddingTop:'100px'}}>
          <span style={{fontSize: "18px",color: "teal"}}>
            <b>NO REQUIREMENT AVAILABLE </b>
            </span>
    
        </div>
        );
        }
      }

    render() {
    return (
   
    <div className='maindiv-820'>


    <div>

    
{/**/}
<div className='cashbook-H box-shadow' style={{justifyContent: 'center'}}>


<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
<span>My Requirement For Residential Property</span>
</div>



</div>
{/**/}
    {this.renderNoRequirement()}
    {this.props.reqresis.map(this.renderList)}


    <br/><br/><br/><br/>
    
    </div>



    </div>
    


    );
    }
    }


    const mapStateToProps = ({ reqresis, selection, form,auth }) => {
    const { fetching,limit, offset, count } = reqresis;

    return {
    auth,
    fetching,
    limit,
    offset,
    count,
    form: form.ReqResiSearch,
    selection,
    reqresis: reqresis.reqresis,
    userA: auth.userA,
    };
    };
    export default connect(
    mapStateToProps,
    {getUserForAuth,fetchMyReqResis,openModal}
    )(ReqResiSearchList);
