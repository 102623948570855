import axios from "axios";
import { toastr } from "react-redux-toastr";
import { openModal, closeModal } from "./modalAction";

import {
  FETCH_STATICS,
  SEARCH_STATICS, 
  FETCHING_STATICS,
} from "./types";

export const resetFetching = () => async dispatch => {
  dispatch({ type:FETCHING_STATICS, payload: true });
};

//search  Statics
export const searchStatics = (
  values,
  limit,
  offset
) => async dispatch => {

  try {
    const res = await axios.post("/api/StaticSearch", {
      values,
      limit,
      offset
    });

    
    dispatch({ type: SEARCH_STATICS, payload: res.data });
    dispatch({ type:FETCHING_STATICS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }
};
//*********************************
//fetch all Statics prop for Advertisement by one Dealer
export const fetchStaticPropAdByDealer = ( 
  values,
  limitP,
  offsetP ) => async dispatch => {

  try {
    const res = await axios.post("/api/StaticPropAdByDealer",{
    values,
    limitP,
    offsetP
    } );

    
    dispatch({ type: FETCH_STATICS, payload: res.data });
    dispatch({ type:FETCHING_STATICS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};
//*****************************
///fetch all Statics prop for Advertisement by one Dealer
export const fetchStaticDealerAdByDealer = ( 
  values,
  limitD,
  offsetD ) => async dispatch => {

  try {
    const res = await axios.post("/api/StaticDealerAdByDealer",{
      values,
      limitD,
      offsetD
    } );

    
    dispatch({ type: FETCH_STATICS, payload: res.data });
    dispatch({ type:FETCHING_STATICS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};

//update Static and props to remove props
export const updateStaticToRemoveProp = ( values) => async dispatch => {
  try {
    const res = await axios.put(`/api/StaticEditToRemoveProp`, values);

    if (res.data.msg ==='success') {
      toastr.success('Removed Successfully');
    }
    
    dispatch({ type: FETCH_STATICS, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};
//******************
//List similar sale Properties
export const similarSalePropertiesResiBuilderFloor = (
  values,
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/SimilarSalePropertiesResiBuilderFloor", {
      values,

    });

    

    dispatch({ type: SEARCH_STATICS, payload: res.data });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//******************
//List similar sale Properties
export const similarSalePropertiesResi = (
  values,
) => async dispatch => {
  
  try {
    const res = await axios.post("/api/SimilarSalePropertiesResi", {
      values,

    });

    

    dispatch({ type: SEARCH_STATICS, payload: res.data });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//List similar rent Properties
export const similarRentPropertiesResi = (
  values,

) => async dispatch => {
  
  try {
    const res = await axios.post("/api/SimilarRentPropertiesResi", {
      values,

    });

    

    dispatch({ type: SEARCH_STATICS, payload: res.data });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//******************
//List similar sale Properties
export const similarSalePropertiesCom = (
  values,

) => async dispatch => {

  try {
    const res = await axios.post("/api/SimilarSalePropertiesCom", {
      values,

    });

    
 
    dispatch({ type: SEARCH_STATICS, payload: res.data });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//List similar rent Properties
export const similarRentPropertiesCom = (
  values,

) => async dispatch => {
 
  try {
    const res = await axios.post("/api/SimilarRentPropertiesCom", {
      values,

    });

    
  
    dispatch({ type: SEARCH_STATICS, payload: res.data });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


//****************************
//fetch all companies  fetchCompanysForPropListRSBF

export const fetchCompanysForPropListRSBF = (
    limitStaticDealerRSBF,
    offsetStaticDealerRSBF
) => async dispatch => {
  try {
    const res = await axios.post("/api/CompanysForPropListRSBF", 
    {  
      limitStaticDealerRSBF,
      offsetStaticDealerRSBF
    });
    
    dispatch({ type: FETCH_STATICS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


//fetch all companies  fetchCompanysForPropListRS

export const fetchCompanysForPropListRS = (
      limitStaticDealerRS,
      offsetStaticDealerRS
  ) => async dispatch => {
    try {
      const res = await axios.post("/api/CompanysForPropListRS", 
      { 
        limitStaticDealerRS,
        offsetStaticDealerRS 
      });
    
      dispatch({ type: FETCH_STATICS, payload: res.data });
    } catch (error) {
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  };
  

//fetch all companies  fetchCompanysForPropListRS

export const fetchCompanysForPropListRR = (
  limitStaticDealerRR,
  offsetStaticDealerRR
) => async dispatch => {
  try {
    const res = await axios.post("/api/CompanysForPropListRR", 
    {  
      limitStaticDealerRR,
      offsetStaticDealerRR 
    });
    
    dispatch({ type: FETCH_STATICS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//**********************
//fetch all companies  fetchCompanysForPropListRS

export const fetchCompanysForPropListCS = (
    limitStaticDealerCS,
    offsetStaticDealerCS
) => async dispatch => {
  try {
    const res = await axios.post("/api/CompanysForPropListCS", 
    {
      limitStaticDealerCS,
      offsetStaticDealerCS 
      });
    
    dispatch({ type: FETCH_STATICS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//fetch all companies  fetchCompanysForPropListCS

export const fetchCompanysForPropListCR = (
    limitStaticDealerCR,
    offsetStaticDealerCR
) => async dispatch => {
  try {
    const res = await axios.post("/api/CompanysForPropListCR", 
    {  
      limitStaticDealerCR,
      offsetStaticDealerCR
   });
    
    dispatch({ type: FETCH_STATICS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//fetch all companies  fetchCompanysForMainPage

export const fetchCompanysForMainPage = () => async dispatch => {
    try {
      const res = await axios.post("/api/CompanysForMainPage");
      
      dispatch({ type: FETCH_STATICS, payload: res.data });
    } catch (error) {
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  };
//**********************
//resetOffset

export const resetOffsetStatics = () => async dispatch => {
  
  try {
        
    dispatch({ type: SEARCH_STATICS, payload: {offset:0} });
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};