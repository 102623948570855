import React, { Component } from "react";

import ProjectRSummary from './ProjectRSummary';


class ProjectRSearchListBuilder extends Component {
     
   
  renderNoProject=()=> {
    if (this.props.project.length===0) {
      return (
        <div>
          <span style={{fontSize: "18px",color: "teal"}}>
            <b>NO PROJECT AVAILABLE </b>
            </span>

        </div>
      );
    }
  }


  render() {

    return (


<div>


<div className='card' style={{textAlign:'center',padding:'0px',marginTop:'0px',backgroundColor:'dodgerblue',color:'white'}} >

<div className="heading-1"   >
<span>{this.props.countProject}{' '} {this.props.heading}</span>
</div>
  
</div>

<div className = 'pxy-05'>
{this.renderNoProject()}
{this.props.project.map((item)=> (<ProjectRSummary  key={item._id}   projectResi={item}/>))}
</div>

</div>

    );
  }
}


export default ProjectRSearchListBuilder
