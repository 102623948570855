import React from 'react';
import { withRouter, } from "react-router-dom";
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import writtenNumber from 'written-number';
import {capitalizeFirst} from "../functions/Functions";
import {resetOffsetPropResis} from '../../actions/propresiAction';
import { closeModal } from "../../actions/modalAction";

import {  Form, Button,   Popup, } from 'semantic-ui-react';
import NumberInput from "../form/NumberInput";
import SelectInput from '../form/SelectInput';


import {
  
  propertyFor,
  
} from './DataS';
import {
  propertyType,
  bedroom,
  
  locality,
  
} from './Data';

const PropResiRentSearch = props => {
  const { handleSubmit,  reset, submitting } = props;
  const { maxRent, minRent } =
    props.state.form &&
    props.state.form.PropResiRentSearch !== undefined &&
    props.state.form.PropResiRentSearch.values !== undefined &&
    props.state.form.PropResiRentSearch.values;

    const projectName = () => {
      return props.state.projectRs.projectRsForData.map(value => ({
        key: value.projectName,
        text: `${value.projectName}` ,
        value: value.projectName,style:{fontSize:'18px'}
      }));
    };

    
    const onSubmit = () => {
    props.resetOffsetPropResis();
    props.closeModal()
    props.history.push(`/propresi/PropResiRentSearchList`);
  };

  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Residential Property Available for Rent
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
             #  Leave All Field Blank (Clear Values) to View All Available Properties 
           <br /><br />
            <Button
            color="teal"
            type="button"
            onClick={()=>props.closeModal()}

            >
            Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <br/><br/>
          <label >Property For</label>
             <Field
              name="propertyFor"
              placeholder="Property For"
              type="text"
              options={propertyFor()}
              //search={true}
              component={SelectInput}
            />


            <label >Property Type</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search All Available Property Type or
        Enter Single or Multiple Values to Search Those Property Type
            </Popup>
            <Field
              name="propertyTypeA"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Bedroom</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search Available Properties for All Bedrooms or
        Enter Single or Multiple Values to Search Properties for Those Bedrooms
            </Popup>
            <Field
              name="bedroomA"
              placeholder="Bedroom"
              type="text"
              options={bedroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />


            <label >
              Minimum Rent{' '}
              <span>
                ({minRent && capitalizeFirst(writtenNumber(minRent, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Enter Lower Value of your Budget Range
            </Popup>
            <Field
              name="minRent"
              placeholder="Minimum Rent"
              type="number"
              component={NumberInput}
            />
            <label >
              Maximum Rent{' '}
              <span>
                ({maxRent && capitalizeFirst(writtenNumber(maxRent, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Enter Higher Value of your Budget Range
            </Popup>
            <Field
              name="maxRent"
              placeholder="Maximum Rent"
              type="number"
              component={NumberInput}
            />



            <label >Society/Complex Name</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search Properties in All Society/Complex of Dwarka or
        Enter Single or Multiple Values to Search Properties in Those Society/Complex
            </Popup>
            <Field
              name="projectNameA"
              placeholder="Enter Key Word To Search"
              type="text"
              options={projectName()}
              search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Locality</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search Properties in All Sectors of Dwarka or
        Enter Single or Multiple Values to Search Properties in Those Sectors
            </Popup>
            <Field
              name="localityA"
              placeholder="Enter Key Word To Search"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />


            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
              // as={Link}
              // to={`/propresi/PropResiRentSearchList`}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA,
  };
};
export default connect(
  mapStateToProps,
  {resetOffsetPropResis,closeModal}
)(
  reduxForm({
    form: 'PropResiRentSearch', // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(withRouter(PropResiRentSearch))
);
