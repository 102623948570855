import {
  FETCH_REQCOMS,
  FETCH_REQCOM,
  SEARCH_REQCOMS,
  FETCHING_REQCOMS,
} from '../actions/types';

const INITIAL_STATE = {
  city:'', 
  fetching:true,
  reqcoms: [],
  reqcomsULock: [],
  reqcom: null,
  offset: 0,
  offsetULock: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REQCOMS:
      return { ...state, ...action.payload };
    case FETCH_REQCOM:
      return { ...state, reqcom: action.payload };
    
    case SEARCH_REQCOMS:
      
      return { ...state, ...action.payload };
      case FETCHING_REQCOMS:
        return { ...state, fetching: action.payload };
    
    default:
      return state;
  }
};
