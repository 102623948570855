import React, { Component } from "react";
import { connect } from "react-redux";

import {  withRouter } from "react-router-dom";
import DealerSuggestedListForProp from './DealerSuggestedListForProp';
import { closeModal } from '../../actions/modalAction';

import {
  Icon,
} from "semantic-ui-react";



class ContactDetailForPropList extends Component {
 

 render() {

  const {
    advertisedBy,
    expiredOn,
      name,
      phone1,
      phone2,
      email,
      whatsapp,
  } = this.props.forContactReq !== undefined && this.props.forContactReq !== null && this.props.forContactReq.ownerContactDetail
 

    
    const {propertyFor} = this.props.forContactReq !== null && this.props.forContactReq.contactReq
      //******************
const renderBuyOrRent =()=>{
  if(propertyFor==='SALE'){
    return  (<span>BUY</span>)
  }
  if((propertyFor==='RENT') || (propertyFor==='PG ACCOMMODATION')){
    return  (<span>RENT</span>)
  }
  
}
   const renderContactDetail = () => {
    if((advertisedBy !== 'Others') && (Date.parse(expiredOn) >=  Date.now())){
    return (
    <div >

       <div >
       {name && (
         <div className='row'>
           <div className='side'>
             <b>Name</b>
           </div>
           <div className='main'>
             <b>{name}</b>
           </div>

         </div>
       )}
     </div>

     <div >
     {phone1 && (
       <div className='row'>
         <div className='side'>
           <b>Phone1</b>
         </div>
         <div className='main'>
           <b>{phone1}</b>
         </div>

       </div>
     )}
   </div>

   <div >
   {phone2 && (
     <div className='row'>
       <div className='side'>
         <b>Phone2</b>
       </div>
       <div className='main'>
         <b>{phone2}</b>
       </div>

     </div>
   )}
 </div>

 <div >
 {email && (
   <div className='row'>
     <div className='side'>
       <b>Email</b>
     </div>
     <div className='main'>
       <b>{email}</b>
     </div>

   </div>
 )}
</div>

<div >
{whatsapp && (
 <div className='row'>
   <div className='side'>
     <b>Whatsapp ID</b>
   </div>
   <div className='main'>
     <b>{whatsapp}</b>
   </div>

 </div>
)}
</div>

</div>
)
    }else{ 
    return(
    <div><h6>Owner Contact Details are Not Available </h6></div>
    );

    }
    }
    //**** */
   return (
    <div className = 'maindiv-768' >
      
    {/**/}
    <div className='card-attached border-radius-top border-db disflex' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.props.closeModal}>
      <span className='back-btn' > <Icon name="arrow left" /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
Contact Detail
</div>
</div>


</div>
{/**/}
    <div className='card-attached border-sb border-db-bottom'  >


    <div className='pxy-20' >
    
    <div className='border-bottom heading' >
    <span  > Owner Contact Detail </span>
    </div>
    {renderContactDetail()}
    </div>
    
<div className="heading-1"   >

<span>You Can Also Contact The Following Dealers For Assistance And Consultancy To {renderBuyOrRent()} this Property </span>

</div>

<DealerSuggestedListForProp  contactReq={this.props.forContactReq.contactReq} /> 
<br/>
     </div>
    
     </div>
     //**********************************

   );
 }
}

export default connect(
  null,
  {closeModal}
)(withRouter(ContactDetailForPropList));

