import axios from "axios";
import { toastr } from "react-redux-toastr";
import { openModal, closeModal } from "./modalAction";

import {
  FETCH_CONTACTREQS,
  FETCH_CONTACTREQ,
  SEARCH_CONTACTREQS,
  FETCHING_CONTACTREQS,
} from "./types";

export const resetFetching = () => async dispatch => {
  dispatch({ type:FETCHING_CONTACTREQS, payload: true });
};

//search  ContactReqs
export const searchContactReqs = (
  values,
  limit,
  offset
) => async dispatch => {

  try {
    const res = await axios.post("/api/ContactReqSearch", {
      values,
      limit,
      offset
    });

    dispatch({ type: SEARCH_CONTACTREQS, payload: res.data });
    dispatch({ type:FETCHING_CONTACTREQS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};
//fetch all ContactReqs for one Dealer
export const fetchContactReqsForDealer = (
  values,
  limit,
  offset) => async dispatch => {

  try {
    const res = await axios.post("/api/ContactReqListForDealer",{
      values,
      limit,
      offset
    } );

    dispatch({ type: FETCH_CONTACTREQS, payload: res.data });
    dispatch({ type:FETCHING_CONTACTREQS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};

//fetch all ContactReqs for one Dealer
export const fetchContactReqsForBuilder = (
  values,
  limit,
  offset) => async dispatch => {

  try {
    const res = await axios.post("/api/ContactReqListForBuilder",{
      values,
      limit,
      offset
    } );

    dispatch({ type: FETCH_CONTACTREQS, payload: res.data });
    dispatch({ type:FETCHING_CONTACTREQS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};
//fetch all ContactReqs for one party
export const fetchContactReqsByParty = (
  values,
  limit,
  offset) => async dispatch => {

  try {
    const res = await axios.post("/api/ContactReqListByParty",{
     values,
     limit,
      offset
    } );

    dispatch({ type: FETCH_CONTACTREQS, payload: res.data });
    dispatch({ type:FETCHING_CONTACTREQS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};

//fetch single ContactReq
export const fetchContactReq = id => async dispatch => {
  try {
    const res = await axios.get(`/api/ContactReqDetail/${id}`);
    
    dispatch({ type: FETCH_CONTACTREQ, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


//update ContactReq to inactive by party
export const updateContactReqToInactiveByParty = (id,values,offset) => async dispatch => {
  try {
    const res = await axios.put(`/api/UpdateContactReqToInactiveByParty/${id}`,{values,offset});
    
    switch (res.data.msg) {
      case 'errorAlready':
      toastr.error('Request Already Sent To This Dealer');
      break;
      case 'success':
      toastr.success('Deleted Successfully');
      break;
      default:
        toastr.success(' ');
    }
    dispatch({ type: FETCH_CONTACTREQS, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};
//update ContactReq to inactive by dealer
export const updateContactReqToInactiveForDealer = (id,values,offset) => async dispatch => {
  try {
  const res = await axios.put(`/api/UpdateContactReqToInactiveForDealer/${id}`,{values,offset});
    
    switch (res.data.msg) {
      case 'errorAlready':
      toastr.error('Request Already Sent To This Dealer');
      break;
      case 'success':
      toastr.success('Deleted Successfully');
      break;
      default:
      toastr.success(' ');
    }
    dispatch({ type: FETCH_CONTACTREQS, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};
// //delete ContactReq
// export const deleteContactReq = (id) => async dispatch => {
//   try {
//     const res = await axios.delete(`/api/ContactReqDelete/${id}`);
    
//     dispatch({ type: DELETE_CONTACTREQ, payload: res.data });
//   } catch (error) {
//     if(error.response.status==401){
//       dispatch(openModal('LoginModal')); 
//       toastr.error(`${JSON.stringify(error.response.data) }`);
//     }else{
//       toastr.error("Oops", "Something went wrong.Please Try Again");
//     }
//   }

// };



//########################################################################
// create ContactReq
export const createContactReq = ( values) => async (dispatch) => {
  try {

    const res = await axios.post(`/api/ContactReqCreate`, values);  
    if (res.data.msg ==='errorAlready') {
      if(res.data.requestFor !=='General'){
    toastr.error(`Request Already Sent For This ${res.data.requestFor}  To This ${res.data.companyType}`);
    }
    if(res.data.requestFor ==='General'){
      toastr.error(`A ${res.data.requestFor} Request Already Sent To This ${res.data.companyType}`);
      }
  }
    if (res.data.msg ==='success') {
      if(res.data.requestFor !=='General'){
        toastr.success(`Contact Request Successfully Sent For This ${res.data.requestFor}  To This ${res.data.companyType}`);
        }
        if(res.data.requestFor ==='General'){
          toastr.success(`A ${res.data.requestFor} Contact Request Successfully Sent To This ${res.data.companyType}`);
          }
      
      }
    
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};
//************************************************************************
