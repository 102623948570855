import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../common/Loading"; 
import {similarSalePropertiesResi} from "../../actions/staticAction";
import PropResiSummary from './PropResiSummary';




class SimilarPropResiSaleList extends Component {
  state = {
    id:'',
  };

  componentDidMount() {
    const values = this.props.values;
    this.props.similarSalePropertiesResi(values);
  }
  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if (this.props.values.bedroom !== prevProps.values.bedroom) {
      const values = this.props.values;
    this.props.similarSalePropertiesResi(values);
      }
     }

  
  

  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }
  renderNoProperty=()=> {
    if (this.props.statics.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE </b></span>

        </div>
      );
    }
  }


  render() {
    
    return (


<div style= {{margin:'0px'}} >


    
        {/*  */}
        <div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
    
    <div className='heading-2'>
<span  > {this.props.header}</span> 
</div>
      
          </div>

    <div className='card-attached border-sb pxy-05'  >
    {this.renderNoProperty()}
{this.props.statics.map((item)=> (<PropResiSummary  key={item._id}   propresi={item}/>))}
    </div>
    {/*  */}


<br/><br/><br/><br/>
</div>

    );
  }
}



const mapStateToProps = ({ statics,auth,  }) => {
  const { fetching,limit, offset, count } = statics;

  return {
    fetching,
    limit,
    offset,
    count,
    statics: statics.statics,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {similarSalePropertiesResi, }
)(SimilarPropResiSaleList);
