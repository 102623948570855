import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { openModal } from "../../actions/modalAction";
import {fetchUser} from "../../actions/authAction";
import {
  
  Container,
  
  Segment,
  Button,
  Header,
 
} from "semantic-ui-react";




class UserDetail extends Component {
  state = {
    id: "",
    open: false,
    result: "show the modal to capture a result"
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (this.props.auth.isAuthenticated) {
     
      this.props.fetchUser(id);
    }else{
      
      this.props.openModal('LoginModalBySetInt')
    }
    
  }
 
  

  render() {
    const {
      userId,uphone1,uphone2,uwhatsapp, uname, uemail, companyName, city, _id,
    } =
      this.props.user !== undefined &&
      this.props.user !== null &&
      this.props.user;
    //const { open, result } = this.state;
    
    return (
      <Container text>
        
        <Segment
          textAlign="center"
          attached="top"
          inverted
          color="teal"
          style={{ border: "none" }}
        >
          <Header> User Detail</Header>
        </Segment>
        <Segment attached>
          <Button.Group size="mini" floated="right">
            <Button
              // basic
              inverted
              floated="right"
              color="green"
              type="button"
              as={Link}
              to={`/company/UserEdit/${_id}`}
            >
              Edit
            </Button>
            {/*<Button
              // basic
              inverted
              floated="right"
              color="red"
              type="button"
              onClick={() => this.show()}
            >
              Delete
            </Button>*/}
          </Button.Group>
          <br />
          <br />
          <div >
          {userId && (
        <div className='row'>
        <div className='side'>
        <b>User Id</b>
        </div>
        <div className='main'>
        <b>{userId}</b>
        </div>

        </div>
        )}
        </div>
          <div >
        {uname && (
        <div className='row'>
        <div className='side'>
        <b>User Name</b>
        </div>
        <div className='main'>
        <b>{uname}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uphone1 && (
        <div className='row'>
        <div className='side'>
        <b>User Phone1</b>
        </div>
        <div className='main'>
        <b>{uphone1}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uphone2 && (
        <div className='row'>
        <div className='side'>
        <b>User Phone2</b>
        </div>
        <div className='main'>
        <b>{uphone2}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uemail && (
        <div className='row'>
        <div className='side'>
        <b>User Email</b>
        </div>
        <div className='main'>
        <b>{uemail}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {uwhatsapp && (
        <div className='row'>
        <div className='side'>
        <b>User Whatsapp ID</b>
        </div>
        <div className='main'>
        <b>{uwhatsapp}</b>
        </div>

        </div>
        )}
        </div>



        <div >
        {companyName && (
        <div className='row'>
        <div className='side'>
        <b>Company Name</b>
        </div>
        <div className='main'>
        <b>{companyName}</b>
        </div>

        </div>
        )}
        </div>
        <div >
        {city && (
        <div className='row'>
        <div className='side'>
        <b>City</b>
        </div>
        <div className='main'>
        <b>{city}</b>
        </div>

        </div>
        )}
        </div>
          
         
        </Segment>
      </Container>
    );
  
  
  }
}
 

const mapStateToProps = ({ auth }) => {
 
  return {
    auth, 
    user: auth.userD,
    userA:auth.userA
  };
};

export default connect(
  mapStateToProps,
  {fetchUser, openModal}
)(UserDetail);
