import React, { Component } from "react";
import { connect } from "react-redux";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading";
import {searchPropResiRents} from "../../actions/propresiAction";
import { fetchCompanysForPropListRR } from "../../actions/staticAction";
import { openModal } from "../../actions/modalAction";

import {
  Icon,
} from "semantic-ui-react";
import DealerSuggestedStatic from '../dealerSuggesteds/DealerSuggestedStatic';
import PropResiSummary from './PropResiSummary';




class PropResiRentSearchList extends Component {
  state = {
    pageO:'',
    id:'',
  };
  componentDidMount() {
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
      
      const {
        offsetRR,
        limitRR,
        limitStaticRR,
        offsetStaticRR,
        offsetStaticDealerRR ,
        limitStaticDealerRR,
    } = this.props;
      
    
    this.props.searchPropResiRents(values ,limitRR, offsetRR,limitStaticRR,offsetStaticRR);
    this.props.fetchCompanysForPropListRR(limitStaticDealerRR,offsetStaticDealerRR )

  }
  handleSearchPropResiRent = () => {
    this.props.openModal("SearchPropResiRentModal");
  };

  
    //^^^^^^^^^^^^^^^^^^^^^^^^^
    onChange=(e) => {
      this.setState({ [e.target.name]: e.target.value });
    }
  //*******************************************************
  
   
  back = () => {
    const {
      offsetRR,
      limitRR,
      limitStaticRR,
      offsetStaticRR,
      offsetStaticDealerRR ,
      limitStaticDealerRR,
  } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    if (offsetRR === 0) {
      return;
    }
      this.props.searchPropResiRents(values, limitRR, (offsetRR - limitRR),limitStaticRR, (offsetStaticRR - limitStaticRR));
      this.props.fetchCompanysForPropListRR( limitStaticDealerRR, (offsetStaticDealerRR - limitStaticDealerRR));

    window.scroll(0,0)
  };

  advance = () => {
    const {
        countRR,
        offsetRR,
        limitRR,
        limitStaticRR,
        offsetStaticRR,
        offsetStaticDealerRR ,
        limitStaticDealerRR,
      
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
    if (offsetRR + limitRR > countRR) {
      return;
    }

    this.props.searchPropResiRents(values, limitRR, (offsetRR + limitRR), limitStaticRR, (offsetStaticRR + limitStaticRR));
    this.props.fetchCompanysForPropListRR( limitStaticDealerRR, (offsetStaticDealerRR + limitStaticDealerRR));

    window.scroll(0,0)
  };
//***************** */
handleGoToPage = () => {
  const {
    countRR,
    limitRR,
    limitStaticRR,
    limitStaticDealerRR,
} = this.props;
  
  const values =
    (this.props.form !== undefined && this.props.form.values) ||{localityA:[]};
   // use Math.abs for strict equality ===  to work and safe guard against negative value from user
   if (Math.abs(this.state.pageO) === 0) {
    return; 
  }

const page=(Math.abs(this.state.pageO*limitRR) > countRR)?(Math.ceil(countRR/limitRR-1)):(Math.abs(this.state.pageO)-1)


  //const page = (this.state.pageO-1)
    this.props.searchPropResiRents(values, limitRR, (page * limitRR),    limitStaticRR, (page * limitStaticRR));
    this.props.fetchCompanysForPropListRR( limitStaticDealerRR, (page * limitStaticDealerRR));
    window.scroll(0,0)

};

//****************** */
  renderPaginator=()=> {
    if (this.props.propresisRR.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offsetRR}
          limit={this.props.limitRR}
          count={this.props.countRR}
        />
      );
    }
  }

  renderLoading=()=> {
    if (this.props.fetchingRR===true) {
      return (<Loading/>)
    }
  }



  render() { 
    

    return (

      <div className='maindiv-820 '>
      
      
      <div> {/* main column start */}
      
      <div className='card-attached border-radius-top border-sb' style={{textAlign:'center',backgroundColor:'dodgerblue'}} >
        
      {/**/}
      <div style={{display:'flex',color:'white',padding:'5px'}}>
      
      <div
      style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
      onClick={this.handleSearchPropResiRent}>
            <span className='icon-button2 border1' > <Icon name='search' /></span >
          </div>
      
      
      
      <div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
      <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
      {this.props.countRR} Residential Property For Rent in {this.props.city}
      </div>
      </div>
      
      
      </div>
      {/**/}
      
        </div>
        {/*  */}
      
          
        <div className='card-attached border-sb pxy-05'  >
      
      {this.renderLoading()}
      
      {this.props.staticTop1.map((item)=> (<PropResiSummary  key={item._id}   propresi={item} nameClass={'static'}/>))}
      {this.props.staticTop2.map((item)=> (<PropResiSummary  key={item._id}   propresi={item} nameClass={'static'}/>))}
      
      {this.props.propresisRR.map((item)=> (<PropResiSummary  key={item._id}   propresi={item}/>))}
      
      {this.props.staticBottom1.map((item)=> (<PropResiSummary  key={item._id}   propresi={item} nameClass={'static'}/>))}
      {this.props.staticBottom2.map((item)=> (<PropResiSummary  key={item._id}   propresi={item} nameClass={'static'}/>))}
      
      
      </div>
      
      
      
      {/* static dealers ad */}
      <div className='card-attached border-sb '  >
      {this.props.staticsDealer && this.props.staticsDealer.length >0 && (
      
      <div  style={{padding:'10px',lineHeight:'24px', fontSize:'16px',textAlign:'center',backgroundColor:'dodgerblue',color:'white'}} >
      
      <div ><b>Dealers For Assistance And Consultancy To Buy/Sell/Rent In All Sectors Of Dwarka, Delhi</b></div>
      
      </div>
      )}
      
      
      
      <div className='disflex-DLMP media-600'
      
      //style={{flexDirection:'column',justifyContent:'center',alignItems:'center'}}
      >
      {this.props.staticsDealer.map((item)=>(<DealerSuggestedStatic  key={item._id}   company={item}/>))}
      </div>
      
      </div>
      {/*  */}
      
      <div className='card-attached border-sb '  >
      
      <div className='disflex-paginator card-paginator media-600'>
      {this.renderPaginator()}
      <div className="disflex-paginator  " >
        <div className= 'font-paginator'>Go To Page</div>
        <form>
              <input
              className='inputPage'
                placeholder="number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChange}
              />
              <span  className="btnNumber"  onClick={this.handleGoToPage} >
                Submit
              </span>
            </form>
      
              
      </div>
      <br/>
      </div>
      
      </div>
      {/*  */}
      
      
      <br/><br/><br/><br/>
      
      </div> {/* main column end */}
      {/* side column */}
      
      {/* side column end */}
      {/* side column display at bottom in mobile start */}
      
      {/* side column mobile end */}
      
      
      </div>
      
      
      
          );
    
    //@@@@
    
  }
}


const mapStateToProps = ({ propresis,auth, form,statics, }) => {
  const { fetchingRR,limitRR, offsetRR, countRR, } = propresis;
  
  const {city, limitStaticRR, offsetStaticRR, countStaticRR,limitStaticDealerRR, offsetStaticDealerRR } = statics
  
  return {
    city,
    fetchingRR,
    limitRR,
    offsetRR,
    countRR,
    limitStaticRR,
    offsetStaticRR,
    countStaticRR,
    staticTop1: statics.staticTop1,
    staticTop2: statics.staticTop2,
    staticBottom1: statics.staticBottom1,
    staticBottom2: statics.staticBottom2,
    form: form.PropResiRentSearch,
    propresisRR: propresis.propresisRR,
    userA: auth.userA,
      limitStaticDealerRR,
      offsetStaticDealerRR,
      staticsDealer: statics.staticsDealer
  };
};

export default connect(
  mapStateToProps,
 {searchPropResiRents,openModal, fetchCompanysForPropListRR,}
)(PropResiRentSearchList);
