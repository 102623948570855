import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import {fetchAllProjectCs} from '../../actions/projectCAction';
import {fetchAllProjectRs} from '../../actions/projectRAction';
import { getUserForAuth,logoutUser,clearStorage,showSidebar,hideSidebar } from "../../actions/authAction";
import {fetchLocalitys} from "../../actions/appParameterAction";

import { openModal } from "../../actions/modalAction";
import LinkItem from "./LinkItem";
import ProfileActivityLink from './ProfileActivityLink'
import {  
    Button,
    Image,
    Icon,
  } from "semantic-ui-react";

class Navbar extends Component {
  state = {
    openM:false,
  };

  componentDidMount() {
    // if (!this.props.parameter ){
    //   this.props.fetchAppParameter()
    //     }
    if (!this.props.userA){
      this.props.getUserForAuth();
    }
    if(this.props.state.projectCs.projectCsForData.length <1){
      this.props.fetchAllProjectCs();
    }
    if(this.props.state.projectRs.projectRsForData.length <1){
      this.props.fetchAllProjectRs();
    }
    if(this.props.state.appParameters.localitys.length <1){
      this.props.fetchLocalitys();
    }

this.openPopup()
  }

  componentWillUnmount() {
    clearTimeout(this.openPopup);
  }
  
  onLogoutClick = () => {
  this.props.logoutUser();
  this.toggleMenu()
  };
  onSignInClick = () => {
    this.props.openModal('LoginModal');
    this.toggleMenu()
  };
    
  
  //*******************************************
  handleSidebarToggle=()=>{
    this.props.showSidebar();
    window.scroll(0,0)
  }

openPopup =()=>{
  if(localStorage.alertRead !=='Yes'){
  setTimeout(()=>this.props.openModal('AlertModal'),10000)
}
}
 // *****************************************
 toggleMenu = ()=> {
  this.setState({ openM: !this.state.openM });
  }
  render() {
    
    // const { isAuthenticated,user  } = this.props.auth;
    const userA=this.props.userA !== null && this.props.userA
//&&&&&&&&&&&&&&&&&&&&&&

  const renderBackButton =()=>{
    return this.props.visibleBB=== true ? (
      <button

    onClick={() => this.props.history.goBack()}

          className='back-btn'
        >
          <Icon name="arrow left" />

        </button>
    ) : (
      <div></div>
      )}
      //@@@@

      const renderRefreshButton =()=>{
        return  (
          <Button
          circular
          icon='refresh'
          onClick={() => window.location.reload(false)}
          style={{backgroundColor: 'dodgerblue', color:'white'}}
        />
        ) }
    
        // //@@@@@@@@@@@@@@@@

        const postPropertyRequirementLink=() => {

          return (
            
       
          
        <div className='card-attached'>
      <div className='dropdownFP-head' >
            POST PROPERTIES</div> 
            <LinkItem
          url={'/propresi/PropResiCreate'}
          title={'Residential'}
          />
          
          <LinkItem
          url={'/propcom/PropComCreate'}
          title={'Commercial'}
          />
       
          
          { ((userA.userGroup !=='Dealer') && (userA.userGroup !=='Builder')) && (
          <>
            <div className='dropdownFP-head' >
            POST REQUIREMENTS</div>

            <LinkItem
          url={'/reqresi/ReqResiCreate'}
          title={'Residential'}
          />
          
          <LinkItem
          url={'/reqcom/ReqComCreate'}
          title={'Commercial'}
          />
          </>)}
          
          
                    
          
          </div>


          )
        
        }
      

    //$$$$$$$$$$$$$$$$$$$$$$$$$$$

const renderShowHideSidebarButton =()=>{
  return this.props.visible=== true ? (
    <div>
    <button className='btn'  onClick={this.props.hideSidebar}

    >
      {/* <Icon name="close" /> */}X
      </button></div>
  ) : (
    <div>
    <button  className='btn' onClick={this.handleSidebarToggle }

    ><Icon name="sidebar" /></button></div>
    )}

    

    const authLinks = (
      <div>

      <nav id="navbar-top" className='border-bottom-nav border-top' style={{backgroundColor:'white',flexDirection:'row'}}>

         <div><Image size='mini' src='/logo.png' style={{ marginRight: '.5em' }} />
         </div>
         {renderBackButton()}
         {renderShowHideSidebarButton()}
{/*  */}

<div className="dropdown-container">
<button>{((userA.userGroup ==='Dealer') ||(userA.userGroup ==='Builder')) ? 'Post Property' : 'Post Requirement | Property'  }</button>
<ul className='margin-postProp'>

{postPropertyRequirementLink()} 

</ul>
</div>
<>
   
    
<div 
  className={this.state.openM 
  ? "menu-wrapper body menu-open" 
  : "menu-wrapper body"}
  >
  <div> 
    <ul >
      <ProfileActivityLink
      userA = {userA}
      auth = {this.props.auth}
      onLogoutClick = {this.onLogoutClick}
      onSignInClick = {this.onSignInClick}
      fn={this.toggleMenu}

      />
      </ul>
      </div>
      
  </div>
  </>
{/*  */}
      </nav>
      {/* NAV DESKTOP */}

      <nav id="navbar-desktop" className='border-bottom-nav border-top'  style={{backgroundColor:'white',flexDirection:'row'}}>

        <div style={{display:'flex'}}>
{/*  */}
<div style={{display:'flex',alignItems:'center'}}><Image size='mini' src='/logo.png' style={{ marginRight: '.5em' }} />
<div className='nodisplay' style={{fontSize:'1.2rem',fontWeight:'bold',marginRight:'5px'}}>
  <span style={{color:'dodgerblue'}}>FIND</span>
  
  <span style={{color:'blue'}}>PROP</span>
  <span style={{color:'orange'}}>.IN</span></div>
</div>
{/*  */}
        
        {renderBackButton()}
        {renderShowHideSidebarButton()}
        {renderRefreshButton()}
        </div>


 <ul>

        <li>
            <Link to={'/'} >

        <div style={{fontSize:'1.2rem',fontWeight:'bold',marginRight:'10px'}}><Icon name='home' />Home</div>
        </Link>

      </li>
      

      {/*  */}

          <li>

        <div className="dropdown-container">
        <button>{((userA.userGroup ==='Dealer') ||(userA.userGroup ==='Builder')) ? 'Post Property' : 'Post Requirement | Property'  }</button>
        <ul className='margin-postProp'>

        {postPropertyRequirementLink()} 

        </ul>
        </div>

          
        </li>
         

      <li>
      
        <div>
        <button
        // for button style only
        className={this.state.openM 
          ? "button-toggle button-toggle-open" 
          : "button-toggle"}
        onClick={this.toggleMenu}
        ><Icon name="user outline" />Profile|Activity</button>
        
        </div>
        <>
   
    
<div 
  className={this.state.openM 
  ? "menu-wrapper body menu-open" 
  : "menu-wrapper body"}
  >
  <div 
      
      > <ul >
      {/* {renderProfileActivityLink()}  */}
      <ProfileActivityLink
      userA = {userA}
      auth = {this.props.auth}
      onLogoutClick = {this.onLogoutClick}
      onSignInClick = {this.onSignInClick}
      fn={this.toggleMenu}

      />
      </ul>
      </div>
      
  </div>
  </>
      </li>



        </ul>
     </nav>

{/* bottom navbar */}
<nav id="navbar-bottom" className='border-top-nav border-bottom-3' style={{backgroundColor:'white'}}>

        <ul>
          
          <li><button className='btn'  style={{fontSize:'1.5rem',fontWeight:'bold'}}>
            <Link to={'/'} >
            <Icon name='home' />
        </Link>

       </button></li>
{/*  */}
       <li><button className='btn'  style={{fontSize:'1.5rem',fontWeight:'bold'}}  
       onClick={() => window.location.reload(false)}

><Icon name='refresh' />
</button>
</li>
{/*  */}
      <li><button className='btn'  style={{fontSize:'1.5rem',fontWeight:'bold'}}  
      onClick={this.toggleMenu}

      ><Icon name="user outline" />
      </button>
      </li>

{/*  */}



        </ul>
      </nav>
      

    </div>
    );



    return (
      <div>
        {
          authLinks
        }
      </div>
    );
  }
}
//@@@@@@@@@@@@@@@@@



const mapStateToProps = state => {
  //console.log("state@nav",state)
  return {
  state,
  auth: state.auth,
  visible: state.auth.visible,
  visibleBB: state.auth.visibleBB,
  userA: state.auth.userA,
};
}

export default connect(
  mapStateToProps,
  { getUserForAuth,logoutUser,clearStorage, openModal,
    showSidebar,hideSidebar ,fetchAllProjectRs,
    fetchAllProjectCs,fetchLocalitys }
)(withRouter(Navbar));
