import React, { Component } from "react";
import ShortlistProjectR from './ShortlistProjectR'
import ShortlistProjectC from '../projectC/ShortlistProjectC'
import {
  Button,
} from "semantic-ui-react";




class ShortlistProject extends Component {
  state = {
    projectR:true,
    projectC:false,
  };


  showShortlistedProjectR =()=> this.setState({ projectR: true,projectC: false, })
  showShortlistedProjectC =()=> this.setState({ projectR: false,projectC: true, })
  renderShortlistedProjectR = () => {
    if (this.state.projectR===true) {

      return <ShortlistProjectR />;
    }
  };
  renderShortlistedProjectC = () => {
    if (this.state.projectC===true) {

      return <ShortlistProjectC />;
    }
  };
  //******************



  render() {

    return (
      <div className='maindiv-820'>

{/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2' >
<span  > All Shortlisted Residential and Commercial Projects</span>
</div>
<Button.Group widths="2"  >

<Button
style={{marginRight:'1px'}}
// basic
//inverted
floated="right"
color="blue"
type="button"
onClick={this.showShortlistedProjectR}
>
 <b> Shortlisted Residential Projects</b>
</Button>
<Button
style={{marginLeft:'1px'}}
// basic
//inverted
floated="right"
color="blue"
type="button"
onClick={this.showShortlistedProjectC}
>
  Shortlisted Commercial Projects
</Button>

</Button.Group>
</div>
{/*  */}

{ this.renderShortlistedProjectR()}
{ this.renderShortlistedProjectC()}

</div>
    );
  }
}


export default ShortlistProject

// export default connect(
//   mapStateToProps,
//  {fetchShortlistComSale,openModal, shortlistAddRemoveCom}
// )(withRouter(ShortlistComSale));
