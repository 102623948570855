import {
  FETCH_PROPRESIS,
  FETCH_PROPRESI,
  SEARCH_PROPRESIS,
  FETCHING_PROPRESIS,
} from '../actions/types';

const INITIAL_STATE = { 
  city:'', 
  fetching:true,
  fetchingRS:true,
  fetchingRSBF:true,
  fetchingRR:true,
  propresisMy: [], //list of single user property posted
  propresisFIN: [],  //list of single financer's property
  propresisRS: [],
  propresisRSF: [],  //list of all financers property
  propresisRR: [],
  propresisRSBF: [],
  propresisRSBFF: [],
  propresisBookAd: [],
  propresi: null,

  offsetRS: 0,
  offsetRR: 0,
  offsetRSF: 0,
  offsetRSBF: 0,
  offsetRSBFF: 0, 

  offsetBookAd: 0,

  offsetRRSH: 0,
  offsetRSSH: 0,   //for shortlist

  limitRS: 10,
  limitRR: 10,
  limitRSF: 5,
  limitRSBF: 10,
  limitRSBFF: 5,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case FETCH_PROPRESIS: 
      return { ...state, ...action.payload };
    case FETCH_PROPRESI:
      return { ...state, propresi: action.payload };
    
    case SEARCH_PROPRESIS:
     
      return { ...state, ...action.payload };
    
    case FETCHING_PROPRESIS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
