import axios from "axios";
import { toastr } from "react-redux-toastr";
import { closeModal,openModal } from "./modalAction";

import {
  FETCH_REQCOMS,
  FETCH_REQCOM,
  SEARCH_REQCOMS,
  FETCHING_REQCOMS,
  MESSAGE,
} from "./types";


//search  ReqComs

export const searchReqComs = (
  values,
  limit,
  offset
) => async dispatch => {
  

  try {
    const res = await axios.post("/api/ReqComSearch", {
      values,
      limit,
      offset
    });

    
    dispatch({ type: SEARCH_REQCOMS, payload: res.data });
    dispatch({ type:FETCHING_REQCOMS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


//****************************
export const searchReqComsUnlocked = (
  values,
  limit,
  offsetULock
) => async dispatch => {
  

  try {
    const res = await axios.post("/api/ReqComSearchUnlocked", {
      values,
      limit,
      offsetULock
    });

   
    dispatch({ type: SEARCH_REQCOMS, payload: res.data });
    dispatch({ type:FETCHING_REQCOMS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};


//****************************
//update reqcom  FOR unlock contact
// export const updateReqComForUnlockContact = (id, balanceCreditReq) => async dispatch => {
//   try {
//     const res = await axios.put(`/api/ReqComEditForUnlockContact`, {id:id,balanceCreditReq:balanceCreditReq});
    
    
//     if (res.data.msg === 'errorAlready') { 
//       toastr.error('Already Unlocked');
//     }
//       if (res.data.msg === 'success') { 
//     toastr.success('Unlocked Successfully');
    
//   }

//       dispatch({ type: FETCH_USER, payload: null });
//   } catch (error) {
//     toastr.error("Oops", "Something went wrong.Please Try Again");
//   }
// };


//fetch single ReqCom
export const fetchReqCom = id => async dispatch => {
  try {
    const res = await axios.get(`/api/ReqComDetail/${id}`);
    
    dispatch({ type: FETCH_REQCOM, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//#######################################################
//fetch individual  MyReqCom
export const fetchMyReqComs = () => async dispatch => {
  try {
    const res = await axios.post(`/api/MyReqComs`);

    dispatch({ type: FETCH_REQCOMS, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};

// CREATE REQCOMMENT
export const createReqCom = (values, history) => async dispatch => {
  try {
    
    const res = await axios.post(`/api/ReqComCreate`, {
      values
    });

    
     dispatch({ type: MESSAGE, payload: res.data });
     history.push('/');
     dispatch(openModal('MessageModal'));
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//resetOffset

export const resetOffsetReqComs = () => async dispatch => {
  
  try {
        
    dispatch({ type: SEARCH_REQCOMS, payload: {offset:0} });
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};