	import React, { Component } from "react";

	import { connect } from "react-redux";
	import writtenNumber from "written-number";
	import format from "date-fns/format";
	//import Paginator from "../paginator/Paginator";
	import {fetchMyReqComs} from "../../actions/reqcomAction";

	import { getUserForAuth } from "../../actions/authAction";
	import { openModal } from "../../actions/modalAction";
	import {
	Icon,
	} from "semantic-ui-react";



	class ReqComSearchList extends Component {
	state = {

	open: false,
	result: "show the modal to capture a result"
	};
	componentDidMount() {

	if (this.props.auth.isAuthenticated) {

	this.props.fetchMyReqComs();
	}else{

		this.props.openModal('LoginModal') 
	}
	}

	capitalizeFirst=(input)=> {
	var words = input.split(' ');
	var CapitalizedWords = [];
	words.forEach(element => {
	CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
	});
	return CapitalizedWords.join(' ');
	}

	renderList=(reqcom)=> {
	const {
	name,
	phone1,
	phone2,
	email,
	whatsapp,
	requiredFor,
	propertyType,
	furnishedStatus,
	entry,
	floorNo,
	projectName,
	locality,
	budgetMin,
	budgetMax,
	areaCarpetMin,
	areaCarpetMax,
	washroomCommon,
	washroomPersonal,
	pantryPersonal ,
	liftsInTheTower,
	powerBackUp,
	parking,
	reserveParking,
	visitorParking,
	note,
	createdAt,
	_id
	} = reqcom;


	const renderpropertyType = () => {
	return propertyType.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} <span className='pipe'>|</span></b>
	</span>
	);
	});
	};



	const renderfurnishedStatus = () => {
	return furnishedStatus.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} <span className='pipe'>|</span></b>
	</span>
	);
	});
	};

	const renderentry = () => {
	return entry.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} <span className='pipe'>|</span></b>
	</span>
	);
	});
	};

	const renderfloorNo = () => {
	return floorNo.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} <span className='pipe'>|</span></b>
	</span>
	);
	});
	};

	const renderprojectName = () => {
	return projectName.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} <span className='pipe'>|</span></b>
	</span>
	);
	});
	};

	const renderlocality = () => {
	return locality.map(item => {
	return (
	<span key={item}>
	{" "}
	<b>{item} <span className='pipe'>|</span></b>
	</span>
	);
	});
	};




	//**************************
	// const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));

	return (
	<div key={_id}>
		{/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Requirement For Commercial Property For {requiredFor}</span>
	</div>

	</div>

	<div className='card-attached border-radius-bottom border-sb pxy-20 mb-010'  >

{/*  */} 
	


	<div style={{padding:'10px',}} >


	<div >
	{name && (
	<div className='row'>
	<div className='side'>
	<b>Name</b>
	</div>
	<div className='main'>
	<b>{name}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{phone1 && (
	<div className='row'>
	<div className='side'>
	<b>Phone1</b>
	</div>
	<div className='main'>
	<b>{phone1}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{phone2 && (
	<div className='row'>
	<div className='side'>
	<b>Phone2</b>
	</div>
	<div className='main'>
	<b>{phone2}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{email && (
	<div className='row'>
	<div className='side'>
	<b>Email</b>
	</div>
	<div className='main'>
	<b>{email}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{whatsapp && (
	<div className='row'>
	<div className='side'>
	<b>WhatsApp ID</b>
	</div>
	<div className='main'>
	<b>{whatsapp}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{requiredFor && (
	<div className='row'>
	<div className='side'>
	<b>Require For</b>
	</div>
	<div className='main'>
	<b>{requiredFor}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{propertyType != null &&
	propertyType.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Type Of Property</b>
	</div>
	<div className='main'>
	{renderpropertyType()}
	</div>

	</div>
	)}
	</div>

	<div >
	{furnishedStatus != null &&
	furnishedStatus.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Furnished Status</b>
	</div>
	<div className='main'>
	{renderfurnishedStatus()}
	</div>

	</div>
	)}
	</div>
	{/**/}
	<div >
	{washroomCommon && (
	<div className='row'>
	<div className='side'>
	<b>Common Washroom</b>
	</div>
	<div className='main'>
	<b>{washroomCommon}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{washroomPersonal && (
	<div className='row'>
	<div className='side'>
	<b>Personal Washroom</b>
	</div>
	<div className='main'>
	<b>{washroomPersonal}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{pantryPersonal && (
	<div className='row'>
	<div className='side'>
	<b>Personal Pantry</b>
	</div>
	<div className='main'>
	<b>{pantryPersonal}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{powerBackUp && (
	<div className='row'>
	<div className='side'>
	<b>Power BackUp</b>
	</div>
	<div className='main'>
	<b>{powerBackUp}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{liftsInTheTower && (
	<div className='row'>
	<div className='side'>
	<b>Lifts In The Tower</b>
	</div>
	<div className='main'>
	<b>{liftsInTheTower}</b>
	</div>

	</div>
	)}
	</div>



	<div >
	{parking && (
	<div className='row'>
	<div className='side'>
	<b>Car Parking</b>
	</div>
	<div className='main'>
	<b>{parking}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{reserveParking && (
	<div className='row'>
	<div className='side'>
	<b>Reserve Car Parking</b>
	</div>
	<div className='main'>
	<b>{reserveParking}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{visitorParking && (
	<div className='row'>
	<div className='side'>
	<b>Visitor Parking</b>
	</div>
	<div className='main'>
	<b>{visitorParking}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{floorNo != null &&
	floorNo.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Floor No</b>
	</div>
	<div className='main'>
	{renderfloorNo()}
	</div>

	</div>
	)}
	</div>

	<div >
	{entry != null &&
	entry.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Entry</b>
	</div>
	<div className='main'>
	{renderentry()}
	</div>

	</div>
	)}
	</div>

	{/**/}


	<div >
	{budgetMin > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Minimum Budget</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {budgetMin}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(budgetMin, {
	lang: "enIndian"
	}))}
	){" "}
	</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{budgetMax > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Maximum Budget</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {budgetMax}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(budgetMax, {
	lang: "enIndian"
	}))}
	){" "}
	</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{areaCarpetMin > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Minimum Carpet Area</b>
	</div>
	<div className='main'>

	<b> {areaCarpetMin}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMin, {
	lang: "enIndian"
	}))}
	){" "} sq ft
	</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{areaCarpetMax > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Maximum Carpet Area</b>
	</div>
	<div className='main'>

	<b> {areaCarpetMax}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMax, {
	lang: "enIndian"
	}))}
	){" "} sq ft
	</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{projectName  != null &&
	projectName.length > 0 && (
	<div className='rowERP-projName'>
	<div className='side'>
	<b>Complex/Project Preferred</b>
	</div>
	<div className='main display-linebreak'>
	{renderprojectName ()}
	</div>

	</div>
	)}
	</div>

	<div >
	{locality != null &&
	locality.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Locality</b>
	</div>
	<div className='main'>
	{renderlocality()}
	</div>
	</div>
	)}
	</div>


	<div >
	{createdAt && (
	<div className='row'>
	<div className='side'>
	<b>Created On</b>
	</div>
	<div className='main'>

	<b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{note && (
	<div className='rowERP-projName'>
	<div className='side'>
	<b>Note/Comments</b>
	</div>
	<div className='main display-linebreak'>
	<b>{note}</b>
	</div>

	</div>
	)}
	</div>


	{/**/}

	{/**/}
	</div >{/* end  3rd div */}

	</div>
	</div>

	);
	}

	renderLoading() {
	if (this.props.fetching===true) {
	return (
	<div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

	</div>
	);
	}
	}
	renderNoRequirement() {
	if (this.props.reqcoms.length <1) {
		return (
		<div className='maindiv-820 card-attached border-radius-top border-db'style={{textAlign:'center',paddingBottom:'100px',paddingTop:'100px'}}>
			<span style={{fontSize: "18px",color: "teal"}}>
				<b>NO REQUIREMENT AVAILABLE </b>
				</span>

		</div>
		);
		}
	}

	render() {
	return (
	
	<div className='maindiv-820'>
	

	<div>

	
{/**/}
<div className='cashbook-H box-shadow'style={{justifyContent: 'center'}}>


<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
<span>My Requirement For Commercial Property</span>
</div>



</div>
{/**/}
	{this.renderNoRequirement()}
	{this.props.reqcoms.map(this.renderList)}


	<br/><br/><br/><br/>
	
	</div>



	</div>



	);
	}
	}


	const mapStateToProps = ({ reqcoms, selection, form,auth }) => {
	const { fetching,limit, offset, count } = reqcoms;

	return {
	auth,
	fetching,
	limit,
	offset,
	count,
	form: form.ReqComSearch,
	selection,
	reqcoms: reqcoms.reqcoms,
	userA: auth.userA,
	};
	};
	export default connect(
	mapStateToProps,
	{getUserForAuth,fetchMyReqComs,openModal}
	)(ReqComSearchList);
