

//COMMON action Types
export const TRANSPORT = 'TRANSPORT';
export const MESSAGE = 'MESSAGE';

//builders action Types
export const SEARCH_BUILDERS = 'SEARCH_BUILDERS';
export const CREATE_BUILDERS = 'CREATE_BUILDERS';
export const FETCH_BUILDERS = 'FETCH_BUILDERS';
export const FETCH_BUILDER = 'FETCH_BUILDER';
export const DELETE_BUILDER = 'DELETE_BUILDER';
export const UPDATE_BUILDER = 'UPDATE_BUILDER';
export const SELECT_BUILDER = 'SELECT_BUILDER';
export const DESELECT_BUILDER = 'DESELECT_BUILDER';
export const FETCHING_BUILDERS = 'FETCHING_BUILDERS';

//##############################################
//companys action Types
export const SEARCH_COMPANYS = 'SEARCH_COMPANYS';
export const CREATE_COMPANYS = 'CREATE_COMPANYS';
export const FETCH_COMPANYS = 'FETCH_COMPANYS';
export const FETCH_COMPANYSBLOCKED = 'FETCH_COMPANYSBLOCKED';
export const FETCH_COMPANYSUNBLOCKED = 'FETCH_COMPANYSUNBLOCKED';
export const FETCH_COMPANY = 'FETCH_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const PROPREQ_COUNT = 'PROPREQ_COUNT';

export const SELECT_COMPANY = 'SELECT_COMPANY';
export const DESELECT_COMPANY = 'DESELECT_COMPANY';
export const FETCHING_COMPANYS = 'FETCHING_COMPANYS';
//##############################################
export const RESET_SELECTION = 'RESET_SELECTION';
//##############################################

//Residential property  action Types

export const SEARCH_PROPRESIS = 'SEARCH_PROPRESIS';
export const SELECT_PROPRESI = 'SELECT_PROPRESI';
export const DESELECT_PROPRESI = 'DESELECT_PROPRESI';
export const CREATE_PROPRESIS = 'CREATE_PROPRESIS';
export const FETCH_PROPRESIS = 'FETCH_PROPRESIS';
export const FETCH_PROPRESI = 'FETCH_PROPRESI';
export const DELETE_PROPRESI = 'DELETE_PROPRESI';
export const UPDATE_PROPRESI = 'UPDATE_PROPRESI';
export const FETCHING_PROPRESIS = 'FETCHING_PROPRESIS';
//Residential property RENT action types
export const SEARCH_PROPRESIRENTS = 'SEARCH_PROPRESIRENTS';
export const SELECT_PROPRESIRENT = 'SELECT_PROPRESIRENT';
export const DESELECT_PROPRESIRENT = 'DESELECT_PROPRESIRENT';
export const CREATE_PROPRESIRENTS = 'CREATE_PROPRESIRENTS';
export const FETCH_PROPRESIRENTS = 'FETCH_PROPRESIRENTS';
export const FETCH_PROPRESIRENT = 'FETCH_PROPRESIRENT';
export const DELETE_PROPRESIRENT = 'DELETE_PROPRESIRENT';
export const UPDATE_PROPRESIRENT = 'UPDATE_PROPRESIRENT';
export const FETCHING_PROPRESIRENTS = 'FETCHING_PROPRESIRENTS';
//Commercial property  action types
export const SEARCH_PROPCOMS = 'SEARCH_PROPCOMS';
export const SELECT_PROPCOM = 'SELECT_PROPCOM';
export const DESELECT_PROPCOM = 'DESELECT_PROPCOM';
export const CREATE_PROPCOMS = 'CREATE_PROPCOMS';
export const FETCH_PROPCOMS = 'FETCH_PROPCOMS';
export const FETCH_PROPCOM = 'FETCH_PROPCOM';
export const DELETE_PROPCOM = 'DELETE_PROPCOM';
export const UPDATE_PROPCOM = 'UPDATE_PROPCOM';
export const FETCHING_PROPCOMS = 'FETCHING_PROPCOMS';
//Commercial property RENT action types
export const SEARCH_PROPCOMRENTS = 'SEARCH_PROPCOMRENTS';
export const SELECT_PROPCOMRENT = 'SELECT_PROPCOMRENT';
export const DESELECT_PROPCOMRENT = 'DESELECT_PROPCOMRENT';
export const CREATE_PROPCOMRENTS = 'CREATE_PROPCOMRENTS';
export const FETCH_PROPCOMRENTS = 'FETCH_PROPCOMRENTS';
export const FETCH_PROPCOMRENT = 'FETCH_PROPCOMRENT';
export const DELETE_PROPCOMRENT = 'DELETE_PROPCOMRENT';
export const UPDATE_PROPCOMRENT = 'UPDATE_PROPCOMRENT';
export const FETCHING_PROPCOMRENTS = 'FETCHING_PROPCOMRENTS';

//require action Types
export const SEARCH_REQRESIS = 'SEARCH_REQRESIS';
export const SELECT_REQRESI = 'SELECT_REQRESI';
export const DESELECT_REQRESI = 'DESELECT_REQRESI';
export const CREATE_REQRESIS = 'CREATE_REQRESIS';
export const FETCH_REQRESIS = 'FETCH_REQRESIS';
export const FETCH_REQRESI = 'FETCH_REQRESI';
export const DELETE_REQRESI = 'DELETE_REQRESI';
export const UPDATE_REQRESI = 'UPDATE_REQRESI';
export const FETCHING_REQRESIS = 'FETCHING_REQRESIS';
//require action Types rent
export const SEARCH_REQRESIRENTS = 'SEARCH_REQRESIRENTS';
export const SELECT_REQRESIRENT = 'SELECT_REQRESIRENT';
export const DESELECT_REQRESIRENT = 'DESELECT_REQRESIRENT';
export const CREATE_REQRESIRENTS = 'CREATE_REQRESIRENTS';
export const FETCH_REQRESIRENTS = 'FETCH_REQRESIRENTS';
export const FETCH_REQRESIRENT = 'FETCH_REQRESIRENT';
export const DELETE_REQRESIRENT = 'DELETE_REQRESIRENT';
export const UPDATE_REQRESIRENT = 'UPDATE_REQRESIRENT';
export const FETCHING_REQRESIRENTS = 'FETCHING_REQRESIRENTS';
//require COM action Types
export const SEARCH_REQCOMS = 'SEARCH_REQCOMS';
export const SELECT_REQCOM = 'SELECT_REQCOM';
export const DESELECT_REQCOM = 'DESELECT_REQCOM';
export const CREATE_REQCOMS = 'CREATE_REQCOMS';
export const FETCH_REQCOMS = 'FETCH_REQCOMS';
export const FETCH_REQCOM = 'FETCH_REQCOM';
export const DELETE_REQCOM = 'DELETE_REQCOM';
export const UPDATE_REQCOM = 'UPDATE_REQCOM';
export const FETCHING_REQCOMS = 'FETCHING_REQCOMS';
//require COM action Types rent
export const SEARCH_REQCOMRENTS = 'SEARCH_REQCOMRENTS';
export const SELECT_REQCOMRENT = 'SELECT_REQCOMRENT';
export const DESELECT_REQCOMRENT = 'DESELECT_REQCOMRENT';
export const CREATE_REQCOMRENTS = 'CREATE_REQCOMRENTS';
export const FETCH_REQCOMRENTS = 'FETCH_REQCOMRENTS';
export const FETCH_REQCOMRENT = 'FETCH_REQCOMRENT';
export const DELETE_REQCOMRENT = 'DELETE_REQCOMRENT';
export const UPDATE_REQCOMRENT = 'UPDATE_REQCOMRENT';
export const FETCHING_REQCOMRENTS = 'FETCHING_REQCOMRENTS';
//dealerSuggested action Types
export const SEARCH_DEALERSUGGESTEDS = 'SEARCH_DEALERSUGGESTEDS';
export const SELECT_DEALERSUGGESTED = 'SELECT_DEALERSUGGESTED';
export const DESELECT_DEALERSUGGESTED = 'DESELECT_DEALERSUGGESTED';
export const CREATE_DEALERSUGGESTEDS = 'CREATE_DEALERSUGGESTEDS';
export const FETCH_DEALERSUGGESTEDS = 'FETCH_DEALERSUGGESTEDS';
export const FETCH_DEALERSUGGESTED = 'FETCH_DEALERSUGGESTED';
export const DELETE_DEALERSUGGESTED = 'DELETE_DEALERSUGGESTED';
export const UPDATE_DEALERSUGGESTED = 'UPDATE_DEALERSUGGESTED';
export const FETCHING_DEALERSUGGESTEDS = 'FETCHING_DEALERSUGGESTEDS';

//Residential Project action types
export const SEARCH_PROJECTRS = 'SEARCH_PROJECTRS';
export const SELECT_PROJECTR = 'SELECT_PROJECTR';
export const DESELECT_PROJECTR = 'DESELECT_PROJECTR';
export const CREATE_PROJECTRS = 'CREATE_PROJECTRS';
export const FETCH_PROJECTRS = 'FETCH_PROJECTRS';
export const FETCH_PROJECTR = 'FETCH_PROJECTR';
export const DELETE_PROJECTR = 'DELETE_PROJECTR';
export const UPDATE_PROJECTR = 'UPDATE_PROJECTR';
export const FETCHING_PROJECTRS = 'FETCHING_PROJECTRS';
//******************
//Commercial Project action types
export const SEARCH_PROJECTCS = 'SEARCH_PROJECTCS';
export const SELECT_PROJECTC = 'SELECT_PROJECTC';
export const DESELECT_PROJECTC = 'DESELECT_PROJECTC';
export const CREATE_PROJECTCS = 'CREATE_PROJECTCS';
export const FETCH_PROJECTCS = 'FETCH_PROJECTCS';
export const FETCH_PROJECTC = 'FETCH_PROJECTC';
export const DELETE_PROJECTC = 'DELETE_PROJECTC';
export const UPDATE_PROJECTC = 'UPDATE_PROJECTC';
export const FETCHING_PROJECTCS = 'FETCHING_PROJECTCS';
//image action types
export const SEARCH_IMAGES = 'SEARCH_IMAGES';
export const SELECT_IMAGE = 'SELECT_IMAGE';
export const DESELECT_IMAGE = 'DESELECT_IMAGE';
export const CREATE_IMAGES = 'CREATE_IMAGES';
export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_IMAGE = 'FETCH_IMAGE';
export const DELETE_IMAGE = 'DELETE_IMAGE';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const FETCHING_IMAGES = 'FETCHING_IMAGES';
//video action types
export const SEARCH_VIDEOS = 'SEARCH_VIDEOS';
export const SELECT_VIDEO = 'SELECT_VIDEO';
export const DESELECT_VIDEO = 'DESELECT_VIDEO';
export const CREATE_VIDEOS = 'CREATE_VIDEOS';
export const FETCH_VIDEOS = 'FETCH_VIDEOS';
export const FETCH_VIDEO = 'FETCH_VIDEO';
export const DELETE_VIDEO = 'DELETE_VIDEO';
export const UPDATE_VIDEO = 'UPDATE_VIDEO';
export const FETCHING_VIDEOS = 'FETCHING_VIDEOS';

//ContactReq action types
export const SEARCH_CONTACTREQS = 'SEARCH_CONTACTREQS';
export const SELECT_CONTACTREQ = 'SELECT_CONTACTREQ';
export const DESELECT_CONTACTREQ = 'DESELECT_CONTACTREQ';
export const CREATE_CONTACTREQS = 'CREATE_CONTACTREQS';
export const FETCH_CONTACTREQS = 'FETCH_CONTACTREQS';
export const FETCH_CONTACTREQ = 'FETCH_CONTACTREQ';
export const DELETE_CONTACTREQ = 'DELETE_CONTACTREQ';
export const UPDATE_CONTACTREQ = 'UPDATE_CONTACTREQ';
export const FETCHING_CONTACTREQS = 'FETCHING_CONTACTREQS';


//ContactUs action types
export const SEARCH_CONTACTUSS = 'SEARCH_CONTACTUSS';
export const SELECT_CONTACTUS = 'SELECT_CONTACTUS';
export const DESELECT_CONTACTUS = 'DESELECT_CONTACTUS';
export const CREATE_CONTACTUSS = 'CREATE_CONTACTUSS';
export const FETCH_CONTACTUSS = 'FETCH_CONTACTUSS';
export const FETCH_CONTACTUS = 'FETCH_CONTACTUS';
export const DELETE_CONTACTUS = 'DELETE_CONTACTUS';
export const UPDATE_CONTACTUS = 'UPDATE_CONTACTUS';
export const FETCHING_CONTACTUSS = 'FETCHING_CONTACTUSS';

//CREDIT ADD action types
export const SEARCH_CREDITADDS = 'SEARCH_CREDITADDS';
export const SELECT_CREDITADD = 'SELECT_CREDITADD';
export const DESELECT_CREDITADD = 'DESELECT_CREDITADD';
export const CREATE_CREDITADDS = 'CREATE_CREDITADDS';
export const FETCH_CREDITADDS = 'FETCH_CREDITADDS';
export const FETCH_CREDITADD = 'FETCH_CREDITADD';
export const DELETE_CREDITADD = 'DELETE_CREDITADD';
export const UPDATE_CREDITADD = 'UPDATE_CREDITADD';
export const FETCHING_CREDITADDS = 'FETCHING_CREDITADDS';
//******************
//CONTACT DETAIL REQUIRE action types
export const SEARCH_CONTACTDETAILREQUIRES = 'SEARCH_CONTACTDETAILREQUIRES';
export const SELECT_CONTACTDETAILREQUIRE = 'SELECT_CONTACTDETAILREQUIRE';
export const DESELECT_CONTACTDETAILREQUIRE = 'DESELECT_CONTACTDETAILREQUIRE';
export const CREATE_CONTACTDETAILREQUIRES = 'CREATE_CONTACTDETAILREQUIRES';
export const FETCH_CONTACTDETAILREQUIRES = 'FETCH_CONTACTDETAILREQUIRES';
export const FETCH_CONTACTDETAILREQUIRE = 'FETCH_CONTACTDETAILREQUIRE';
export const DELETE_CONTACTDETAILREQUIRE = 'DELETE_CONTACTDETAILREQUIRE';
export const UPDATE_CONTACTDETAILREQUIRE = 'UPDATE_CONTACTDETAILREQUIRE';
export const FETCHING_CONTACTDETAILREQUIRES = 'FETCHING_CONTACTDETAILREQUIRES';
//
export const CREATE_ERROR = 'CREATE_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

//###################################
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_ERRORS = 'GET_ERRORS';
//##################################,
// user action types
export const SEARCH_USERS = 'SEARCH_USERS';
export const CREATE_USERS = 'CREATE_USERS';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERDETAIL = 'FETCH_USERDETAIL';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
// Static action types
export const SEARCH_STATICS = 'SEARCH_STATICS';
export const SELECT_STATIC = 'SELECT_STATIC';
export const DESELECT_STATIC = 'DESELECT_STATIC';
export const CREATE_STATICS = 'CREATE_STATICS';
export const FETCH_STATICS = 'FETCH_STATICS';
export const FETCH_STATIC = 'FETCH_STATIC';
export const DELETE_STATIC = 'DELETE_STATIC';
export const UPDATE_STATIC = 'UPDATE_STATIC';
export const FETCHING_STATICS = 'FETCHING_STATICS';
// modal action types
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const FETCHING = 'FETCHING';
// popup action types
export const POPUP_OPEN = 'POPUP_OPEN'; 
export const POPUP_CLOSE = 'POPUP_CLOSE';
// sidebar action types
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
// Backbutton action types
export const SHOW_BACKBUTTON = 'SHOW_BACKBUTTON';
export const HIDE_BACKBUTTON = 'HIDE_BACKBUTTON';

//NOTIFICATION action Types
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATION = 'FETCH_NOTIFICATION';
export const FETCHING_NOTIFICATIONS = 'FETCHING_NOTIFICATIONS';


//APP PARAMETER action Types
export const FETCH_LOCALITYS = 'FETCH_LOCALITYS';
