import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, Route,  } from "react-router-dom";
import { showBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";
import {fetchAllProjectRs} from '../../actions/projectRAction';
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import PropResiCreate from "./PropResiCreate";

import PropResiDetail from "./PropResiDetail";

import PropResiSaleSearchList from "./PropResiSaleSearchList";
import MyPropResiList from "./MyPropResiList";
import BuilderFloorSaleSearchList from "./BuilderFloorSaleSearchList";
import PropResiRentSearchList from "./PropResiRentSearchList";
import ShortlistResi from "./ShortlistResi";

import AddDealerForProp from "./AddDealerForProp";
import Layout from "../layout/Layout";

class PropResiDashboard extends Component {
  componentDidMount() {
    if(this.props.auth.isAuthenticated){
      this.props.getUserForAuth();
    }
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }
    if(this.props.state.projectRs.projectRsForData.length <1){
      this.props.fetchAllProjectRs();
      
    }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };
  render() {

  return (
<div className="mt-10">
<div className='maindiv mt-10'>


<Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
      {/* <Redirect exact from="/propresi" to="/propresi/PropResiSaleList" /> */}
      
      <Route exact path="/propresi/addDealer/:id" component={AddDealerForProp} />

      <Route
        exact
        path="/propresi/PropResiCreate"
        component={PropResiCreate}
      />
      <Route
        exact
        path="/propresi/PropResiDetail/:id"
        component={PropResiDetail}
      />
      


      <Route
        exact
        path="/propresi/BuilderFloorSaleSearchList"
        component={BuilderFloorSaleSearchList}
      />
      <Route
        exact
        path="/propresi/MyPropResiList"
        component={MyPropResiList}
      />
      <Route
        exact
        path="/propresi/PropResiSaleSearchList"
        component={PropResiSaleSearchList}
      />
      <Route
        exact
        path="/propresi/PropResiRentSearchList"
        component={PropResiRentSearchList}
      />
      <Route
        exact
        path="/propresi/ShortlistResi"
        component={ShortlistResi}
      />
    </Switch>
    </Layout >
    </div>

<div >
    <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
</div>

</div>
  );
}
}
const mapStateToProps = state => ({
auth: state.auth,
state
});


export default connect(
   mapStateToProps,
  
  { showBackButton,getUserForAuth, logoutUser,fetchAllProjectRs  }
)(PropResiDashboard);
