import React, { Component } from "react";
import axios from "axios";
import { toastr } from "react-redux-toastr";
// import {
//   Button,
// } from "semantic-ui-react";

class BookRenewFinancerAd extends Component {
  state = {  
    creditRequireForFinancerAd:0 ,
    expiredOn:'',
    creditExpiredOn:'',
    balanceCreditProp:0,
    // companyId:'',

};

  componentDidMount = async ()  =>{
   
    const propOrProjId = this.props.valueFinAd.propOrProjId;
    
    if(this.props.valueFinAd.projectType ==='Residential'){
      const res = await axios.get(`/api/PropertyRCreditDetail/${propOrProjId}`);
      
      this.setState({
				creditRequireForFinancerAd :res.data.creditRequireForFinancerAd ,
				expiredOn:res.data.expiredOn,
        creditExpiredOn :res.data.creditExpiredOn,
        balanceCreditProp :res.data.balanceCreditProp,
      });
    }
    if(this.props.valueFinAd.projectType ==='Commercial'){
      const res = await axios.get(`/api/PropertyCCreditDetail/${propOrProjId}`);
      
      this.setState({
        creditRequireForFinancerAd :res.data.creditRequireForFinancerAd ,
				expiredOn:res.data.expiredOn,
        creditExpiredOn :res.data.creditExpiredOn,
        balanceCreditProp :res.data.balanceCreditProp,
      }); 
    } 

    this.closeModal()
  }

    //*******************************************
    closeModal =()=>{
      setTimeout(this.props.handleModalClose,15000)
    }   


componentWillUnmount() {
  clearTimeout(this.closeModal);
}
 // *****************************************
  handleBookRenewFinancerAd = async (values) => {
    

    if(this.state.balanceCreditProp >= this.state.creditRequireForFinancerAd){
      
      try {
      
        const res = await axios.post(`/api/FinancerAdBookOrRenew`, values);
        if (res.data.msg ==='errorCredit'){
          toastr.error("You Do Not Have Enough Credit To Create / Renew Ad");
        }
        if (res.data.msg ==='success'){
          this.props.handleRefresh()
          this.props.handleModalClose()
          if(res.data.action ==='Book'){
            toastr.success('Ad Created Successfully');
          }
          if(res.data.action ==='Renew'){
            toastr.success('Ad Renewed Successfully');
          }
        
        }
        
      } catch (error) {
    
        toastr.error("Oops", "Something went wrong.Please Try Again CreateAd");
      }
    }else{
      toastr.error("You Do Not Have Enough Credit To Create / Renew Ad");
  
    }
    
  };
  
  //@@@@@@@@@@@@
  

  render() {
    const valCreate = {...this.props.valueFinAd, expiredOn:this.state.expiredOn, creditRequireForFinancerAd : this.state.creditRequireForFinancerAd,action:'Book'}
    const valExtend = {...this.props.valueFinAd, expiredOn:this.state.expiredOn, creditRequireForFinancerAd : this.state.creditRequireForFinancerAd,action:'Renew'}


//*********** */
const renderFieldAndButton = ()=>{
  if(  Date.parse(this.state.expiredOn) < Date.now()){
  //if(action === 'Book'){
    return(
        <>
        <div >
          {this.state.balanceCreditProp >=0 && (
          <div className='row'>
            <div className='side flex-70'>
            <b>Total Credit Available</b>
            </div>
            <div className='main flex-30'>
            <b>{this.state.balanceCreditProp}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
  
          <div >
          {this.state.creditRequireForFinancerAd >0 && (
          <div className='row'>
            <div className='side flex-70'>
            <b>Credit Required To Create Ad</b>
            </div>
            <div className='main flex-30'>
            <b>{this.state.creditRequireForFinancerAd}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>

          
          <div className='row'>
            <div className='side flex-70'>
            <b>Ad Will be Created For Days</b>
            </div>
            <div className='main flex-30'>
            <b>30 Days{' '}</b>{' '}
            </div>
  
          </div>
          
          <br/>
          <div className = 'disflex-paginator'>
          <span
            className = 'btnNumber ml-02'
            onClick={()=> this.handleBookRenewFinancerAd (valCreate)}
          >
            Create Ad
          </span>
          
          <span
            className = 'btnNumber ml-02 bgColor-dodgerblue'
            onClick={this.props.handleModalClose}
          >
            cancel
          </span>
        </div>
        </>
    )
  }
  //if(action === 'Renew'){
    if(Date.now() < Date.parse(this.state.expiredOn)){
    return(
        <>
        <div >
          {this.state.balanceCreditProp >0 && (
          <div className='row'>
            <div className='side flex-70'>
            <b>Total Credit Available</b>
            </div>
            <div className='main flex-30'>
            <b>{this.state.balanceCreditProp}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
  
          <div >
          {this.state.creditRequireForFinancerAd >0 && (
          <div className='row'>
            <div className='side flex-70'>
            <b>Credit Required To Extend Validity</b>
            </div>
            <div className='main flex-30'>
            <b>{this.state.creditRequireForFinancerAd}{' '}</b>{' '}
            </div>
  
          </div>
          )}
          </div>
          
          
          <br/>
          <div className = 'disflex-paginator'>
          <span
            className = 'btnNumber ml-02'
            onClick={()=> this.handleBookRenewFinancerAd (valExtend)}
          >
            Extend Validity
          </span>
          
          <span
            className = 'btnNumber ml-02 bgColor-dodgerblue'
            onClick={this.props.handleModalClose}
          >
            cancel
          </span>
          </div>
        </>
    )
  }
  
  }
    //*************** */
 const renderHeading=()=>{
   //if(this.props.valueFinAd.action === 'Renew'){
    if(Date.now() < Date.parse(this.state.expiredOn)){
     return(
      <div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
      <div className='heading-2'>
      <span  > Extend Validity For 30 Days</span>
      </div>
    
      </div>
     )
   }
   //if(this.props.valueFinAd.action === 'Book'){
    if(  Date.parse(this.state.expiredOn) < Date.now()){
    return(
     <div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
 
     <div className='heading-2'>
     <span  > Book Financer Ad</span>
     </div>
   
     </div>
    )
  }

 }

    //*************** */
    return (
<div className = 'maindiv-768' >
        {/*  */}
{renderHeading()}

<div className='card-attached border-radius-bottom border-sb pxy-10 mb-10'  >

{/*  */}
      
      {renderFieldAndButton()}
        {/*  */}
        </div>
      </div>

//@@@@@@@@@@@@@

    );
  }
}

export default BookRenewFinancerAd
