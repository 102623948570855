import {
  FETCH_CREDITADDS,
  FETCH_CREDITADD,
  SEARCH_CREDITADDS,
  FETCHING_CREDITADDS,
} from '../actions/types';

const INITIAL_STATE = {
  fetching:true,
  creditAdds: [],
  creditAdd: null,
  offset: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CREDITADDS:
      return { ...state, ...action.payload };
    case FETCH_CREDITADD:
      return { ...state, creditAdd: action.payload };
        case SEARCH_CREDITADDS:
     
      return { ...state, ...action.payload };
      case FETCHING_CREDITADDS:
        return { ...state, fetching: action.payload };

    default:
      return state;
  }
};
