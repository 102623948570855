import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { connect } from "react-redux";

import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import {fetchProjectComToBookAd,} from "../../actions/projectCAction";

import { openModal } from "../../actions/modalAction";
import {
  Icon,
  Button,
} from "semantic-ui-react";


class BookAdPropCom extends Component {
  state = {
    pageO:'',
    };
  componentDidMount() {
    const {offset, limit} = this.props;
      
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
  
  
  if (this.props.auth.isAuthenticated) { 
     
    this.props.fetchProjectComToBookAd(values, limit,offset);
  }else{
    
    this.props.openModal('LoginModal')
  }
    
  }
  

  // this reset search form when user go to datail page and 
  //when he return back to list he has to re enter search 
  //criteria again
  // componentWillUnmount(){
  //   this.props.resetForm('SearchBookAdProjectCom')
  // } 
    
   
    //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    handleSearchBookAdProjectCom = () => {
    this.props.openModal("SearchBookAdProjectComModal");
    };

    
    //^^^^^^^^^^^^^^^^^^^^^^
    renderList=(item)=> {
    const {
    projectType,
    projectName,
    locality,
    _id
    } = item;

    
    //**************************
    const renderDetailButton=() => {   
    
    return (<Button
    inverted
    //floated="right"
    color="blue"
    type="button"
    as={Link}
    to={`/projectC/ProjectCDetail/${_id}`}
    >
    Detail
    </Button>)
    

    }

    
    return (
    <div key={_id}>
    <div className='card pxy-10 border3 mt-0 '  >
    {/*requester detqils start*/}
    {/*  */}
	<div className='border-bottom mt-05 mb-05'>
	<div  className=" color-CornflowerBlue font-18B mb-10"  >
	<span >
	{projectType} 
	</span>
	</div>
	</div>
	{/*  */}
  
    <div style={{fontSize:'1rem'}}>
    


    <div >
    {projectName && (
    <div className='row'>
    <div className='side'>
    <b>Location</b>
    </div>
    <div className='main'>
    <b>

    <div>
    {' '}
    
        
    {projectName && (

    <span >
    {' '}{projectName}
    </span>)

    }{' '}
    {locality && (

    <span >
    {locality} Dwarka, New Delhi
    </span>)

    }{' '}
    {renderDetailButton()}
    </div>

    </b>
    </div>

    </div>
    )}
    </div>

    </div>


    </div>
    </div>


    );
    }

  back = () => {
    const {
      offset,
      limit
      
    } = this.props;
    //const values = this.props.form.ProjectSearch.values;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
      return;
    }

    this.props.fetchProjectComToBookAd(values, limit, (offset - limit));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offset,
      limit,
      count
    
    } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
      return;
    }

    this.props.fetchProjectComToBookAd(values, limit, (offset + limit));
    window.scroll(0,0)
  };

  handleGoToPage = () => {
    const {
    limit,
    count
    } = this.props;

    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    // use Math.abs for strict equality ===  to work and safe guard against negative value from user
    if (Math.abs(this.state.pageO) === 0) {
    return; 
    }

    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)

    this.props.fetchProjectComToBookAd(values, limit, (page * limit));
    window.scroll(0,0)

    }

  renderPaginator() {
    if (this.props.projectCsBookAd.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }


  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }

//^^^^^^^^^^^^^^^^^^^^^^^^^
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//^^^^^^^^^^^^^^^^^^^^
  render() {

    return (

  <div className='maindiv-820'>
  <div >
  

  <div>
    {/**/}
<div className='cashbook-H box-shadow mb-05 '>

<div
className="disflex-center flex-15 pxy-05 ml-05"
onClick={this.handleSearchBookAdProjectCom}>
      <span className='icon-button2 border1' > <Icon name='search' /></span >
    </div>



    <div  className="disflex-center flex-85" >
<div  className="disflex-center pxy-05 font-20B"   >
{this.props.count}{' '}Locations At Commercial Project To Book Ad
</div>
</div>


</div>
{/**/}
  
  
      
  {this.renderLoading()}
  {this.props.projectCsBookAd.map(this.renderList)}

  {/*  */}
<div className='disflex-paginator card-paginator border media-600'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
{/*  */}
  <br/><br/>
  
  </div>
  


  </div>
  </div>

    );
  }
}


const mapStateToProps = ({ projectCs,auth,form  }) => {
  const { fetching,limit, offsetBookAd, countBookAd } = projectCs;

  return {
    auth,
    fetching,
    limit:limit,
    offset:offsetBookAd,
    count:countBookAd,
    form: form.SearchBookAdProjectCom,
    projectCsBookAd: projectCs.projectCsBookAd,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
  {fetchProjectComToBookAd,openModal,}
)(BookAdPropCom);
