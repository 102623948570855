import React, { Component } from 'react';
import { Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';

//import Message from '../auth/Message';
import { closePopup } from '../../actions/popupAction';

const actions = { closePopup };


class MessagePopup extends Component {
  render() {
    //const message = 'Phone1 Number Copied'
    return (
      <Popup size="tiny" open={true} 
      onClose={this.props.closePopup}
      content={this.props.message}
      style = {{
        borderRadius: 15,
        fontSize:"16px",
        fontWeight:"bold",
        color:this.props.color,
        //opacity: 0.7,
        padding: '10px',
      }}
      offset={[100, 50]}
      position='bottom center'
      size = 'tiny'
      
     />
       
    );
  }
}
const mapState = state => ({
  message: state.popups.popupProps.message
});
export default connect(
  mapState,
  actions
)(MessagePopup);
