//import React, { Component } from 'react';
import React  from 'react';
import { Modal, } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closePopup } from '../../actions/popupAction';

const actions = { closePopup };


 const CopyPhoneFixedPopup = (props)=> {

  return (
    <Modal 
    
    //size="tiny"
    dimmer={props.dimmer} // "inverted"/"blurring"
    open={true} 
    onClose={props.closePopup}
    closeOnEscape={false}
    closeOnDimmerClick={false} 
    style = {{
      top:'auto',  // for positioning of modal at center of view port
    maxWidth:'300px'
    }}
    >
     
      <Modal.Content className='border-2'
      >


        <div
        className='font-18B mb-20'
        style={{color:props.color}}
        >
        {props.message}
        </div>

        <div className='display-flex j-c-flex-end'>
        {props.action1 &&  (<span 
       className='icon-button font-16B border1 px-05 '
        onClick={props.closePopup}
        > 
        {props.action1}
        </span >)}
     {props.action2 &&  (<span 
       className='icon-button font-16B border1 px-05 '
        onClick={props.closePopup}
        > 
        {props.action2}
        </span >)}
 
        </div>
      </Modal.Content>
    </Modal>
  );
    
    // return (

    //   <Popup size="tiny" open={true} 
    //   onClose={props.closePopup}
    //   //content={props.message}
    //   style = {{
    //     borderRadius: 15,
    //     fontSize:"20px",
    //     fontWeight:"bold",
    //     color:props.color,
    //     backgroundColor:'#fae6ff',
    //     //backgroundColor:'Salmon',
    //     //opacity: 0.7,
    //     padding: '30px',
    //     //zIndex:5
    //   }}
    //   offset={[100, 50]}
    //   position='bottom center'
    //   size = 'tiny'
    //   positionFixed
      
      
    //  >
    //    <div className='disflex-paginator'>
    //    <span className='border2 '
    //     onClick={props.closePopup}
    //     > 
    //     <Icon name='close' />
    //     </span >
    //     </div>

    //     <div>
    //     {props.message}
    //     </div>
    //  </Popup>
       
    // );
  }

// const mapState = state => ({
//   message1: state.popups.popupProps.message
// });
export default connect(
  null,
  actions
)(CopyPhoneFixedPopup); 
