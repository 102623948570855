import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
//import writtenNumber from "written-number";

import {searchStatics} from "../../actions/staticAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button, } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";

import {
 
  adForOption,
  //adLocationProperty,
  adCategoryOption,
  adLocationPropertyCommonO,
  adLocationPropertyBFloorO,
  adLocationDealer,
  adSubLocation,
  // position,
  // locality,

} from './Data';




const StaticSearch = props => {
  
  const { handleSubmit,  reset, submitting } = props;
  
  const onSubmit = values => {
    const offset = 0
    const valuesF=()=>{
     if (values.adFor === "Dealer"){
      return {...values,adSubLocationA:[],adCategory: ''}
     }
     if (values.adFor !== "Dealer"){
      if(((values.adLocation === 'SIMILAR PROPERTY') || (values.adLocation === 'BUILDER FLOOR SIMILAR PROPERTY'))){
        return {...values,adSubLocationA:[]}
      }else{
        return {...values}
      }
     } 

    } 

const { limit} = props.state.statics;
    props.searchStatics(valuesF(), limit,offset);
  };
  //********************** */
  const { adFor,adCategory, adLocation } =
  props.state.form &&
  props.state.form.StaticSearch !== undefined &&
  props.state.form.StaticSearch.values !== undefined &&
  props.state.form.StaticSearch.values;


//@@@@@

const adLocationProperty = ()=>{
  const data =()=>{
    if(adCategory==='Commercial'){
      return adLocationPropertyCommonO
    }
    if(adCategory==='Residential'){
      return [...adLocationPropertyCommonO,
        ...adLocationPropertyBFloorO]
    }
  }
return data() !==undefined && data().map(value => ({
  key: value,
  text: value,
  value: value,style:{fontSize:'18px'}
}));
}

// ^^^
const renderAdSubLocationField = ()=>{

  if( 
    adLocation && ((adLocation === 'STATIC') || (adLocation === 'BUILDER FLOOR STATIC') 
)
) {

return (
  <>
      <label > Ad Sub Location</label>
      <Field
      search={true}
      name="adSubLocationA"
      type="text"
      component={SelectInput}
      multiple={true}
      options={adSubLocation()}
      placeholder="Ad Sub Location "
      />
      </>
      )
}else{
  return(<div></div>)
}
}
// ^^^
const renderAdForPropertyField = ()=>{
return (
 < React.Fragment>
        
        <label >Ad Category</label>
        <Field
          search={true}
          name="adCategory"
          type="text"
          component={SelectInput}
          options={adCategoryOption()}
          placeholder="Ad Category"
        />

        
          <label >Ad Location</label>
          
          <Field
            search={true}
            name="adLocation"
            type="text"
            component={SelectInput}
            //multiple={true}
            options={adLocationProperty()}
            placeholder="Ad Location "
            />

            {/*  */}
        
           {renderAdSubLocationField()}
            

          
            
          
 </React.Fragment>
)

}

//@@@@@
const renderAdForDealerField = ()=>{

return (
 < React.Fragment>
        
        {/* <label >Ad Category</label>
        <Field
          search={true}
          name="adCategory"
          type="text"
          component={SelectInput}
          options={adCategoryOption()}
          placeholder="Ad Category"
        /> */}

<label >Ad Location</label>
          
          <Field
            search={true}
            name="adLocation"
            type="text"
            component={SelectInput}
           // multiple={true}
            options={adLocationDealer()}
            placeholder="Ad Location "
            />
            
          
 </React.Fragment>
)

}


//@@@@@@@@@@@@
const renderDealerOrPropertyField = () =>{
if((adFor==='Property SALE') || (adFor==='Property RENT')){
return renderAdForPropertyField()
}
if((adFor==='Dealer') ){
return renderAdForDealerField()
}
}



//********************** */


  return (
    
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Static Ad To Book
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
 #  Leave All Field Blank (Clear Values) to View All Available Static Ad To Book  
           <br /><br />
            

          <Button
          color="teal"
          type="button"
          onClick={()=>props.closeModal()}

          >
          Cancel
          </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting} 
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          <label >Ad For</label>
        <Field
          search={true}
          name="adFor"
          type="text"
          component={SelectInput}
          options={adForOption()}
          placeholder="Ad For"
        />

        {renderDealerOrPropertyField()}

        
            {/*  */}
            <label >Lower Started On Date</label>
            <Field
              name="startL"
              type="text"
              component={DateInput}
              placeholder= "Lower Started On Date To Search Static Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Higher Started On Date</label>
            <Field
              name="startH"
              type="text"
              component={DateInput}
              placeholder="Higher Started On Date To Search Static Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Lower Expired On Date</label>
            <Field
              name="expireL"
              type="text"
              component={DateInput}
              placeholder= "Lower Expired On Date To Search Static Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Higher Expired On Date</label>
            <Field
              name="expireH"
              type="text"
              component={DateInput}
              placeholder="Higher Expired On Date To Search Static Ad Between Two Dates"
              dateFormat="dd-MM-yyyy"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />



            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting} 
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state
  };
};
export default connect(
  mapStateToProps,
   {searchStatics,closeModal}
)(
  reduxForm({
    form: "StaticSearch", // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(StaticSearch)
);
