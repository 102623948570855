import React, { Component } from "react";
import { Link, } from "react-router-dom";
import { connect } from "react-redux";
//import {CopyToClipboard} from 'react-copy-to-clipboard';
import { openPopup } from "../../actions/popupAction";
import {createContactReq} from "../../actions/contactReqAction";
import { openModal,closeModal } from "../../actions/modalAction";

import {
  Button, 
} from "semantic-ui-react";



class DealerSuggested extends Component {
  

  contactReqCreateHandler=(companyIdD2DC,id,name,contactR)=>{
    const values= {...contactR,dealer:id,dealerName:name,companyIdD2DC,}
   
    if (!this.props.userA) {
      this.props.openModal('LoginModal')

    }else{
      this.props.createContactReq( values)
      this.props.closeModal()
    }

  }

//****************** */
handleOpenPopup = (ph , col) => {
  const message= `${ph} Mobile Number Copied` 
  this.props.openPopup("CopyPhoneFixedPopup" , {message:message, color:col,});
};
//***** */
//company data
  renderList=()=> {

    const {
      cname,
      cimage,
      locality,
      city,
      //address,
     // state,
      //nameCP1,
     // mobileCP1,
     // nameCP2,
     // mobileCP2,
      //noteForAd,
      companyIdD2DC,
      _id,
    } = this.props.company.dealer
    const id = _id
      
  //   const {
  //     uname,
  //     uphone1,
  //     uphone2,
  //     uemail,
  //     uwhatsapp,

  //     }=this.props.userA !== null && this.props.userA
  //   const  requesterId= this.props.userA !== null && this.props.userA._id
  // const contactReq= {
  //   requestFor:'General',
  //   uname,
  //   uphone1,
  //   uphone2,
  //   uemail,
  //   uwhatsapp,
  //   requesterId,
  //   }
  const renderDetailButton = () =>{
    return this.props.userA != null &&
         (this.props.userA.userGroup ==='Dealer' ||
         this.props.userA.userGroup ==='Builder') ? (
           <Button
       size="mini"
       fluid
       color="teal"
       type="button"
       as={Link}
       //onClick={() => this.contactReqCreateHandler(companyIdD2DC,id,cname,this.props.contactReq)}
    to={`/company/company/${_id}`}
       >
       <span className='display-button'>Detail</span>
       </Button>
 
         ) : 
      (
       <Button
       size="mini"
       fluid
       color="teal"
       type="button"
       as={Link}
       onClick={() => this.contactReqCreateHandler(companyIdD2DC,id,cname,this.props.contactReq)}
    to={`/company/company/${_id}`}
       >
       <span className='display-button'>Detail</span>
       </Button>
      )
   }

  // const renderDetailButton = () =>{
  //   return (
  //   <Button
  //   size="mini"
  //   fluid
  //   color="teal"
  //   type="button"
  //   as={Link}
  //   onClick={() => this.contactReqCreateHandler(companyIdD2DC,id,cname,this.props.contactReq)}
  //   to={`/company/company/${_id}`}
  //   >
  //    <span className='display-button'>Detail</span> 
  //   </Button>
  //    )
  // }


  const renderContactReqButtonOrNot = () =>
        this.props.userA != null &&
        (this.props.userA.userGroup ==='Dealer' ||
        this.props.userA.userGroup ==='Builder') ? (
          <div></div>

        ) : (
      <Button
      fluid
      color="blue"
      type="button"
      onClick={() => this.contactReqCreateHandler(companyIdD2DC,id,cname,this.props.contactReq)}
      >
      Ask Dealer To Contact You
      </Button>

        );
        //************************


      //************************
    return (
      <div  key={_id} className='disflex-DLMP-cardwrapper'>
      <div className='card-ds border-All' style={{padding:'5px',}} >{/* 1 */}


      <div className='disflex-dsl border-bottom' >{/* 2 */}
      {/* <div className='row'> */}
      <div className=''style={{flex:'25%'}} >
      {/* <Image
      floated="left"
      size="tiny"
      src={`${cimage}`}
      /> */}
      <img className= 'imgDimention-ad img-object' src={`${cimage}`} alt="Company Logo"  />
      </div>
      {/*  */}
      {/* <div className='main' style={{display:'flex',justifyContent:'center',alignItems:'center'}} >
      <div >
      {mobileCP1 && (
      <div >
      <b>{mobileCP1} </b>
      <span>
          <span className='nodisplay-tab'>
          <CopyToClipboard text={mobileCP1}  >
          <span className="icon-button"  onClick= {() => this.handleOpenPopup(mobileCP1,'blue' )}>
<img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
</span>
         </CopyToClipboard>
         </span>
         </span>
      </div> )} 

      {mobileCP2 && (
      <div >
      <b>{' '}{mobileCP2} </b>
      <span>
          <span className='nodisplay-tab'>
          <CopyToClipboard text={mobileCP2}  >
          <span className="icon-button"  onClick= {() => this.handleOpenPopup(mobileCP2,'teal' )}>
<img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
</span>
         </CopyToClipboard>
         </span>
         </span>
      </div>)}
      </div>
      </div> */}
      <div className=' align-center' style={{flex:'75%'}}  >
      <div className='font-dsl-head-main'> {cname}</div >
      </div>
           

      </div>{/* 2 */}

      {/*  */}
      {/* <div className='font-dsl-head-main'> {cname}</div > */}
      <div className='font-dsl-loc-main'>{locality}{' '}{city} New Delhi </div>
      {/*  */}
      
      {/* 4 */}
      <div style={{padding:'5px'}}>
      <div className='display-button-dlmp'>
      {renderDetailButton()}
      {renderContactReqButtonOrNot()} 
      </div>
      </div>
      {/* 4 end */}

      

      </div>{/*1 end */}
      </div>



    );
  }


  render() {
   
    return (
      <div>
         
        {this.renderList()}

      </div>
    );
  }
}


const mapStateToProps = ({ auth }) => {
  return {
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
  {openPopup,createContactReq,openModal,closeModal}
)(DealerSuggested);
