import React, { Component } from "react";
import ShortlistResiSale from './ShortlistResiSale'
import ShortlistResiRent from './ShortlistResiRent'
import {
  Button,
} from "semantic-ui-react";




class ShortlistResi extends Component {
  state = {
    propSale:true,
    propRent:false,
  };
  
  showShortlistedSale =()=> this.setState({ propSale: true,propRent: false, })
  showShortlistedRent =()=> this.setState({ propSale: false,propRent: true, })
  renderShortlistedSale = () => {
    if (this.state.propSale===true) {

      return <ShortlistResiSale />;
    }
  };
  renderShortlistedRent = () => {
    if (this.state.propRent===true) {

      return <ShortlistResiRent />;
    }
  };
  //****************** 



  render() {

    return (
      <div className='maindiv-820'>
        {/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2' >
<span  >All Shortlisted Residential Properties for Sale and Rent</span>
</div>
<Button.Group widths="2"  >

<Button
  // basic
  // inverted
  floated="right"
  color="blue"
  type="button"
  onClick={this.showShortlistedSale}
>
 <b> Shortlisted Properties for Resale</b>
</Button>
<Button
  // basic
  // inverted
  style={{marginLeft:'1px'}}
  floated="right"
  color="blue"
  type="button"
  onClick={this.showShortlistedRent}
>
Shortlisted Properties for Rent|PG
</Button>

</Button.Group>
</div>
{/*  */}

{ this.renderShortlistedSale()}
{ this.renderShortlistedRent()}

</div>
    );
  }
}


export default ShortlistResi

// export default connect(
//   mapStateToProps,
//  {fetchShortlistResiSale,openModal, shortlistAddRemoveResi}
// )(withRouter(ShortlistResiSale));
