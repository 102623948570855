import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../common/Loading"; 
import {similarSalePropertiesCom} from "../../actions/staticAction";
import PropComSummary from './PropComSummary';


class SimilarPropComSaleList extends Component {
  

  componentDidMount() {
    const values = this.props.values;
    this.props.similarSalePropertiesCom(values);
    
  }
  componentDidUpdate(prevProps) {
    //Typical usage, don't forget to compare the props
    if ((this.props.values.carpetAreaMin !== prevProps.values.carpetAreaMin) || (this.props.values.carpetAreaMax !== prevProps.values.carpetAreaMax)) {
      const values = this.props.values;
      this.props.similarSalePropertiesCom(values);
      }
     }
  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }
  renderNoProperty=()=> {
    if (this.props.statics.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE</b></span>

        </div>
      );
    }
  }


  render() {
    
    return (


<div>


    {/*  */}
    <div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
    
    <div className='heading-2'>
<span  > Similar Commercial Property For Resale</span> 
</div>
      
          </div>

    <div className='card-attached border-sb pxy-05'  >
    {this.renderNoProperty()}
    {this.props.statics.map((item)=> (<PropComSummary   key={item._id}   propcom={item}/>))}
    </div>
    {/*  */}


<br/><br/><br/><br/>
</div>

    );
  }
}



const mapStateToProps = ({ statics,auth,  }) => {
  const { fetching,limit, offset, count } = statics;

  return {
    fetching,
    limit,
    offset,
    count,
   // form: form.MystaticsaleSearch,
    statics: statics.statics,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {similarSalePropertiesCom,}
)(SimilarPropComSaleList);
