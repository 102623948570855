import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, Route } from "react-router-dom";

import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";

import DealerSearchList from "./DealerSearchList";
import FinancerSearchList from "./FinancerSearchList";
import ShortlistCompany from "./ShortlistCompany";

import OtherCompanyDetailLinkFromList from "./OtherCompanyDetailLinkFromList";
import OtherCompanyDetailLinkFromDSuggested from "./OtherCompanyDetailLinkFromDSuggested";
import CompanyEdit from "./CompanyEdit";
import AddImage from "./AddImage";
import MyCompanyDetail from "./MyCompanyDetail";

import UserDetail from "./UserDetail";
import UserEdit from "./UserEdit";
// import UserList from "./UserList";
import Layout from "../layout/Layout";
//#####################



  class CompanyDashboard extends Component {
    componentDidMount() {
      if(this.props.auth.isAuthenticated){
        this.props.getUserForAuth();
        
      }
      if(this.props.history.location.pathname !=='/'){
        this.props.showBackButton()
      }
    }
    onLogoutClick = () => {
      this.props.logoutUser();
    };
  render(){
    return (
    <div className="mt-10">
    <div className='maindiv'>

    <Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
    <Route exact path="/company/editcompany/:id" component={CompanyEdit} />
    <Route exact path="/company/addimage" component={AddImage} />
    <Route
      exact
      path="/company/company/:id"
      component={OtherCompanyDetailLinkFromDSuggested}
    />
    <Route
      exact
      path="/company/CompanyDetail/:id"
      component={OtherCompanyDetailLinkFromList}
    />

    <Route exact path="/company/mycompany" component={MyCompanyDetail} />

    <Route
      exact
      path="/company/ShortlistCompany"
      component={ShortlistCompany}
    />
    <Route
      exact
      path="/company/DealerSearchList"
      component={DealerSearchList}
    />
    <Route
      exact
      path="/company/FinancerSearchList"
      component={FinancerSearchList}
    />
    {/* <Route exact path="/company/UserList" component={UserList} /> */}
    <Route exact path="/company/UserDetail/:id" component={UserDetail} />
    <Route exact path="/company/UserEdit/:id" component={UserEdit} />



    </Switch>
        </Layout >
        </div>

    <div >
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>
      );


}
}
const mapStateToProps = state => ({
auth: state.auth
});

export default connect(
 mapStateToProps,

  {showBackButton, getUserForAuth, logoutUser  }
)(CompanyDashboard);
