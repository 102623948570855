


//#########
const propertyForO = [ '','Rent', 'PG Accommodation'];
export const propertyFor = () => {
  return propertyForO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};

//#######
const bedroomO = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const bedroom = () => {
  return bedroomO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//############
const bathroomO = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
export const bathroom = () => {
  return bathroomO.map(value => ({ key: value, text: value, value: value ,style:{fontSize:'18px'} }));
};
//###############
const propertyTypeO = [
  'FLAT-Society',
  'FLAT-DDA',
  'Builder Floor',
  'Penthouse',
  'Duplex House',
  'Residential Land /Plot'
];
export const propertyType = () => {
  return propertyTypeO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

//###################
const entryO = [

  'East',
  'West',
  'North',
  'South',
  'North - East',
  'North - West',
  'South - East',
  'South -West'
];
export const entry = () => {
  return entryO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//#######
