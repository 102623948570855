import React, { Component } from 'react';
import { Icon,
  //Image
 } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player'
import { closeModal } from '../../actions/modalAction';
import {fetchProjectVideos ,fetchPropertyVideos} from "../../actions/videoAction";


class ShowImages extends Component {
  
  componentDidMount = () =>{
    const id = this.props.id
    if(this.props.imageOf === 'Project'){
      this.props.fetchProjectVideos(id)
    }
    if(this.props.imageOf === 'Property'){
      this.props.fetchPropertyVideos(id)
    }

  } 

  render() {
    
    const  videos = () => {
      if(this.props.imageOf === 'Project'){
        return this.props.videosPRT[0]
      }
      if(this.props.imageOf === 'Property'){
        return this.props.videosPRY[0]
      }
      
      };
      const renderVideos = ()=>{
        if(this.props.videosPRY.length>0 || this.props.videosPRT.length>0){
          return (
            <div className='player-wrapper'>
            <ReactPlayer
            // Disable download button
  config={{ file: { attributes: { controlsList: 'nodownload' } } }}

  // Disable right click
  onContextMenu={e => e.preventDefault()}

            //playing
            controls = {true}
            className='react-player'
            url={videos().path}
            width='100%'
            height='100%'
            />
            </div>
          )
        }else{
          return (
            <div className='card-noShow' >
             Oops ! No Video Available
            </div>
          )
        }
      }
            //***** */
    return (
      <div
      style = {{
        borderRadius: 3,
        fontSize:"16px",
        fontWeight:"bold",
        //background:'transparent',
         backgroundColor:'black',
        margin:'0px',
        padding: '0px',
      }}
      >
        <div className='disflex-paginator'>
        <span className='icon-button border1 '
        onClick={this.props.closeModal}
        style={{color:'white'}}
        > 
        <Icon name='close' />
        </span ><span style={{color:'white'}}>{videos() !== undefined && videos().description}</span>
        </div>

        {renderVideos()}
      </div>    
    );
  }
}

const mapState = state => ({
  state,
  videosPRT:state.videos.videosPRT,
  videosPRY:state.videos.videosPRY,
});
export default connect(
  mapState,
  {closeModal,fetchProjectVideos ,fetchPropertyVideos}
)(ShowImages);
