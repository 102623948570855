import React from "react";
import { connect } from "react-redux";
import { closeModal } from "../../actions/modalAction";
import {  Button,  Header, } from "semantic-ui-react";


const TermsAndConditions = props => {
  
  return (
    <div style={{maxWidth:'700px',margin:'auto'}} >
        <div  className ='border-bottom-form'  >
        <Header
                color="blue"
               content="Privacy Policy"
              />
        </div>
        <div style={{padding:'15px'}}>
        <p>This Privacy Policy (the “Policy”) describes how
          CITSYS (hereinafter referred to as “CITSYS”), collects,
          uses, shares and protects personal information that we collect
          from you, the end user of the CITSYS website, applications
          (hereinafter called as “apps”) and services
          (collectively, “CITSYS Products”).A reference to “CITSYS,”
          “we,” “us,” or “our” is a reference to CITSYS.
          </p>
          <p>

While you use and enjoy our services, we want to keep you informed
about our privacy policy towards management and security of your
personal information. We value the trust you place in us, and hence
insist upon the highest level of protection for securing your personal
information. We are obligated to protect your personal information in
accordance with applicable laws, and regulations.
</p>
<p>
This Policy should be read in conjunction with Terms and Conditions.
Registration or usage of any of CITSYS services implies your express
consent and agreement to this Policy.
</p>
<p>
This Policy is subject to modification based on changes in the business,
legal and regulatory requirements and will be made available to you
online. We will make all efforts to communicate about significant
changes to this policy. You are encouraged to periodically visit this
page to review the policy and any changes.
</p>
<h6>
Scope of this Policy

</h6>
<p>
This Policy applies to all the software and services provided by CITSYS
through our websites including https://findprop.in,
mobile applications and applications posted by CITSYS in any
third-party online marketplaces. This policy does not apply to any
third party service providers linked to this website and having a
separate privacy policy.
</p>

<h6>
Collection of Information
</h6>
<p>
In order to enable us in providing you with the best in class services,
we require certain information be shared by you with us. CITSYS may
collect the following types of information:
</p>
<p>
<b>“Personal Information”</b> which shall mean information which could
reasonably be used to identify you personally, which may include,
but not limited to the following:
</p>
<p>
a. Basic details which you provide during the registration process such as Name, Phone Number, Email Address, Communication or Permanent Address.
</p>
<p>b. Photograph provided during registration or during the profile update.
</p>
<p>c. Proof of Identity and Address documents such as PAN Card, Driving License, Passport etc.
</p>
<p>d. Transactions performed which can identify you as an individual through your customer ID or other relevant unique identifier.
</p>
<p>e. Demographic details such as gender, city, PIN code or nearest location.
</p>
<p>f. Usage logs/Server logs/Cookies which may contain details of your personal or sensitive personal information.
</p>
<p>g. Service specific information or identifiers such as, Customer ID etc.
</p>
<p>h. Device specific information or identifiers such as IMEI, OS specific identifiers.
</p>
<p>
For the purpose of this policy, sensitive personal data or information has been considered as a part of Personal Information, which may include, but not limited to the following:
</p>
<p>i.	Authentication information for any of our services or apps.
</p>
<p>ii.	Financial information, such as details of bank account, credit card, debit card, or other payment instrument details etc.
</p>
<p>
<b>“Non-Personal Information”</b> which shall mean information that does not
identify you or any other individual, and includes session, web beacons
 and usage and transaction data, aggregate log data and aggregate
 information.
 </p>
 <p>
We automatically collect certain types of usage information when you
visit our Service, read our emails, or otherwise engage with us. We
typically collect this information through a variety of tracking
technologies, including cookies, web beacons, embedded scripts,
location-identifying technologies, file information, and similar
technology (collectively, “tracking technologies”). We may collect
and store information locally on your device using mechanisms such
as browser storage and application data caches. We may collect
analytics data or use third-party analytics tools such as Google
Analytics to help us measure traffic and usage trends for the Service
and to understand more about the demographics of our users. We also
save all the information that you may provide along with the complaint,
 like photo, video/audio, user location and other complaint related
 data. We also may share your information as well as information from
 tools like cookies, log files, and device identifiers and location
 data, with third-party organizations that help us provide the Service
 to you ("Service Providers"). Our Service Providers will be given
 access to your information as is reasonably necessary to provide
 the Service under reasonable confidentiality terms.

 </p>
 <b>Information from Mobile applications
 </b>
 <p>Our mobile applications will have access to the camera, messages
 photo gallery, and phone book only when you choose to permit such
 access. You may disable the mobile applications' access to this
 information at any time by editing the settings on your mobile device.
 Similarly, when you enable notifications on our mobile application,
 CITSYS will push notifications to your device using a third party
 push notification service provider.
 </p>
 <h6>
Use of Information

</h6>
<p>
The information provided by you to us is used for a number of purposes
connected with our business operations which may include the following:

</p>
<p>a.	Primarily to connect with you to authorize Your access to our services;
</p>
<p>b. Verify your identity, access, privileges assigned and relationship with us.

</p>
<p>c.Provisioning of products/services, testing or improvement of services, recommending various products or services;
</p>
<p>d.Processing your requests, enquiries and complaints, customer services and related activities;
</p>
<p>e.Communicating to you about our software and services, bills, invoices, existing or new offers, content, advertisements, surveys, key policies or other administrative information; to communicate about;
</p>
<p>f.Analytics, reviews, for improvement of our products and services;
</p>
<p>g.Improving your experience while using our services by presenting advertising, products and offers tailored to you;
</p>
<p>h.Completing your purchase;
</p>
<p>i.Communicate about changes to our Terms of Use, Privacy Policy or other important communications which are considered to be transactional
</p>
<p>j.Support to law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law or anti-fraud activities/systems; and
</p>
<p>k.Other usages that you may consent to.

</p>
<p>We will strive to keep your personal information confidential and will place reasonable security controls to protect it. Your Personal Information will be kept confidential as required under applicable laws.
</p>
<p>However, any information, any comments, messages, blogs, scribbles etc. posted / uploaded / conveyed / communicated by you on the public sections of the apps/website becomes published content and is not considered personal information subject to this Policy. You may request that such information be taken down and, we may attempt to remove any such information if it is technically feasible to do so and as per the applicable laws.
</p>
<p>Further, with respect to Non-Personal Information automatically collected and stored in files, we shall use this information to understand and analyze trends, to administer our services, to learn about user behavior on the apps and while using our services, to gather demographic information about the user base as a whole and to measure traffic patterns on our network and apps. We use this information to inter-alia deliver our web pages to you upon request, to tailor our services to the interests of our users, to improve the quality, functionality and interactivity and let advertisers know the geographic locations from where our users/ visitors come. As Non-Personal Information does not personally identify you, we may use and disclose Non-Personal Information at our discretion.
</p>
<h6>
Sharing and Disclosure
</h6>
<p>
We do not sell or rent personal information to any third party
entities. We limit the disclosure of the Personal Information to
certain circumstances, as provided below:
</p>
<p>a.	Mergers or acquisitions: In a scenario where we or our assets are merged or acquired by the other business entity, or during restructuring of business or re-organization, we may have to share information provided by you with the other business entities. Rest assured, if such a transaction occurs the other business entity or the newly combined business entity would be required to follow this Privacy Policy.
</p>
<p>b.	Partners: we engage a number of vendors, consultants, contractors
and take support of our group companies or affiliates (hereon referred
  to as our Partners). Our Partners provide a host of services
  including contact information verification, payment processing,
  customer service, website hosting, data analysis, infrastructure
  provision, IT services, and other similar services. These Partners
  play a key role in seamless delivery of our products and services to
  you. These Partners and their employees operate under a contract and
  strict security & confidentiality restrictions. We may provide our
  partners access to your information through our systems or may share
  your Personal Information with them to enable them to provide their
  services to you.

  </p>
  <p>c.	Customer Initiated Sharing: Whenever you request us to share
  your information with a third party website or application
  (for example, login to a third party using your CITSYS ID), we will
  provide a fresh notice and obtain consent from you regarding the
  details of the information that will be shared, before sharing the
  same. In such a case, CITSYS will not be in control of the information
   (shared with the third party website/application or data generated
     based on your usage on that website/application) and privacy
     policy of CITSYS will not be applicable for any such use, access etc. Any
     processing, use, sharing of Information provided by you to such
     third party website/application shall be as per the respective
     privacy policy of such applications and websites. The customer
     shall read and provide consent to the third party’s privacy policy
     before requesting CITSYS to share the information.
     </p>
     <p>d.	Government or judicial process: We may share your Personal Information with the government / government authorities or agencies and legal or judicial authorities for any investigation or to comply with legal process or in response to a request by any of these authorities or to enforce applicable terms and conditions or to protect our, our users and Partners rights, privacy, safety or property.

     </p>
     <p>e.	Legal Reasons: The information provided by you may be shared with our other entities and affiliates to help in detecting and preventing identity theft, frauds and other illegal activities; correlate or map related accounts to prevent misuse of our products & services and to provide you our services.

     </p>
     <p>f.	Protection of CITSYS: To protect your rights and/or those of our Partners, and to allow CITSYS to pursue available remedies or limit the damages that it may sustain in case of unauthorised use / misuse of our products or the content thereon, by third parties.

     </p>
     <h6>Storage, Retention and Security
     </h6>
     <p>1. We have adopted reasonable security practices and procedures, in line with international standard IS/ISO/IEC 27001, to include strategic, operational, managerial, technical, and physical security controls to safeguard and protect your data and information. We have implemented such measures, as stated above, to protect against unauthorized access to and unlawful interception of Personal Information. Additionally, we have adopted measures to ensure that your personal information is accessible to our employees or partners employees strictly on the need to know basis.
     </p>
     <p>2. Our website, applications, portals, and network equipment have industry standard security precautions in place to protect the loss, misuse and alteration of information which is in our environment. Whenever you change or access your account information, we facilitate the use of secure systems. The information in our custody and control is protected by adherence to reasonable security procedures in order to safeguard against unauthorized access. We apply encryption or other appropriate security controls to protect Personal Information when stored or transmitted by us.
     </p>
     <p>3. Your information or data will primarily be stored in
     electronic form. However, certain data can also be stored in
     physical form.
     </p>
     <p>We may enter into agreement with third parties to collect,
     store, process your information or data but under full compliance
     with applicable laws. These third parties may have their own
     security standards to safeguard your information or data and we
     will require such third parties to adopt reasonable security
     standards to safeguard your information / data on commercially
     reasonable basis.
     </p>
     <p>We have taken appropriate steps for the security and protection
     of all our digital platforms including internal applications,
     however, we shall not be responsible for any breach of security
     or the disclosure of Personal Information for reasons outside our
     control, such as hacking, social engineering, cyber terrorism,
     espionage by third parties, or any events by way of force majeure
     such as sabotage, fire, flood, explosion, acts of God, civil
     commotion, strikes or industrial action of any kind, riots,
     insurrection, war, acts of government.
     </p>
     <p>Third Party Websites, Applications and Services
     </p>
     <p>CITSYS Application/website may include links to other
     websites/applications. Such websites/application are governed
     by their respective privacy policies, which are beyond our
     control. Once you leave our servers (you can tell where you are
       by checking the URL in the location bar on your browser), use
       of any information you provide is governed by the privacy policy
        of the operator of the website/application you are visiting.
        That policy may differ from ours. If you can't find the privacy
         policy of any of these websites/application via a link from
         the website's homepage, you should contact the
         website/application directly for more information.
         </p>
         <p>Whenever you request us to share your information with a
         third party website or application (for example, login to a
           third party using your CITSYS ID), we will provide a fresh
           notice and obtain consent from you regarding the details
           of the information that will be shared, before sharing the
           same. In such a case, CITSYS will not be in control of the
           information (shared with the third party website/application
             or data generated based on your usage on that
             website/application) and CITSYS’s privacy policy will not be
             applicable. The third party website/application will handle
              the information according to their own privacy policy.
              The customer shall read and provide consent to the third
              party’s privacy policy before requesting CITSYS to share
              the information.
              </p>
              <h6>Access, Correction and Deletion
              </h6>
              <p>We strive hard to keep our records updated and
              accurate with your latest information. You shall be
              responsible to ensure that the information or data you
              provide from time to time is and shall be correct, current
               and updated and you have all the rights, permissions and
               consent to provide such information or data.
               </p>
               <p>Based on technical feasibility, we will provide you
               with the access to all your personal and sensitive
               personal information that we maintain about you. We
               will perform verification before providing you access
               to this information.
               </p>
               <p>You may request access, correction or updation, and
               deletion of the information by contacting CITSYS.
               </p>
               <p>You may note that deletion of certain information or
               withdrawal of consent may lead to cancellation of your
               registration with us or your access to our services.
               Additionally, we may not be able to process your request
               of correction, updation or deletion, in case the same is
               not supported by valid documents or data retention if
               required by the applicable law or law enforcement
               requests or under any judicial proceedings or it is
               extremely difficult to implement (such as requests
                 related to backup copies or if a new system is
                  required to process the request or change of
                  technical design) or risks the privacy of other
                  users.
                  </p>
                  <h6>Queries and complaints
                  </h6>
                  <p>We are committed to protect your personal
                  information collected and processed by us and look
                  forward to your continued support for the same. In
                  case of any feedback or concern regarding protection
                  of your personal information, you can contact us at
                  grievance.officer@citsys.in. Alternatively, you may
                  also direct your privacy-related feedback or concerns
                   to the Privacy Grievance Officer whose details are as
                    mentioned below:
                    </p>
                    <p>If you have questions or concerns about this
                    Policy, please contact  CITSYS at
                    grievance.officer@citsys.in.
                    </p>
                    <p>Last Updated: 1 August 2021
                    </p>
                    <p>Copyright © 2021, CITSYS
All rights reserved.

</p>


                           <Button
              color="blue"

              type="button"
              onClick={()=>props.closeModal()}

            >
             Got it
            </Button>

            </div>
            </div>
  );
};

export default connect(
 null,
   {closeModal}
)(TermsAndConditions)

