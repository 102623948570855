
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { closeModal,openModal } from "./modalAction";
//import { hashHistory } from 'react-router';
import {
  SEARCH_BUILDERS,
  FETCH_BUILDERS,
  FETCH_BUILDER,
  FETCHING_BUILDERS,
} from "./types";

//search  all dealers

export const searchBuilders = (
  values,
  limit,
  offset
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/builderSearch", { values,limit, offset });

    dispatch({ type: SEARCH_BUILDERS, payload: res.data });
    dispatch({ type:FETCHING_BUILDERS, payload: false });
    dispatch(closeModal());
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


//fetch all companies
export const fetchBuilders = () => async dispatch => {
  try {
    const res = await axios.post("/api/builders");

    dispatch({ type: FETCH_BUILDERS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
// //fetch Shortlist companies (for gurgaon / noida)
// export const fetchShortlistBuilders = (
//   limit,
//   offset,
// ) => async dispatch => {
//   try {
//     const res = await axios.post("/api/ShortlistBuilders", { limit,offset,
//       values:{} });
   
//     dispatch({ type: FETCH_BUILDERS, payload: res.data });
//     dispatch({ type:FETCHING_BUILDERS, payload: false });
//   } catch (error) {
//     toastr.error("Oops", "Something went wrong.Please Try Again");
//   }
// };

//****************************

//fetch single builder
export const fetchBuilder = id => async dispatch => {
  try {
    const res = await axios.get(`/api/builder/${id}`);
    
    dispatch({ type: FETCH_BUILDER, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch MY builder
export const fetchMyBuilderCompany = () => async dispatch => {
  try {
    const res = await axios.get(`/api/myBuilderCompany`);
    
    dispatch({ type: FETCH_BUILDER, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//update builder
export const updateBuilder = (id, values) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/builder/${id}/update`, values);

    if (res.data.msg ==='success') {
      toastr.success('Builder Updated Successfully');
       }
  } catch (error) {
if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }
};



//#######################################################
//to update  builder ranking
export const updateBuilderRanking = id => async () => {
  try {
    const res = await axios.post(`/api/UpdateBuilderRanking`,{id:id});
    switch (res.data.msg) {
    case 'errorAlready':
    toastr.error('This Dealer Already Added to List');
    break;
    case 'success':
    toastr.success('Ranking Successfully Updated');
    break;
    default:
      toastr.success(' ');
  }
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//#######################################################

//resetOffset

export const resetOffsetBuilders = () => async dispatch => {
  
  try {
        
    dispatch({ type: SEARCH_BUILDERS, payload: {offset:0} });
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};