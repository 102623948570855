import React, { Component } from "react";
import { connect } from "react-redux";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading";
import {searchPropComRents} from "../../actions/propcomAction";
import { fetchCompanysForPropListCR } from "../../actions/staticAction";
import { openModal } from "../../actions/modalAction";

import {
  Icon,
} from "semantic-ui-react";
import DealerSuggestedStatic from '../dealerSuggesteds/DealerSuggestedStatic';
import PropComSummary from './PropComSummary';



class PropComRentSearchList extends Component {
  state = {
    pageO:'',
    id:'',
  };
  componentDidMount() {
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
      const {
        limitCR,
        offsetCR,
        limitStaticCR,
        offsetStaticCR,
        limitStaticDealerCR,
        offsetStaticDealerCR , 
    } = this.props;
   
    this.props.searchPropComRents(values ,limitCR, offsetCR,limitStaticCR,offsetStaticCR);
    this.props.fetchCompanysForPropListCR(limitStaticDealerCR,offsetStaticDealerCR )

  }
  handleSearchPropComRent = () => {
    this.props.openModal("SearchPropComRentModal");
  };

  
    //^^^^^^^^^^^^^^^^^^^^^^^^^
      onChange=(e) => {
        this.setState({ [e.target.name]: e.target.value });
      }
    

    //###########################################
  back = () => {
    const {
      limitCR,
      offsetCR,
      limitStaticCR,
      offsetStaticCR,
      limitStaticDealerCR,
      offsetStaticDealerCR , 
  } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
      if (offsetCR === 0) {
      return;
    }

    this.props.searchPropComRents(values, limitCR, (offsetCR - limitCR),limitStaticCR, (offsetStaticCR - limitStaticCR));
this.props.fetchCompanysForPropListCR( limitStaticDealerCR, (offsetStaticDealerCR - limitStaticDealerCR));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      countCR,
      limitCR,
      offsetCR,
      limitStaticCR,
      offsetStaticCR,
      limitStaticDealerCR,
      offsetStaticDealerCR , 
  } = this.props;
    const values =
      (this.props.form !== undefined && this.props.form.values) || {localityA:[]};
      if (offsetCR + limitCR > countCR) {
      return;
    }

    this.props.searchPropComRents(values, limitCR, (offsetCR + limitCR), limitStaticCR, (offsetStaticCR + limitStaticCR));
this.props.fetchCompanysForPropListCR( limitStaticDealerCR, (offsetStaticDealerCR + limitStaticDealerCR));
    window.scroll(0,0)
  };
//***************** */
handleGoToPage = () => {
  const {
    countCR,
    limitCR,
    limitStaticCR,
    limitStaticDealerCR, 
} = this.props;
  const values =
    (this.props.form !== undefined && this.props.form.values) ||{localityA:[]};
   // use Math.abs for strict equality ===  to work and safe guard against negative value from user
   if (Math.abs(this.state.pageO) === 0) {
    return; 
  }

const page=(Math.abs(this.state.pageO*limitCR) > countCR)?(Math.ceil(countCR/limitCR-1)):(Math.abs(this.state.pageO)-1)


  //const page = (this.state.pageO-1)
    this.props.searchPropComRents(values, limitCR, (page * limitCR),   limitStaticCR, (page * limitStaticCR));
    this.props.fetchCompanysForPropListCR( limitStaticDealerCR, (page * limitStaticDealerCR));
    window.scroll(0,0)

};

//****************** */
  renderPaginator=()=> {
    if (this.props.propcomsCR.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offsetCR}
          limit={this.props.limitCR}
          count={this.props.countCR}
        />
      );
    }
  }

  renderLoading=()=> {
    if (this.props.fetchingCR===true) {
      return (<Loading/>)
    }
  }



  render() { 
   

    return (

      <div className='maindiv-820 '>
      
      
      <div> {/* main column start */}
      
      <div className='card-attached border-radius-top border-sb' style={{textAlign:'center',backgroundColor:'dodgerblue'}} >
        
      {/**/}
      <div style={{display:'flex',color:'white',padding:'5px'}}>
      
      <div
      style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
      onClick={this.handleSearchPropComRent}>
            <span className='icon-button2 border1' > <Icon name='search' /></span >
          </div>
      
      
      
      <div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
      <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
      {this.props.countCR} Commercial Property For Rent in {this.props.city}
      </div>
      </div>
      
      
      </div>
      {/**/}
      
        </div>
        {/*  */}
      
          
        <div className='card-attached border-sb pxy-05'  >
      
      {this.renderLoading()}
      
      {this.props.staticTop1.map((item)=> (<PropComSummary   key={item._id}   propcom={item} nameClass={'static'}/>))}
      {this.props.staticTop2.map((item)=> (<PropComSummary   key={item._id}   propcom={item} nameClass={'static'}/>))}
      
      
      {this.props.propcomsCR.map((item)=> (<PropComSummary   key={item._id}   propcom={item}/>))}
      
      {this.props.staticBottom1.map((item)=> (<PropComSummary   key={item._id}   propcom={item} nameClass={'static'}/>))}
      {this.props.staticBottom2.map((item)=> (<PropComSummary   key={item._id}   propcom={item} nameClass={'static'}/>))}
      
      
      </div>
      
      
      
      {/* static dealers ad */}
      <div className='card-attached border-sb '  >
      {this.props.staticsDealer && this.props.staticsDealer.length >0 && (
      
      <div  style={{padding:'10px',lineHeight:'24px', fontSize:'16px',textAlign:'center',backgroundColor:'dodgerblue',color:'white'}} >
      
      <div ><b>Dealers For Assistance And Consultancy To Buy/Sell/Rent In All Sectors Of Dwarka, Delhi</b></div>
      
      </div>
      )}
      
      
      
      <div className='disflex-DLMP media-600'
      
      //style={{flexDirection:'column',justifyContent:'center',alignItems:'center'}}
      >
      {this.props.staticsDealer.map((item)=>(<DealerSuggestedStatic  key={item._id}   company={item}/>))}
      </div>
      
      </div>
      {/*  */}
      
      <div className='card-attached border-sb '  >
      
      <div className='disflex-paginator card-paginator media-600'>
      {this.renderPaginator()}
      <div className="disflex-paginator  " >
        <div className= 'font-paginator'>Go To Page</div>
        <form>
              <input
              className='inputPage'
                placeholder="number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChange}
              />
              <span  className="btnNumber"  onClick={this.handleGoToPage} >
                Submit
              </span>
            </form>
      
              
      </div>
      <br/>
      </div>
      
      </div>
      {/*  */}
      
      
      <br/><br/><br/><br/>
      
      </div> {/* main column end */}
      {/* side column */}
      
      {/* side column end */}
      {/* side column display at bottom in mobile start */}
      
      {/* side column mobile end */}
      
      
      </div>
      
      
      
          );
    
    
    //@@@@@
    
  }
}


const mapStateToProps = ({ propcoms,auth, form,statics, }) => {
  const {city, fetchingCR,limitCR, offsetCR, countCR,} = propcoms;
 
  const { limitStaticCR, offsetStaticCR, countStaticCR,limitStaticDealerCR, offsetStaticDealerCR } = statics

  return {
    city,
    fetchingCR,
    limitCR,
    offsetCR,
    countCR,
    limitStaticCR,
    offsetStaticCR,
    countStaticCR,
    staticTop1: statics.staticTop1,
    staticTop2: statics.staticTop2,
    staticBottom1: statics.staticBottom1,
    staticBottom2: statics.staticBottom2,
    form: form.PropComRentSearch,
    propcomsCR: propcoms.propcomsCR,
    userA: auth.userA,
    limitStaticDealerCR,
    offsetStaticDealerCR,
    staticsDealer: statics.staticsDealer
  };
};

export default connect(
  mapStateToProps,
 {searchPropComRents,openModal, fetchCompanysForPropListCR,}
)(PropComRentSearchList);
