import axios from "axios";
import { toastr } from "react-redux-toastr";
import { closeModal,openModal } from "./modalAction";

import {
  FETCH_PROPCOMS,
  FETCH_PROPCOM,
  UPDATE_PROPCOM,
  SEARCH_PROPCOMS,
  FETCHING_PROPCOMS,
  MESSAGE,
  SEARCH_STATICS,
} from "./types";



//List All Properties In Project
export const allSalePropertiesInProjectC = (
  values,

) => async dispatch => {
 
  try {
    const res = await axios.post("/api/AllSalePropertiesInProjectC", {
      values,

    });

   
    dispatch({ type: SEARCH_PROPCOMS, payload: res.data });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//List All Properties In Project
export const allRentPropertiesInProjectC = (
  values,

) => async dispatch => {
 
  try {
    const res = await axios.post("/api/AllRentPropertiesInProjectC", {
      values,

    });

   
    dispatch({ type: SEARCH_PROPCOMS, payload: res.data });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//#######################################################
//fetch individual  MyPropCom
export const fetchMyPropComs = () => async dispatch => {
  try {
    const res = await axios.post(`/api/MyPropComs`);

    dispatch({ type: FETCH_PROPCOMS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetching:false} });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};
//******************
//fetch PropCom To BookAd
export const fetchPropComToBookAd = (
  values,
  limitCS,
  offsetBookAd
  ) => async dispatch => {
  try {
    const res = await axios.post( `/api/PropComToBookAd`, 
    {
      values,
      limitCS, 
      offsetBookAd 
    }
    );

    dispatch({ type: FETCH_PROPCOMS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetching:false} });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};
//******************
//fetch individual  FinancerPropResi
export const fetchFinancerPropComs = (id) => async dispatch => {
  try {
    const res = await axios.post(`/api/FinancerPropComs`,{id});
    
    dispatch({ type: FETCH_PROPCOMS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetching:false} });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try AgainFinancerPropResis");
  }
};
//fetch individual  FinancerPropResi
export const fetchFinancerPropComsAd = () => async dispatch => {
  try {
    const res = await axios.post(`/api/FinancerPropComsAd`);
    
    dispatch({ type: FETCH_PROPCOMS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetching:false} });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try AgainFinancerPropResis");
  }
};


//******************
//search  PropComs SALE

export const searchPropComSales = (
    values,
    limitCS,
    offsetCS,
    limitCSF,
    offsetCSF,
    limitStaticCS,
    offsetStaticCS,
) => async dispatch => {
  
  try {
    const res = await axios.post("/api/PropComSaleSearch", {
      values,
      limitCS,
      offsetCS,
      limitCSF,
      offsetCSF,
      limitStaticCS,
      offsetStaticCS,
    });

   
    dispatch({ type: SEARCH_PROPCOMS, payload: res.data });
    dispatch({ type: SEARCH_STATICS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetchingCS:false} });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again searchMyPropComs");
  }
};

//search  PropComs RENT

export const searchPropComRents = (
    values,
    limitCR,
    offsetCR,
    limitStaticCR,
    offsetStaticCR,
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/PropComRentSearch", {
        values,
        limitCR,
        offsetCR,
        limitStaticCR,
        offsetStaticCR,
    });

   
    dispatch({ type: SEARCH_PROPCOMS, payload: res.data });
    dispatch({ type: SEARCH_STATICS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetchingCR:false} });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again searchMyPropComs");
  }
};
//fetchShortlistComSale
export const fetchShortlistComSale = ( limitCS, offsetCSSH) => async dispatch => {
  try {
    const res = await axios.post(`/api/ShortlistComSale`, {
      limitCS,
      offsetCSSH, 
    });
    
    dispatch({ type: FETCH_PROPCOMS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetching:false} });
  } catch (error) {
        if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
//fetchShortlistComRent 
export const fetchShortlistComRent = ( limitCR, offsetCRSH) => async dispatch => {
  try {
    const res = await axios.post(`/api/ShortlistComRent`, {
      limitCR,
      offsetCRSH,
    });
   
    dispatch({ type: FETCH_PROPCOMS, payload: res.data });
    dispatch({ type: FETCHING_PROPCOMS, payload: {fetching:false} });
  } catch (error) {
        if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
//fetch single PropCom
export const fetchPropCom = id => async dispatch => {
  try {
    const res = await axios.get(`/api/PropComDetail/${id}`);
    
    dispatch({ type: FETCH_PROPCOM, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//#####################################################


//update updatePropComForAddImagePath
export const updatePropComForAddImagePath = (id, values) => async dispatch => {
  try {
    const res = await axios.put(`/api/PropComEditForAddImagePath/${id}`, values);
    

    dispatch({ type: UPDATE_PROPCOM, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


// create PropCom
export const createPropCom = (values, history) => async dispatch => {
  try {

    const res = await axios.post("/api/PropComCreate", {
      values
    });
   
  dispatch({ type: MESSAGE, payload: res.data });
  history.push('/');
  dispatch(openModal('MessageModal'));

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//resetOffset

export const resetOffsetPropComs = () => async dispatch => {
  
  try {

    dispatch({ type: SEARCH_PROPCOMS, payload: {offsetCS:0 , offsetCR:0 , offsetCF:0} });
    dispatch({ type: SEARCH_STATICS, payload: {offsetStaticCS:0 , offsetStaticCR:0}, offsetStaticDealerCS:0, offsetStaticDealerCR:0 });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};