import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, Route,  } from "react-router-dom";
import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";
import {fetchAllProjectRs} from '../../actions/projectRAction';
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import ReqResiCreate from "./ReqResiCreate";

import ReqResiDetail from "./ReqResiDetail";


import ReqResiSearchList from "./ReqResiSearchList";
import ReqResiUnlockedList from "./ReqResiUnlockedList";
import MyReqResiList from "./MyReqResiList";

import Layout from "../layout/Layout";

class ReqResiDashboard extends Component {
  componentDidMount() {
    if(this.props.auth.isAuthenticated){
      this.props.getUserForAuth();
    }
    if(this.props.history.location.pathname !=='/'){
          this.props.showBackButton()
        }
        if(this.props.state.projectRs.projectRsForData.length <1){
          this.props.fetchAllProjectRs();
          
        }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };
  render() {
    return (
    <div className="mt-10">
    <div className='maindiv'>

      <Layout onLogoutClick={this.onLogoutClick}>

          <Switch>
            <Route
              exact
              path="/reqresi/ReqResiCreate"
              component={ReqResiCreate}
            />

            <Route
              exact
              path="/reqresi/ReqResiDetail/:id"
              component={ReqResiDetail}
            />
            

            <Route
              exact
              path="/reqresi/ReqResiUnlockedList"
              component={ReqResiUnlockedList}
            />
            <Route
              exact
              path="/reqresi/ReqResiSearchList"
              component={ReqResiSearchList}
            />
            <Route
              exact
              path="/reqresi/MyReqResiList"
              component={MyReqResiList}
            />

          </Switch>
          </Layout >
          </div>

      <div >
          <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

        <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
      </div>

      </div>
        );

  }
  }

const mapStateToProps = state => ({
auth: state.auth,
state
});

export default connect(
   mapStateToProps,
 
  {showBackButton, getUserForAuth, logoutUser,fetchAllProjectRs  }
)(ReqResiDashboard);
