import React, { Component } from "react";
import { withRouter  } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import writtenNumber from "written-number";

import {fetchMyPropResis} from "../../actions/propresiAction";
import { openModal } from "../../actions/modalAction";

import {
  Icon,
} from "semantic-ui-react";




class MyPropResiList extends Component {
  state = {
    id:'',
  };
  componentDidMount() {

		if (this.props.auth.isAuthenticated) {

		this.props.fetchMyPropResis();
		}else{

			this.props.openModal('LoginModal')
		}

  }
  
//**************** */

	handleOpenModalImage = (id,imageOf) => {   
	this.props.openModal("ShowImagesModal" , {id:id,imageOf:imageOf});
	};
	//^^^^^^^^^^^^^^^^^^
	handleOpenModalVideo = (id,imageOf) => {   
	this.props.openModal("ShowVideoModal" , {id:id,imageOf:imageOf});
	};
//*******************************************************
capitalizeFirst=(input)=> {
  var words = input.split(' ');
  var CapitalizedWords = [];
  words.forEach(element => {
    CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
  });
  return CapitalizedWords.join(' ');
}

//*************************************** */

  renderList=(propresi)=> {
    const {
      //imagePath,
				name,
				phone1,
				phone2,
				email,
				whatsapp,
				propertyFor,
				propertyType,
				bedroom,
				bathroom,
				balcony,
				additionalRooms,
				furnishedStatus,
				aproxValueOfWork,
				expectedPrice,
				// occupancyStatus,
				// freehold,
				facing,
				entry,
				Overlooking,
				carParkingCovered,
				carParkingOpen,
				floorNo,
				totalFloors,
				//projectType,
				projectName,
				//projectId,
				address,
				locality,
				locationOnMap,
				description,
				createdAt,
				//furnishing details
				interior,
				wardrobes,
				ac,
				fan,
				bed,
				diningTable,
				sofa,
				curtains,
				tv,
				geyser,
				bathtub,
				modularKitchen,
				refrigerator,
				chimney,
				iglGasConnection,
				hob,
				microwave,
				ro,
				washingMachine,
				others,
				expectedRent,
				securityDeposit,
				maintenanceCharges,
				unitMaintenanceCharges,
				otherCharges,
				unitOtherCharges,
				//availableFrom,
				willingToRentOutTo,
				notWillingToRentOutTo,
				//tenantsProfession,
				tenantsWhoAreNonVegetarians,
				tenantsWithPets,
				companyLease,
				agreement,
				//
				brokerResponse,
				typeOfOwnership,
				loanOfferedBy,
				status,
				possession,
				category,
				area,
				areaUnit,
				carpetArea,
				advertisedBy,
				liftsInTheTower,
				//
				_id

    } = propresi;


	
    const renderadditionalRooms = () => {
      return additionalRooms.map(item => {
        return (
			<span key={item}>
			{" "}
			{item} <span className='pipe'>|</span>
			</span>
			);
      });
    };

//**********************
const poss = (Date.parse(possession) < Date.now())? "Immediate":<span>{ possession && format(new Date(possession), "dd-MM-yyyy")}</span>
//***********************

   
    return (
<div key={_id}>
	{/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > {bedroom} BHK Residential Property For {propertyFor} in {projectName}</span>
	</div>

	</div>

	<div className='card-attached border-radius-bottom border-sb pxy-20 mb-010'  >

{/*  */}
<a target='_blank'  rel="noreferrer noopener" href={`${locationOnMap}`} >
<span
className="icon-button"
>
<img className= 'yimg ' src='/mapIcon.png' alt="Location on Map" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
</span>
</a>
{/*  */} 
		<span
 onClick={()=>this.handleOpenModalImage(_id,'Property')}
 className="icon-button"
 >
 <img className= 'yimg ' src='/imageAddIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
 <span
 onClick={()=>this.handleOpenModalVideo(_id,'Property')} 
 className="icon-button"
 >
 <img className= 'yimg ' src='/videoViewIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
	


<br />
<br />
<div>
</div>

{/*  */}
<div className='border-bottom heading' >
    <span  > Owner Contact Detail </span>
    </div>
	
	<div >
	<div >
	{name && (
	<div className='row'>
	<div className='side'>
	<b>NAME</b>
	</div>
	<div className='main'>
	<b>{name}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{phone1 && (
	<div className='row'>
	<div className='side'>
	<b>PHONE1</b>
	</div>
	<div className='main'>
	<b>{phone1}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{phone2 && (
	<div className='row'>
	<div className='side'>
	<b>PHONE2</b>
	</div>
	<div className='main'>
	<b>{phone2}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{email && (
	<div className='row'>
	<div className='side'>
	<b>EMAIL</b>
	</div>
	<div className='main'>
	<b>{email}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{whatsapp && (
	<div className='row'>
	<div className='side'>
	<b>WHATSAPP ID</b>
	</div>
	<div className='main'>
	<b>{whatsapp}</b>
	</div>

	</div>
	)}
	</div>

	</div>
	{/*  */}
	{/*  */}
<div className='border-bottom'  style= {{marginTop:'10px',marginBottom:'10px',}}>
	<div  className="heading"  >
	<span >
	Property Detail 
	</span>
	</div>
	</div>
	{/*  */}
	<div >
	{propertyFor === 'SALE' && expectedPrice > 0 && ( 
	<div className='row'>
	<div className='side'>
	<b>Price</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {expectedPrice}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(expectedPrice, {
	lang: "enIndian"
	}))}
	){" "}
	</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{propertyFor !== 'SALE' && expectedRent > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Rent</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {expectedRent}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(expectedRent, {
	lang: "enIndian"
	}))}
	){" "}

	</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{propertyFor !== 'SALE' && securityDeposit > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Security Deposit</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {securityDeposit}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(securityDeposit, {
	lang: "enIndian"
	}))}
	){" "}

	</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{propertyFor !== 'SALE' && maintenanceCharges > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Maintenance Charges</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {maintenanceCharges}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(maintenanceCharges, {
	lang: "enIndian"
	}))}
	){" "}

	</b>
	{propertyFor !== 'SALE' && unitMaintenanceCharges && (


	<b>{unitMaintenanceCharges}</b>

	)}
	</div>

	</div>
	)}
	</div>

	<div >
	{propertyFor !== 'SALE' && otherCharges > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Other Charges</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {otherCharges}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(otherCharges, {
	lang: "enIndian"
	}))}
	){" "}

	</b>
	{propertyFor !== 'SALE' && unitOtherCharges && (


	<b>{unitOtherCharges}</b>

	)}
	</div>

	</div>
	)}
	</div>
	<div >
	{propertyFor && (
	<div className='row'>
	<div className='side'>
	<b>Property For</b>
	</div>
	<div className='main'>
	<b>{propertyFor}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{propertyType && (
	<div className='row'>
	<div className='side'>
	<b>Type Of Property</b>
	</div>
	<div className='main'>
	<b>{propertyType}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{category && (
	<div className='row'>
	<div className='side'>
	<b>Category</b>
	</div>
	<div className='main'>
	<b>{category}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{status && (
	<div className='row'>
	<div className='side'>
	<b>Status</b>
	</div>
	<div className='main'>
	<b>{status}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{possession && (
	<div className='row'>
	<div className='side'>
	<b>Possession</b>
	</div>
	<div className='main'>

	<b>{poss}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{carpetArea > 0 &&(
	<div className='row'>
	<div className='side'>
	<b>Carpet Area</b>
	</div>
	<div className='main'>
	<b>{carpetArea}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{area > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Area</b>
	</div>
	<div className='main'>
	<b>{area}{' '}{areaUnit} </b>
	</div>

	</div>
	)}
	</div>


	<div >
	{bedroom && (
	<div className='row'>
	<div className='side'>
	<b>Bedrooms</b>
	</div>
	<div className='main'>
	<b>{bedroom}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{bathroom && (
	<div className='row'>
	<div className='side'>
	<b>Bathrooms</b>
	</div>
	<div className='main'>
	<b>{bathroom}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{balcony && (
	<div className='row'>
	<div className='side'>
	<b>Balconys</b>
	</div>
	<div className='main'>
	<b>{balcony}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{additionalRooms &&
	additionalRooms.length > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Additional Rooms</b>
	</div>
	<div className='main'>
	<b>{renderadditionalRooms()}</b>

	</div>

	</div>
	)}
	</div>

	{/* furnishing details  START*/}
	<div >
	{furnishedStatus && (
	<div className='row'>
	<div className='side'>
	<b>Furnished Status</b>
	</div>
	<div className='main'>
	<b>{furnishedStatus}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{aproxValueOfWork > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Value Of Work</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b>{aproxValueOfWork}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(aproxValueOfWork, {
	lang: "enIndian"
	}))}
	){" "}

	</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{interior && (
	<div className='row'>
	<div className='side'>
	<b>Interior</b>
	</div>
	<div className='main'>
	<b>{interior}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{wardrobes && (
	<div className='row'>
	<div className='side'>
	<b>Wardrobes</b>
	</div>
	<div className='main'>
	<b>{wardrobes}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{ac && (
	<div className='row'>
	<div className='side'>
	<b>AC</b>
	</div>
	<div className='main'>
	<b>{ac}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{fan && (
	<div className='row'>
	<div className='side'>
	<b>Fan</b>
	</div>
	<div className='main'>
	<b>{fan}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{bed && (
	<div className='row'>
	<div className='side'>
	<b>Bed</b>
	</div>
	<div className='main'>
	<b>{bed}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{diningTable && (
	<div className='row'>
	<div className='side'>
	<b>Dining Table</b>
	</div>
	<div className='main'>
	<b>{diningTable}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{sofa && (
	<div className='row'>
	<div className='side'>
	<b>Sofa</b>
	</div>
	<div className='main'>
	<b>{sofa}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{curtains && (
	<div className='row'>
	<div className='side'>
	<b>Curtains</b>
	</div>
	<div className='main'>
	<b>{curtains}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{tv && (
	<div className='row'>
	<div className='side'>
	<b>TV</b>
	</div>
	<div className='main'>
	<b>{tv}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{geyser && (
	<div className='row'>
	<div className='side'>
	<b>Geyser</b>
	</div>
	<div className='main'>
	<b>{geyser}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{bathtub && (
	<div className='row'>
	<div className='side'>
	<b>Bathtub</b>
	</div>
	<div className='main'>
	<b>{bathtub}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{modularKitchen && (
	<div className='row'>
	<div className='side'>
	<b>Modular Kitchen</b>
	</div>
	<div className='main'>
	<b>{modularKitchen}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{chimney && (
	<div className='row'>
	<div className='side'>
	<b>Chimney</b>
	</div>
	<div className='main'>
	<b>{chimney}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{iglGasConnection && (
	<div className='row'>
	<div className='side'>
	<b>Piped Gas</b>
	</div>
	<div className='main'>
	<b>{iglGasConnection}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{hob && (
	<div className='row'>
	<div className='side'>
	<b>Hob</b>
	</div>
	<div className='main'>
	<b>{hob}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{microwave && (
	<div className='row'>
	<div className='side'>
	<b>Microwave</b>
	</div>
	<div className='main'>
	<b>{microwave}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{ro && (
	<div className='row'>
	<div className='side'>
	<b>Water Purifier</b>
	</div>
	<div className='main'>
	<b>{ro}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{washingMachine && (
	<div className='row'>
	<div className='side'>
	<b>Washing Machine</b>
	</div>
	<div className='main'>
	<b>{washingMachine}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{refrigerator && (
	<div className='row'>
	<div className='side'>
	<b>Refrigerator</b>
	</div>
	<div className='main'>
	<b>{refrigerator}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{others && (
	<div className='row'>
	<div className='side'>
	<b>Other Item</b>
	</div>
	<div className='main'>
	<b>{others}</b>
	</div>

	</div>
	)}
	</div>

	{/* furnishing details  END*/}
	{/* other rent details  START*/}
	<div >
	{willingToRentOutTo && (
	<div className='row'>
	<div className='side'>
	<b>Willing To RentOut To</b>
	</div>
	<div className='main'>
	<b>{willingToRentOutTo}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{notWillingToRentOutTo && (
	<div className='row'>
	<div className='side'>
	<b>Not Willing To RentOut To</b>
	</div>
	<div className='main'>
	<b>{notWillingToRentOutTo}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{tenantsWhoAreNonVegetarians && (
	<div className='row'>
	<div className='side'>
	<b>Tenants Who Are NonVegetarians</b>
	</div>
	<div className='main'>
	<b>{tenantsWhoAreNonVegetarians}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{tenantsWithPets && (
	<div className='row'>
	<div className='side'>
	<b>Tenants With Pets</b>
	</div>
	<div className='main'>
	<b>{tenantsWithPets}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{companyLease && (
	<div className='row'>
	<div className='side'>
	<b>Company Lease</b>
	</div>
	<div className='main'>
	<b>{companyLease}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{agreement && (
	<div className='row'>
	<div className='side'>
	<b>Agreement</b>
	</div>
	<div className='main'>
	<b>{agreement}</b>
	</div>

	</div>
	)}
	</div>

	{/* other rent details  END*/}


	<div >
	{entry && (
	<div className='row'>
	<div className='side'>
	<b>Entry</b>
	</div>
	<div className='main'>
	<b>{entry}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{facing && (
	<div className='row'>
	<div className='side'>
	<b>Facing</b>
	</div>
	<div className='main'>
	<b>{facing}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{Overlooking && (
	<div className='row'>
	<div className='side'>
	<b>Overlooking</b>
	</div>
	<div className='main'>
	<b>{Overlooking}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{carParkingCovered && (
	<div className='row'>
	<div className='side'>
	<b>Covered Parking</b>
	</div>
	<div className='main'>
	<b>{carParkingCovered}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{carParkingOpen && (
	<div className='row'>
	<div className='side'>
	<b>Open Parking</b>
	</div>
	<div className='main'>
	<b>{carParkingOpen}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{floorNo && (
	<div className='row'>
	<div className='side'>
	<b>Floor No.</b>
	</div>
	<div className='main'>
	<b>{floorNo}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{totalFloors && (
	<div className='row'>
	<div className='side'>
	<b>Total Floors</b>
	</div>
	<div className='main'>
	<b>{totalFloors}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{liftsInTheTower && (
	<div className='row'>
	<div className='side'>
	<b>Lifts In The Tower</b>
	</div>
	<div className='main'>
	<b>{liftsInTheTower}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{address && (
	<div className='row'>
	<div className='side'>
	<b>Address</b>
	</div>
	<div className='main'>
	<b>{address}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{projectName && (
	<div className='row'>
	<div className='side'>
	<b>Society/Complex</b>
	</div>
	<div className='main'>
	<b>{projectName}</b>
	</div>

	</div>
	)}
	</div>

	<div >
	{locality && (
	<div className='row'>
	<div className='side'>
	<b>Locality</b>
	</div>
	<div className='main'>
	<b>{locality}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{typeOfOwnership && (
	<div className='row'>
	<div className='side'>
	<b>Type Of Ownership</b>
	</div>
	<div className='main'>
	<b>{typeOfOwnership}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{loanOfferedBy && (
	<div className='row'>
	<div className='side'>
	<b>Loan Offered By</b>
	</div>
	<div className='main'>
	<b>{loanOfferedBy}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{advertisedBy && (
	<div className='row'>
	<div className='side'>
	<b>Advertised By</b>
	</div>
	<div className='main'>
	<b>{advertisedBy}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{brokerResponse && (
	<div className='row'>
	<div className='side'>
	<b>Broker Response</b>
	</div>
	<div className='main'>
	<b>{brokerResponse}</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{createdAt && (
	<div className='row'>
	<div className='side'>
	<b>Advertised On</b>
	</div>
	<div className='main'>

	<b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
	</div>

	</div>
	)}
	</div>


	<div >
	{description && (
	<div className='row'>
	<div className='side'>
	<b>About/USP OF Property</b>
	</div>
	<div className='main'>
	<b>{description}</b>
	</div>

	</div>
	)}
	</div>

	</div>

	{/*  */}
	</div>
	);
	}

  //######################################################


  
  renderLoading=()=> {
    if (this.props.fetching ===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }

  renderNoProperty=()=> {
    if (this.props.propresisMy.length===0) {
      return (
		<div className='maindiv-820 card-attached border-radius-top border-db'style={{textAlign:'center',paddingBottom:'100px',paddingTop:'100px'}}>
			<span style={{fontSize: "18px",color: "teal"}}>
				<b>NO PROPERTY AVAILABLE </b>
				</span>

		</div>
		);
    }
  }

  

  render() {

  
	//***** */
    return (
<div>
<div className='maindiv-820'>


<div>

{/**/}
<div className='cashbook-H box-shadow' style={{justifyContent: 'center'}}>


<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
<span> My Residential Property For Sale / Rent In Dwarka Delhi</span>
</div>



</div>
{/**/}


{this.renderLoading()}

{this.renderNoProperty()}
{this.props.propresisMy.map(this.renderList)}




</div>
</div>



<br/><br/><br/><br/>

</div>








    );
  }
}


const mapStateToProps = ({ propresis,auth, images,videos }) => {
  const { fetching, } = propresis



  return {
    fetching,
    //form: form.MyPropResiSaleSearch,
    propresisMy: propresis.propresisMy,
	auth,
    userA: auth.userA,
	imagesPRY:images.imagesPRY,
	videosPRY:videos.videosPRY,

  };
};

export default connect(
  mapStateToProps,
 {fetchMyPropResis,openModal, }
)(withRouter(MyPropResiList));
