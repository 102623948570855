import axios from "axios";
import { toastr } from "react-redux-toastr";
//import { closeModal, openModal } from "./modalAction";

import {
 
  // appPARAMETER types
  FETCH_LOCALITYS
  
} from "./types";



// Locality ACTION
//fetch all Localitys
export const fetchLocalitys = () => async dispatch => {
  try {
    const res = await axios.post(`/api/LocalityList`);
    
    dispatch({ type: FETCH_LOCALITYS, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again@LocalityList");
  }
};

