
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { closeModal,openModal } from "./modalAction";
//import { hashHistory } from 'react-router';
import {
  SEARCH_COMPANYS,
  FETCH_COMPANYS,
  FETCH_COMPANY,
  FETCHING_COMPANYS,
  MESSAGE,
} from "./types";

//search  all dealers

export const searchCompanys = (
  values,
  limit,
  offset
) => async dispatch => {
 
  try {
    const res = await axios.post("/api/companySearch", { values,limit, offset });

    dispatch({ type: SEARCH_COMPANYS, payload: res.data });
    dispatch({ type:FETCHING_COMPANYS, payload: false });
    dispatch(closeModal());
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//search  all financers

export const searchFinancerCompanys = (
  values,
  limit,
  offset
) => async dispatch => {
  
  try {
    const res = await axios.post("/api/companySearchFinancer", { values, limit, offset });

    dispatch({ type: SEARCH_COMPANYS, payload: res.data });
    dispatch({ type:FETCHING_COMPANYS, payload: false });
    dispatch(closeModal());
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//fetch all companies
export const fetchCompanys = () => async dispatch => {
  try {
    const res = await axios.post("/api/companys");

    dispatch({ type: FETCH_COMPANYS, payload: res.data });
  } catch (error) {
   
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch Shortlist companies
export const fetchShortlistCompanys = (
  limit,
  offset,
) => async dispatch => {
  try {
    const res = await axios.post("/api/ShortlistCompanys", { limit,offset,
      values:{} });
   
    dispatch({ type: FETCH_COMPANYS, payload: res.data });
    dispatch({ type:FETCHING_COMPANYS, payload: false });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};

//****************************

//fetch single company
export const fetchCompany = id => async dispatch => {
  try {
    const res = await axios.get(`/api/company/${id}`);
    
    dispatch({ type: FETCH_COMPANY, payload: res.data });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch MY company
export const fetchMyCompany = () => async dispatch => {
  try {
    const res = await axios.get(`/api/myCompany`);
    
    dispatch({ type: FETCH_COMPANY, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};

//update company
export const updateCompany = (id, values,history) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/company/${id}/update`, values);

    if (res.data.msg ==='success') {
      history.push('/company/mycompany');
      toastr.success('Company Updated Successfully');
       }
  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};

// create company
export const createCompany = (values, history) => async dispatch => {
  try {

    const res = await axios.post("/api/company/create", { values });
    
    if(res.data.msg==='errorUserAlready'){
      history.push('/register');
      toastr.error('user ID Already Exists Use Another user ID ');
    }
    if(res.data.msg==='errorCompanyAlready'){
      history.push('/register');
      toastr.error('Company Already Exists Contact Sales Team');
    }
    if(res.data.msg==='errorCidAlready'){
      history.push('/register');
      toastr.error('Company ID Already Exists Use Another');
    }
    if(res.data.userGroup === 'Individual'){
      dispatch(openModal('LoginModal'));
      toastr.success('Registered Successfully Now You Can Login')
    }
    if(res.data.userGroup === "Dealer"){
    dispatch({ type: MESSAGE, payload:{message: res.data.msg} });
    history.push('/');
    dispatch(openModal('MessageModal'));
    }
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

//#######################################################
//to update  company ranking
export const updateCompanyRanking = id => async () => {
  try {
    const res = await axios.post(`/api/UpdateCompanyRanking`,{id:id});
    switch (res.data.msg) {
    case 'errorAlready':
    toastr.error('This Dealer Already Added to List');
    break;
    case 'success':
    toastr.success('Ranking Successfully Updated');
    break;
    default:
      toastr.success(' ');
  }
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//#######################################################

//resetOffset

export const resetOffsetCompanys = () => async dispatch => {
  
  try {
        
    dispatch({ type: SEARCH_COMPANYS, payload: {offset:0} });
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};