import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
//import createHistory from 'history/createBrowserHistory'
import { createBrowserHistory } from "history";
//import 'semantic-ui-css/semantic.min.css';

import store from "../store";

//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import setAuthToken from "../utils/setAuthToken";
import { setCurrentUser, logoutUser} from "../actions/authAction";

import PassChange from "../components/auth/PassChange";
import ReqForOTP from "../components/auth/ReqForOTP";
import PassReset from "../components/auth/PassReset";
//*********************

import Navbar from "./layout/Navbar";

//import Landing from "../components/landing/";
import Landing from "../components/landing/Home";

//modal imports
import ModalManager from "./modals/ModalManager";
//popup imports
import PopupManager from "./popup/PopupManager";
// PropertySale imports PropResi
import PropResiDashboard from "./propertyResi/PropResiDashboard";
import PropComDashboard from "./propertyCom/PropComDashboard";

//require imports
import ReqResiDashboard from "./requireResi/ReqResiDashboard";
import ReqComDashboard from "./requireCom/ReqComDashboard";
import ProjectRDashboard from "./projectR/ProjectRDashboard";
import ProjectCDashboard from "./projectC/ProjectCDashboard";
import ActivityDashboard from "./activity/ActivityDashboard";
import StaticDashboard from "./static/StaticDashboard";

import CompanyDashboard from "./companys/CompanyDashboard";
import BuilderDashboard from "./builder/BuilderDashboard";
import DownloadDashboard from "./download/DownloadDashboard";
import CompanyCreate from "./companys/CompanyCreate";


// from devconnector******************************************************
// Check for token

  if (localStorage.jwtToken){
  //console.log("localStorageApp:", localStorage);
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear current Profile
    //  store.dispatch(clearCurrentProfile());
    // Redirect to login
   // window.location.href = "/login";
  }
}
//***********************************************************
//const history = createHistory()
const history = createBrowserHistory()

class App extends Component {
  render() {
    return (
      <div>
        <Router history={history}>
          <div>
            <ModalManager />
            <PopupManager />
            <div>
              <Navbar />
              <Switch>

              <Route exact path="/" component={Landing} />

              <Route exact path="/formpassreset" component={ReqForOTP} />
              <Route exact path="/register" component={CompanyCreate} />
              <Route exact path="/passchange" component={PassChange} />

              <Route exact path="/passreset" component={PassReset} />

              
              <Route path="/company" component={CompanyDashboard} />
              <Route path="/builder" component={BuilderDashboard} />
              <Route path="/propresi" component={PropResiDashboard} />
              <Route path="/propcom" component={PropComDashboard} />
              <Route path="/reqresi" component={ReqResiDashboard} />
              <Route path="/reqcom" component={ReqComDashboard} />
              <Route path="/projectR" component={ProjectRDashboard} />
              <Route path="/projectC" component={ProjectCDashboard} />
              <Route path="/static" component={StaticDashboard} />
              <Route path="/activity" component={ActivityDashboard} />
              <Route path="/download" component={DownloadDashboard} />
              </Switch>
            </div>

          </div>
        </Router>
      </div>
    );
  }
}

export default App;
