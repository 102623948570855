import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import writtenNumber from 'written-number';
import {capitalizeFirst} from "../functions/Functions";
import {searchBuilderFloorSales} from '../../actions/propresiAction';
import { fetchCompanysForPropListRSBF } from "../../actions/staticAction";
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,   } from 'semantic-ui-react';
import NumberInput from "../form/NumberInput";
import SelectInput from '../form/SelectInput';
import DateInput from '../form/DateInput';
//import { projectName } from './DataProjectName';
import {
 // propertyType,
  bedroom,
  bathroom,
  additionalRooms,
  furnishedStatus,
  advertisedByForSearch,
  floorNo,
  locality,
  sorting
} from './Data';
import {
  carParkingCovered,
  carParkingOpen,
   entry,
} from './DataS';

const BuilderFloorSaleSearch = props => {
  const { maxPrice, minPrice } =
    props.state.form &&
    props.state.form.BuilderFloorSaleSearch !== undefined &&
    props.state.form.BuilderFloorSaleSearch.values !== undefined &&
    props.state.form.BuilderFloorSaleSearch.values;
  const { handleSubmit,  reset, submitting } = props;

  const onSubmit = values => {
    const offsetRSBF = 0
    const offsetRSBFF = 0
    const offsetStaticRSBF = 0
    const offsetStaticDealerRSBF = 0
    const {
      limitRSBF,
      limitRSBFF,
      limitStaticRSBF,
      limitStaticDealerRSBF,
  } = props.state.propresis;
  
  props.searchBuilderFloorSales(values, limitRSBF, offsetRSBF ,  limitRSBFF, offsetRSBFF , limitStaticRSBF, offsetStaticRSBF );
  props.fetchCompanysForPropListRSBF(limitStaticDealerRSBF,offsetStaticDealerRSBF )
    
  };

  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Builder Floor Available for Sale
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
 #  Leave All Field Blank (Clear Values) to View All Available Properties 
           <br /><br />
<Button
              color="teal"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
          <br /><br />
          <label >Sort By</label>
          <Field
            //search={true}
            name="sorting"
            type="text"
            component={SelectInput}
            options={sorting()}
            placeholder="Sort By"
          />

{/*
            <label >Property Type</label>
            <Field
              name="propertyTypeA"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />  */}
            <label >Bedroom</label>
            <Field
              name="bedroomA"
              placeholder="Bedroom"
              type="text"
              options={bedroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Bathroom</label>
            <Field
              name="bathroomA"
              placeholder="Bathroom"
              type="text"
              options={bathroom()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Additional Rooms</label>
            <Field
              name="additionalRoomsA"
              placeholder="Additional Rooms"
              type="text"
              options={additionalRooms()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Furnished Status</label>
            <Field
              name="furnishedStatusA"
              placeholder="Furnished Status"
              type="text"
              options={furnishedStatus()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >
              Minimum Price{' '}
              <span>
                ({minPrice && capitalizeFirst(writtenNumber(minPrice, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label>
            <Field
              name="minPrice"
              placeholder="Minimum Price"
              type="number"
              component={NumberInput}
            />
            <label >
              Maximum Price{' '}
              <span>
                ({maxPrice && capitalizeFirst(writtenNumber(maxPrice, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label>
            <Field
              name="maxPrice"
              placeholder="Maximum Price"
              type="number"
              component={NumberInput}
            />


            <label >Entry</label>
            <Field
              name="entryA"
              placeholder="Entry"
              type="text"
              options={entry()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Covered Car Parking</label>
            <Field
              name="carParkingCoveredA"
              placeholder="Covered Car Parking"
              type="text"
              options={carParkingCovered()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Open Car Parking</label>
            <Field
              name="carParkingOpenA"
              placeholder="Open Car Parking"
              type="text"
              options={carParkingOpen()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Floor No</label>
            <Field
              name="floorNoA"
              placeholder="Floor No"
              type="text"
              options={floorNo()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
{/*
            <label >Society/Complex Name</label>
            <Field
              name="projectNameA"
              placeholder="Society/Complex Name"
              type="text"
              options={projectName()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />  */}

            <label >Locality</label>
            <Field
              name="localityA"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >Property Created FROM</label>
            <Field
              name="from"
              type="text"
              component={DateInput}
              dateFormat="DD-MM-YYYY "
              placeholder="Property Created FROM"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            <label >Property Created TO</label>
            <Field
              name="to"
              type="text"
              component={DateInput}
              dateFormat="DD-MM-YYYY "
              placeholder="Property Created TO"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
<label >Advertised By</label>
            <Field
              name="advertisedBy"
              placeholder="Advertised By"
              type="text"
              options={advertisedByForSearch()}
              //search={true}
              //multiple={true}
              component={SelectInput}
            />

            <br />

            <Button
              color="blue"
              type="submit"
              disabled={ submitting}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/><br/><br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA,
  };
};
export default connect(
  mapStateToProps,
   {searchBuilderFloorSales,closeModal ,fetchCompanysForPropListRSBF }
)(
  reduxForm({
    form: 'BuilderFloorSaleSearch', // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(BuilderFloorSaleSearch)
);
