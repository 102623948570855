import {
  // appPARAMETER types
  
  FETCH_LOCALITYS
} from '../actions/types';

const INITIAL_STATE = {
  
  localitys: [],
  // countC,
  // countL,
  // countR,
  offset: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    
      case FETCH_LOCALITYS:
      return { ...state, ...action.payload };
      
      default:
      return state;
  }
};
