import React from 'react';
import { withRouter, } from "react-router-dom";
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
//import Script from 'react-load-script';

import { resetOffsetCompanys } from '../../actions/companysAction';
import { closeModal } from '../../actions/modalAction';

import { 
  Form,
  Button,
  Popup,
} from 'semantic-ui-react';
// import TextInput from '../form/TextInput';
// import TextArea from '../form/TextArea';
import SelectInput from '../form/SelectInput';


import { city,  typeOfProp, typeOfDealings,locality, } from './Data';
const CompanySearch = props => {
  const { handleSubmit,  reset, submitting,  } = props;

  const onSubmit = () => {
    props.resetOffsetCompanys();
    props.closeModal()
    props.history.push(`/company/DealerSearchList`);
  };
  return (
    <div style={{maxWidth:'700px',margin:'auto'}}>

      {/**/}
      <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Dealers
          {/*  */}
          </div>
          </div>
          {/**/}
        
          <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
             #  Leave All Field Blank (Clear Values) to View All Available Dealers 
           <br /><br />


            <Button
              color="teal"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <br/><br/>
            <label >Type of Dealings</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search All Available Type of Dealings or
        Enter Single or Multiple Values to Search Those Type of Dealings
            </Popup>
            <Field
              //search={true}
              multiple={true}
              name="typeOfDealings"
              type="text"
              component={SelectInput}
              options={typeOfDealings()}
              placeholder="Type of Dealings"
            />
            <label >Types of properties deals in</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search All Available Types of properties deals in or
        Enter Single or Multiple Values to Search Those Types of properties deals in
            </Popup>
            <Field
              //search={true}
              multiple={true}
              name="typeOfPropertyDealsIn"
              type="text"
              component={SelectInput}
              options={typeOfProp()}
              placeholder="Types of properties deals in"
            />
            <label >Locality</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search Dealers in All Sectors of Dwarka or
        Enter Single or Multiple Values to Search Dealers in Those Sectors
            </Popup>
            <Field
              name="localityA"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />
            <label >City/District</label>
            <Field
              //search={true}
              name="city"
              type="text"
              component={SelectInput}
              options={city()}
              placeholder="City/District"
            />

            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
              // as={Link}
              // to={`/company/DealerSearchList`}
              // onClick={()=>props.closeModal()}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};

export default connect(
  null,
  { resetOffsetCompanys, closeModal }
)(
  reduxForm({
    form: 'CompanySearch', // a unique identifier for this form
    destroyOnUnmount: false

    //validate
  })(withRouter(CompanySearch))
);
