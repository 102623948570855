import React, { Component } from "react";
import { withRouter  } from "react-router-dom";
import { connect } from "react-redux";
import format from "date-fns/format";
import writtenNumber from "written-number";
import {fetchMyPropComs} from "../../actions/propcomAction";
import { openModal } from "../../actions/modalAction";
import {
  Icon,
} from "semantic-ui-react";


class MyPropComList extends Component {
  state = {
    id:'',
  };
  componentDidMount() {
        
    if (this.props.auth.isAuthenticated) {
     
      this.props.fetchMyPropComs();
    }else{
      
      this.props.openModal('LoginModal')
    }
    
  }
  
  
  //**************** */

    handleOpenModalImage = (id,imageOf) => {   
    this.props.openModal("ShowImagesModal" , {id:id,imageOf:imageOf});
    };
    //^^^^^^^^^^^^^^^^^^
    handleOpenModalVideo = (id,imageOf) => {   
    this.props.openModal("ShowVideoModal" , {id:id,imageOf:imageOf});
    };

  
  //*******************************************************
  capitalizeFirst=(input)=> {
    var words = input.split(' ');
    var CapitalizedWords = [];
    words.forEach(element => {
      CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    });
    return CapitalizedWords.join(' ');
  }
  
  //*******************************************************
  renderList=(propcom)=> {
    const {
      //imagePath,
				name,
				phone1,
				phone2,
				email,
				whatsapp,
				propertyFor,
				propertyType,
				bedroom,
				bathroom,
			//	store,
				furnishedStatus,
				aproxValueOfWork,
				expectedPrice,
				// occupancyStatus,
				// freehold,
				facing,
				entry,
				Overlooking,

				floorNo,
				totalFloors,
			//	projectType,
				projectName,
				//projectId,
				address,
				locality,
				locationOnMap,
				description,
				createdAt,
				//furnishing details
				interior,
				ac,
        fan,
        others,
        washroomCommon,
        washroomPersonal,
        pantryPersonal ,

        //powerBackUp,
        carpetArea,
        length,
        breadth,
        parking,
        reserveParking,
        visitorParking,

				expectedRent,
        securityDeposit,
        maintenanceCharges,
        unitMaintenanceCharges,
        otherCharges,
        unitOtherCharges,
				companyLease,
				agreement,
				//
				brokerResponse,
				typeOfOwnership,
				loanOfferedBy,
				status,
				possession,
				category,
				area,
				areaUnit,
				advertisedBy,
				liftsInTheTower,
				//
				_id

    } = propcom;

//**********************
const poss = (Date.parse(possession) < Date.now())? "Immediate":<span>{ possession && format(new Date(possession), "dd-MM-yyyy")}</span>
//***********************

    return (
<div key={_id}>
{/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2'>
<span  > {carpetArea} sq ft Commercial Property For {propertyFor} in {projectName}</span>
</div>

</div>

<div className='card-attached border-radius-bottom border-sb pxy-20 mb-010'  >

{/*  */}
	<a target='_blank'  rel="noreferrer noopener" href={`${locationOnMap}`} >
	<span
         className="icon-button"
        >
        <img className= 'yimg ' src='/mapIcon.png' alt="Location on Map" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
		</a>
		{/*  */}
		<span
 onClick={()=>this.handleOpenModalImage(_id,'Property')}
 className="icon-button"
 >
 <img className= 'yimg ' src='/imageAddIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
 <span
 onClick={()=>this.handleOpenModalVideo(_id,'Property')}  
 className="icon-button"
 >
 <img className= 'yimg ' src='/videoViewIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
 {/*  */}
	


<br />
<br />
<div>
</div>

{/*  */}
<div className='border-bottom heading' >
    <span  > Owner Contact Detail </span>
    </div>
    <div >
       <div >
       {name && (
         <div className='row'>
           <div className='side'>
             <b>NAME</b>
           </div>
           <div className='main'>
             <b>{name}</b>
           </div>

         </div>
       )}
     </div>

     <div >
     {phone1 && (
       <div className='row'>
         <div className='side'>
           <b>PHONE1</b>
         </div>
         <div className='main'>
           <b>{phone1}</b>
         </div>

       </div>
     )}
   </div>

   <div >
   {phone2 && (
     <div className='row'>
       <div className='side'>
         <b>PHONE2</b>
       </div>
       <div className='main'>
         <b>{phone2}</b>
       </div>

     </div>
   )}
 </div>

 <div >
 {email && (
   <div className='row'>
     <div className='side'>
       <b>EMAIL</b>
     </div>
     <div className='main'>
       <b>{email}</b>
     </div>

   </div>
 )}
</div>

<div >
{whatsapp && (
 <div className='row'>
   <div className='side'>
     <b>WHATSAPP ID</b>
   </div>
   <div className='main'>
     <b>{whatsapp}</b>
   </div>

 </div>
)}
</div>

</div>
{/*  */}
{/*  */}
<div className='border-bottom'  style= {{marginTop:'10px',marginBottom:'10px',}}>
	<div  className="heading"  >
	<span >
	Property Detail 
	</span>
	</div>
	</div>
	{/*  */}
  <div >
	{propertyFor === 'SALE' && expectedPrice > 0 && ( 
	<div className='row'>
	<div className='side'>
	<b>Price</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {expectedPrice}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(expectedPrice, {
	lang: "enIndian"
	}))}
	){" "}
	</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{propertyFor !== 'SALE' && expectedRent > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Rent</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {expectedRent}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(expectedRent, {
	lang: "enIndian"
	}))}
	){" "}

	</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{propertyFor !== 'SALE' && securityDeposit > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Security Deposit</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {securityDeposit}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(securityDeposit, {
	lang: "enIndian"
	}))}
	){" "}

	</b>
	</div>

	</div>
	)}
	</div>
	<div >
	{propertyFor !== 'SALE' && maintenanceCharges > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Maintenance Charges</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {maintenanceCharges}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(maintenanceCharges, {
	lang: "enIndian"
	}))}
	){" "}

	</b>
	{propertyFor !== 'SALE' && unitMaintenanceCharges && (


	<b>{unitMaintenanceCharges}</b>

	)}
	</div>

	</div>
	)}
	</div>

	<div >
	{propertyFor !== 'SALE' && otherCharges > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Other Charges</b>
	</div>
	<div className='main'>
	<Icon className="rupee" ></Icon>
	<b> {otherCharges}</b>
	<b>
	{' '} ({this.capitalizeFirst(writtenNumber(otherCharges, {
	lang: "enIndian"
	}))}
	){" "}

	</b>
	{propertyFor !== 'SALE' && unitOtherCharges && (


	<b>{unitOtherCharges}</b>

	)}
	</div>

	</div>
	)}
	</div>
	<div >
	{propertyFor && (
	<div className='row'>
	<div className='side'>
	<b>Property For</b>
	</div>
	<div className='main'>
	<b>{propertyFor}</b>
	</div>

	</div>
	)}
	</div>


<div >
{propertyType && (
  <div className='row'>
  <div className='side'>
    <b>Type Of Property</b>
  </div>
  <div className='main'>
    <b>{propertyType}</b>
  </div>

  </div>
)}
</div>

<div >
{category && (
  <div className='row'>
  <div className='side'>
    <b>Category</b>
  </div>
  <div className='main'>
    <b>{category}</b>
  </div>

  </div>
)}
</div>

<div >
{status && (
  <div className='row'>
  <div className='side'>
    <b>Status</b>
  </div>
  <div className='main'>
    <b>{status}</b>
  </div>

  </div>
)}
</div>

<div >
	{possession && (
	<div className='row'>
	<div className='side'>
	<b>Possession</b>
	</div>
	<div className='main'>

	<b>{poss}</b>
	</div>

	</div>
	)}
	</div>

  <div >
	{area > 0 && (
	<div className='row'>
	<div className='side'>
	<b>Area</b>
	</div>
	<div className='main'>
	<b>{area}{' '}{areaUnit} </b>
	</div>

	</div>
	)}
	</div>

<div >
{bedroom && (
  <div className='row'>
  <div className='side'>
    <b>Rooms</b>
  </div>
  <div className='main'>
    <b>{bedroom}</b>
  </div>

  </div>
)}
</div>

<div >
{bathroom && (
  <div className='row'>
  <div className='side'>
    <b>Washrooms</b>
  </div>
  <div className='main'>
    <b>{bathroom}</b>
  </div>

  </div>
)}
</div>

<div >
{carpetArea > 0 && (
  <div className='row'>
  <div className='side'>
    <b>Carpet Area </b>
  </div>
  <div className='main'>
    <b>{carpetArea} Sq Ft</b>
  </div>

  </div>
)}
</div>

<div >
{length > 0 && (
  <div className='row'>
  <div className='side'>
    <b>Length </b>
  </div>
  <div className='main'>
    <b>{length} Ft</b>
  </div>

  </div>
)}
</div>

<div >
{breadth > 0 && (
  <div className='row'>
  <div className='side'>
    <b>Breadth </b>
  </div>
  <div className='main'>
    <b>{breadth} Ft</b>
  </div>

  </div>
)}
</div>



{/* furnishing details  START*/}
<div >
{furnishedStatus && (
  <div className='row'>
  <div className='side'>
    <b>Furnished Status</b>
  </div>
  <div className='main'>
    <b>{furnishedStatus}</b>
  </div>

  </div>
)}
</div>

<div >
{aproxValueOfWork > 0 && (
  <div className='row'>
  <div className='side'>
    <b>Value Of Work</b>
  </div>
  <div className='main'>
  <Icon className="rupee" ></Icon>
    <b>{aproxValueOfWork}</b>
    <b>
    {' '} ({this.capitalizeFirst(writtenNumber(aproxValueOfWork, {
      lang: "enIndian"
    }))}
    ){" "}

    </b>
  </div>

  </div>
)}
</div>

<div >
{interior && (
  <div className='row'>
  <div className='side'>
    <b>Interior</b>
  </div>
  <div className='main'>
    <b>{interior}</b>
  </div>

  </div>
)}
</div>


<div >
{ac && (
<div className='row'>
<div className='side'>
  <b>AC</b>
</div>
<div className='main'>
  <b>{ac}</b>
</div>

</div>
)}
</div>
<div >
{fan && (
<div className='row'>
<div className='side'>
<b>Fan</b>
</div>
<div className='main'>
<b>{fan}</b>
</div>

</div>
)}
</div>
<div >
{others && (
<div className='row'>
<div className='side'>
<b>Other Item</b>
</div>
<div className='main'>
<b>{others}</b>
</div>

</div>
)}
</div>

<div >
{washroomCommon && (
<div className='row'>
<div className='side'>
<b>Common Washroom</b>
</div>
<div className='main'>
<b>{washroomCommon}</b>
</div>

</div>
)}
</div>

<div >
{washroomPersonal && (
<div className='row'>
<div className='side'>
<b>Personal Washroom</b>
</div>
<div className='main'>
<b>{washroomPersonal}</b>
</div>

</div>
)}
</div>
<div >
{pantryPersonal && (
<div className='row'>
<div className='side'>
<b>Personal Pantry</b>
</div>
<div className='main'>
<b>{pantryPersonal}</b>
</div>

</div>
)}
</div>




{/* furnishing details  END*/}
{/* other rent details  START*/}

<div >
{companyLease && (
<div className='row'>
<div className='side'>
<b>Company Lease</b>
</div>
<div className='main'>
<b>{companyLease}</b>
</div>

</div>
)}
</div>
<div >
{agreement && (
<div className='row'>
<div className='side'>
<b>Agreement</b>
</div>
<div className='main'>
<b>{agreement}</b>
</div>

</div>
)}
</div>

{/* other rent details  END*/}


<div >
{entry && (
  <div className='row'>
  <div className='side'>
    <b>Entry</b>
  </div>
  <div className='main'>
    <b>{entry}</b>
  </div>

  </div>
)}
</div>
<div >
{facing && (
  <div className='row'>
  <div className='side'>
    <b>Facing</b>
  </div>
  <div className='main'>
    <b>{facing}</b>
  </div>

  </div>
)}
</div>
<div >
{Overlooking && (
  <div className='row'>
  <div className='side'>
    <b>Overlooking</b>
  </div>
  <div className='main'>
    <b>{Overlooking}</b>
  </div>

  </div>
)}
</div>

<div >
{parking && (
  <div className='row'>
  <div className='side'>
    <b>Parking</b>
  </div>
  <div className='main'>
    <b>{parking}</b>
  </div>

  </div>
)}
</div>
<div >
{reserveParking && (
  <div className='row'>
  <div className='side'>
    <b>Reserve Parking</b>
  </div>
  <div className='main'>
    <b>{reserveParking}</b>
  </div>

  </div>
)}
</div>
<div >
{visitorParking && (
  <div className='row'>
  <div className='side'>
    <b>Visitor Parking</b>
  </div>
  <div className='main'>
    <b>{visitorParking}</b>
  </div>

  </div>
)}
</div>

<div >
{floorNo && (
  <div className='row'>
  <div className='side'>
    <b>Floor No.</b>
  </div>
  <div className='main'>
    <b>{floorNo}</b>
  </div>

  </div>
)}
</div>
<div >
{totalFloors && (
  <div className='row'>
  <div className='side'>
    <b>Total Floors</b>
  </div>
  <div className='main'>
    <b>{totalFloors}</b>
  </div>

  </div>
)}
</div>
<div >
{liftsInTheTower && (
  <div className='row'>
  <div className='side'>
    <b>Lifts In The Tower</b>
  </div>
  <div className='main'>
    <b>{liftsInTheTower}</b>
  </div>

  </div>
)}
</div>
<div >
{address && (
  <div className='row'>
  <div className='side'>
    <b>Address</b>
  </div>
  <div className='main'>
    <b>{address}</b>
  </div>

  </div>
)}
</div>

<div >
{projectName && (
  <div className='row'>
  <div className='side'>
    <b>Complex/Project Name</b>
  </div>
  <div className='main'>
    <b>{projectName}</b>
  </div>

  </div>
)}
</div>

<div >
{locality && (
  <div className='row'>
  <div className='side'>
    <b>Locality</b>
  </div>
  <div className='main'>
    <b>{locality}</b>
  </div>

  </div>
)}
</div>
<div >
{typeOfOwnership && (
  <div className='row'>
  <div className='side'>
    <b>Type Of Ownership</b>
  </div>
  <div className='main'>
    <b>{typeOfOwnership}</b>
  </div>

  </div>
)}
</div>
<div >
{loanOfferedBy && (
  <div className='row'>
  <div className='side'>
    <b>Loan Offered By</b>
  </div>
  <div className='main'>
    <b>{loanOfferedBy}</b>
  </div>

  </div>
)}
</div>
<div >
{advertisedBy && (
  <div className='row'>
  <div className='side'>
    <b>Advertised By</b>
  </div>
  <div className='main'>
    <b>{advertisedBy}</b>
  </div>

  </div>
)}
</div>
<div >
{brokerResponse && (
  <div className='row'>
  <div className='side'>
    <b>Broker Response</b>
  </div>
  <div className='main'>
    <b>{brokerResponse}</b>
  </div>

  </div>
)}
</div>
<div >
{createdAt && (
  <div className='row'>
  <div className='side'>
    <b>Advertised On</b>
  </div>
  <div className='main'>

    <b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
  </div>

  </div>
)}
</div>


<div >
{description && (
  <div className='row'>
  <div className='side'>
    <b>About/USP OF Property</b>
  </div>
  <div className='main'>
    <b>{description}</b>
  </div>

  </div>
)}
</div>

</div>

{/*  */}
</div>
    );
  }

  
  //###########################################

  
  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }
  renderNoProperty=()=> {
    if (this.props.propcomsMy.length===0) {
      return (
        <div className='maindiv-820 card-attached border-radius-top border-db'style={{textAlign:'center',paddingBottom:'100px',paddingTop:'100px'}}>
          <span style={{fontSize: "18px",color: "teal"}}>
            <b>NO PROPERTY AVAILABLE </b>
            </span>
    
        </div>
        );
    }
  }


  render() {
 
  
	//***** */
    return (
<div>
<div className='maindiv-820'>


<div>

{/**/}
<div className='cashbook-H box-shadow'style={{justifyContent: 'center'}}>


<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
<span>My Commercial Property For Sale / Rent</span>
</div>



</div>
{/**/}
    

{this.renderLoading()}

{this.renderNoProperty()}
{this.props.propcomsMy.map(this.renderList)}



<br/><br/><br/><br/>

</div>



</div>
</div>


    );
  }
}


const mapStateToProps = ({ propcoms,auth, images,videos }) => {
  const { fetching, } = propcoms
  
  

  return {
    fetching,
    propcomsMy: propcoms.propcomsMy,
    auth,
    userA: auth.userA,
    imagesPRY:images.imagesPRY,
    videosPRY:videos.videosPRY,
    
  };
};

export default connect(
  mapStateToProps,
 {fetchMyPropComs,openModal, }
)(withRouter(MyPropComList));
