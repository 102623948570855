import React, { Component } from "react";
import { connect } from "react-redux";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import {fetchShortlistResiSale} from "../../actions/propresiAction";

// import {
//   Icon,
//   Button,
// } from "semantic-ui-react";
import PropResiSummary from './PropResiSummary';
import { openModal } from "../../actions/modalAction";



class ShortlistResiSale extends Component {
  state = {
    pageO:'',
    id:'',
  };
  componentDidMount() {
      const {offsetRSSH,limitRS}=this.props;
    
    if (this.props.auth.isAuthenticated) {
     
      this.props.fetchShortlistResiSale(limitRS, offsetRSSH);
    }else{
      
      this.props.openModal('LoginModal')
    }
  }
  
  // **************
  //^^^^^^^^^^^^^^^^^^^^^^^^^
    onChange=(e) => {
      this.setState({ [e.target.name]: e.target.value });
    }
  //*******************************************************


  renderGoToPageOrNot=()=> {
    if (this.props.propresisRS.length) {
      return (
        <div className='card-attached border-sb '  >
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()} 
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
</div>
      );
    }
  }

  

  back = () => {
    const {
      offsetRSSH,
      limitRS
    } = this.props;
     if (offsetRSSH === 0) {
      return;
    }

    this.props.fetchShortlistResiSale( limitRS, (offsetRSSH - limitRS));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offsetRSSH,
      limitRS,
      countRS
    } = this.props;
     if (offsetRSSH + limitRS > countRS) {
      return;
    }

    this.props.fetchShortlistResiSale( limitRS, (offsetRSSH + limitRS));
    window.scroll(0,0)
  };
//***************** */
handleGoToPage = () => {
  const {
    limitRS,
    countRS
  } = this.props;
// use Math.abs for strict equality ===  to work and safe guard against negative value from user
  if (Math.abs(this.state.pageO) === 0) {
    return; 
  }

const page=(Math.abs(this.state.pageO*limitRS) > countRS)?(Math.ceil(countRS/limitRS-1)):(Math.abs(this.state.pageO)-1)


  this.props.fetchShortlistResiSale( limitRS, (page * limitRS));
  window.scroll(0,0)

};

//****************** */
  renderPaginator=()=> {
    if (this.props.propresisRS.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offsetRSSH}
          limit={this.props.limitRS}
          count={this.props.countRS}
          //values={this.props.values}
        />
      );
    }
  }

  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }

  renderNoProperty=()=> {
    if (this.props.propresisRS.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE </b></span>

        </div>
      );
    }
  }

  render() {
    
    return (
<div>
<div className='maindiv-820'>

<div>



    {/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2'>
<span  > {this.props.countRS} Shortlisted Residential Properties For Sale</span>
</div>
      
</div>

    <div className='card-attached border-sb pxy-05'  >
    {this.renderLoading()}
{this.renderNoProperty()}
{this.props.propresisRS.map((item)=> (<PropResiSummary  key={item._id}   propresi={item}/>))}


    </div>
{/*  */}

{this.renderGoToPageOrNot()}
<br/><br/><br/><br/>

</div>



</div>
</div>


    );
  }
}


const mapStateToProps = ({ propresis,auth,  }) => {
  const { fetching,limitRS, offsetRSSH, countRS } = propresis;

  return {
    auth,
    fetching,
    limitRS,
    offsetRSSH,
    countRS,
    //form: form.MyPropResiSaleSearch,
    propresisRS: propresis.propresisRS,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {fetchShortlistResiSale,openModal}
)(ShortlistResiSale);
