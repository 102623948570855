import React from 'react';
import { Form, Label } from 'semantic-ui-react';

const TextInput = ({
 //field,
  input,
 // value,
 // onChange,
  width,
  type,
  placeholder,
  meta: { touched, error },
  ...props
}) => {
  return (
    <Form.Field error={touched && !!error} width={width}>
      <input
        {...input}
        {...props}  // required for consolidated entry
        placeholder={placeholder}
        type={type}
        className='input'
        //autoComplete="off"  // working
        //value={input.value || null}
        //  onChange={onChange}
      />
      {touched &&
        error && (
          <Label basic color="red">
            {error}
          </Label>
        )}
    </Form.Field>
  );
};

export default TextInput;
