import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "../common/Loading"; 
import {similarRentPropertiesCom} from "../../actions/staticAction";
import PropComSummary from './PropComSummary';



class SimilarPropComRentList extends Component {
  

  componentDidMount() {
    const values = this.props.values;
    this.props.similarRentPropertiesCom(values);
    }
  
    componentDidUpdate(prevProps) {
      //Typical usage, don't forget to compare the props
      if ((this.props.values.carpetAreaMin !== prevProps.values.carpetAreaMin) || (this.props.values.carpetAreaMax !== prevProps.values.carpetAreaMax)) {
        const values = this.props.values;
        this.props.similarRentPropertiesCom(values);
        }
       }
  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }
  renderNoProperty=()=> {
    if (this.props.statics.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE FOR RENT</b></span>

        </div>
      );
    }
  } 


  render() {
    
    return (


<div>


{/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
    
    <div className='heading-2'>
<span  > Similar Commercial Property For Rent</span> 
</div>
      
          </div>

    <div className='card-attached border-sb pxy-05'  >
    {this.renderNoProperty()}
    {this.props.statics.map((item)=> (<PropComSummary   key={item._id}   propcom={item}/>))}
    </div>
    {/*  */}


<br/><br/><br/><br/>
</div>

    );
  }
}



const mapStateToProps = ({ statics,auth,  }) => {
  const { fetching,limit, offset, count } = statics;

  return {
    fetching,
    limit,
    offset,
    count,
    //form: form.PropComRentSearch,
    statics: statics.statics,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {similarRentPropertiesCom,} 
)(SimilarPropComRentList);
