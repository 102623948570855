import React from 'react';
import { Link} from 'react-router-dom';


const LinkItem = (props) => {
const className = `linkFP textAlign-center px-2`
const moreClass = props.class !== undefined ? props.class : '' 
const fn = props.fn !== undefined ? props.fn : null 

  return (
    <div className = {className.concat(moreClass)}
    onClick={fn}
    >
          <Link to={props.url} >
          <b>{props.title}</b>
        </Link>
          </div>
  );
};

export default LinkItem;
