import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, Route, } from "react-router-dom";
import {showBackButton, getUserForAuth,logoutUser } from "../../actions/authAction";
import {fetchAllProjectCs} from '../../actions/projectCAction';
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";

import ProjectCDetail from "./ProjectCDetail";

import AddDealerForProj from "./AddDealerForProj";
import ProjectCSearchList from "./ProjectCSearchList";

import Layout from "../layout/Layout";



class ProjectCDashboard extends Component {
  componentDidMount() {
    if(this.props.auth.isAuthenticated){
      this.props.getUserForAuth();
    }
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
    }
    if(this.props.state.projectCs.projectCsForData.length <1){
      this.props.fetchAllProjectCs();
    }
  }
  onLogoutClick = () => {
    this.props.logoutUser();
  };

  render() {

    return (
    <div className="mt-10">
    <div className='maindiv'>

    <Layout onLogoutClick={this.onLogoutClick}>
    <Switch>
    <Route exact path="/projectC/AddDealerForProj/:id" component={AddDealerForProj} />
    
      
      <Route
        exact
        path="/projectC/ProjectCDetail/:id"
        component={ProjectCDetail}
      />

      

      <Route
        exact
        path="/projectC/ProjectCSearchList"
        component={ProjectCSearchList}
      />

    </Switch>
        </Layout >
        </div>

    <div >
        <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

      <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
    </div>

    </div>
      );


}
}
const mapStateToProps = state => ({
auth: state.auth,
state
});


export default connect(
   mapStateToProps,
  
  {showBackButton, getUserForAuth, logoutUser,fetchAllProjectCs  }
)(ProjectCDashboard);
