import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import './css/animation.css'
import './css/checkbox.css'
import './css/neonButton.css'
import './css/style.css';
import './css/loader.css';
import './css/border.css';
import './css/button.css';
import './css/card.css';
import './css/ribbon.css';
import './css/ribbon2.css';
import './css/common.css';
import './css/color.css';
import './css/container.css';
import './css/display.css';
import './css/grid.css';
import './css/home.css';
import './css/mis.css';
import './css/nav.css';
import './css/sidebar.css';
import './css/image.css';
import './css/dropdown.css';
import './css/summary.css';

import Layout from "../layout/Layout";
import { hideBackButton,getUserForAuth, } from "../../actions/authAction";
import { fetchCompanysForMainPage } from "../../actions/staticAction"
import { openModal } from "../../actions/modalAction";
import DealerSuggestedStatic from '../dealerSuggesteds/DealerSuggestedStatic';
import About from './About'
import {  Image,Modal } from 'semantic-ui-react'

class Landing extends Component {
  state = {
    modalOpenAbout: false,
  };
  componentDidMount() {
    if(this.props.auth.isAuthenticated){
      this.props.getUserForAuth();
    }
    if(this.props.history.location.pathname ==='/'){
      this.props.hideBackButton()
    }
    this.props.fetchCompanysForMainPage()
    window.scroll(0,0)
  }

  
    //***** */ 
    handleOpenAbout = () => this.setState({ modalOpenAbout: true })
    handleCloseAbout = () => this.setState({ modalOpenAbout: false })

//****************** */
handleContactUsModal = () => {
  this.props.openModal("ContactUsModal");
};
  handlePrivacyPolicyModal = () => {
    this.props.openModal("PrivacyPolicyModal");
  };
  handleCookiesPolicyModal = () => {
    this.props.openModal("CookiesPolicyModal");
  };
  handleTermsAndConditionsModal = () => {
    this.props.openModal("TermsAndConditionsModal");
  };
  //*************** */
       
    //#####################################
    handleSearchPropResiSaleFH = () => {
      this.props.openModal("SearchPropResiSaleModalFH");
    };
    handleSearchPropResiRentFH = () => {
      this.props.openModal("SearchPropResiRentModalFH");
    };
    handleSearchPropComSaleFH = () => {
      this.props.openModal("SearchPropComSaleModalFH");
    };
    handleSearchPropComRentFH = () => {
      this.props.openModal("SearchPropComRentModalFH");
    };
    handleSearchProjectRFH = () => {
      this.props.openModal("SearchProjectRModalFH");
    };
    handleSearchProjectCFH = () => {
      this.props.openModal("SearchProjectCModalFH");
    };
    handleSearchCompanyiesFH = () => {
      this.props.openModal("SearchCompanyModalFH");
    };
    handleSearchRequireComFH = () => {
      this.props.openModal("SearchReqComModalFH");
    };
    handleSearchRequireResiFH = () => {
      this.props.openModal("SearchReqResiModalFH");
    };

    
    //########################################


render() {

 
  return (
    <div >
<Layout onLogoutClick={this.onLogoutClick}>

{/* about modal */}
<Modal
style={{maxWidth:'1140px'}}
open={this.state.modalOpenAbout}
onClose={this.handleCloseAbout}
//size='tiny'
// size='small'
>
<Modal.Content style={{maxWidth:'1140px',padding:'0px'}}>

<About 
handleCloseAbout = {this.handleCloseAbout}
/>
</Modal.Content>


</Modal>
{/*  */}

      <div id="mainP" style={{marginTop:'-20px'}} >
{/* Header: Showcase */}

<section id="showcase" style={{marginTop:'0px'}}  >
    <div className="showcase-content" style={{marginTop:'0px'}} >
   {/*  */}
  <div className="font-24B  pxy-10 nodisplay " style={{textAlign: 'center',lineHeight:'24px',}}>
  <span style={{color:'skyblue'}}>FIND</span>
  <span style={{color:'dodgerblue'}}>PROP</span> 
  <span style={{color:'orange'}}>.IN</span>
  </div>
  {/*  */}
   <div className="font-24B color-yellow pxy-10 font-family-bellMT" style={{textAlign: 'center',lineHeight:'24px',margin:'10px'}}>
    India's Most Trusted Property Site
    </div>
    
    
    {/* nav start */}
    <div style={{display:'flex'}}>
   <nav id='navbar-home' >
    <div className="dropdown-home">

      {/*  */}
      
      <div className="products">
      <button>BUY</button>
        <ul >

          <li ><span
          onClick={this.handleSearchPropResiSaleFH}
          >
          Residential
          </span>
          </li>

          <li >
          <span
          onClick={this.handleSearchPropComSaleFH}
          >
          Commercial
          </span>
          </li> 
          
          <li>
          <span>
          <Link to={`/propresi/BuilderFloorSaleSearchList`} >
          Builder Floors
          </Link>
          </span>
          </li>

        </ul>
      </div>
      {/*  */}
      <div className="products">
        <button>RENT / PG</button>
        <ul >

          <li ><span
          onClick={this.handleSearchPropResiRentFH}
          >
          Residential
          </span>
          </li>

          <li >
          <span
          onClick={this.handleSearchPropComRentFH}
          >
          Commercial
          </span>
          </li> 

        </ul>
      </div>
      <div className="products">
        <button>PROJECTS</button>
        <ul >

          <li ><span
          onClick={this.handleSearchProjectRFH}
          >
          Residential
          </span>
          </li>

          <li >
          <span
          onClick={this.handleSearchProjectCFH}
          >
          Commercial
          </span>
          </li> 
          
        </ul>
      </div>
      <div className="products">
        <button>DEALERS</button>
        <ul >

          <li ><span
          onClick={this.handleSearchCompanyiesFH}
          >
          Top 50
          </span>
          </li>
          
          <li>
          <span>
          <Link to={`/company/FinancerSearchList`} >
          Financers
          </Link>
          </span>
          </li>

          <li>
          <span>
          <Link to="/builder/BuilderSearchList" >
          Builders
          </Link>
          </span>
          </li>

        </ul> 
      </div>
      <div className="products">
      { ((this.props.userA !== null && this.props.userA.userGroup ==='Dealer') ) && (
          <>
            <button>REQUIRE</button> 
        <ul >

          <li ><span
          onClick={this.handleSearchRequireResiFH}
          >
          Residential
          </span>
          </li>

          <li >
          <span
          onClick={this.handleSearchRequireComFH}
          >
          Commercial
          </span>
          </li> 
          
        </ul>
          </>)}
        
      </div>
    </div>
    {/*  */}
         
      
  </nav>
  {/*  */}
  {/*  */}
  <div className="font-24B  pxy-10 nodisplay-tab" style={{textAlign: 'center',lineHeight:'24px',margin:'20px'}}>
  <span style={{color:'skyblue'}}>FIND</span>
  <span style={{color:'dodgerblue'}}>PROP</span> 
  <span style={{color:'orange'}}>.IN</span>
  </div>
  {/*  */}
  
  </div>
      {/* nav end */}
      <div className="font-24B color-yellow pxy-10 font-family-Calibri" style={{textAlign: 'center',lineHeight:'24px',margin:'10px'}}>
    The Most Genuine And Accurate Information About Real Estate In Dwarka Delhi
    </div>
    </div>

  </section>


  {/*static dealer ad  */}
{this.props.staticsDealer && this.props.staticsDealer.length >0 && (

        <div  style={{padding:'10px',lineHeight:'24px', fontSize:'16px',textAlign:'center',backgroundColor:'dodgerblue',color:'white'}} >

  <div ><b>Dealers For Assistance And Consultancy To Buy/Sell/Rent In All Sectors Of Dwarka, Delhi</b></div>

  </div>
)}



      <div className='disflex-DLMP media-600'>
{this.props.staticsDealer.map((item)=>(<DealerSuggestedStatic key={item._id} company={item}/>))}
</div>
{/*  */}


</div>  

{/* Footer */}
<footer id="main-footer" className="bg-dark text-center py-1">
<div className="containerP">
<div className="row" style={{border:'none'}}>
<div className="disflex-nav-footer" style={{ flex: '50%',padding: 20,flexDirection:'column'}}>

<div className = ' border-logo '><Image size='mini' src='/logo.png'  /></div>
  {/*  */}
  <div className='' style={{fontSize:'1.2rem',fontWeight:'bold'}}>
  <span style={{color:'skyblue'}}>FIND</span>
  <span style={{color:'dodgerblue'}}>PROP</span>
  <span style={{color:'orange'}}>.IN</span>
  </div>
  
{/*  */}

{/*  */}
<p ><span id="contact"> 85 Rajeev Nagar, New Delhi-86, INDIA </span></p>
<p>GSTIN : 07AWDPP2321C1Z2</p>
{/*  */}
</div>
<div className="main " style={{ flex: '50%'}}>
<nav id="navbarfoot" className="disflex-nav-footer"> 




    <ul className='disflex-nav-footer-ul'>


    {/* <li><a href="https://findprop.in">Home</a></li> */}
    <li onClick={this.handleOpenAbout}
    className='btnnavAbout'

    >About</li>

     <li>
      <a      
      onClick={this.handleContactUsModal}
      >Contact Us
      </a>
      </li>

      <li>
      <a      
      onClick={this.handlePrivacyPolicyModal}
      >Privacy Policy
      </a>
      </li>

      <li>
      <a
      onClick={this.handleCookiesPolicyModal}
      >Cookies Policy
      </a>
      </li>

      <li>
      <a
      onClick={this.handleTermsAndConditionsModal}
      >Terms & Conditions
      </a>
      </li>

    </ul>
    </nav>

    </div>



</div>

<p > © Copyright 2020 CITSYS, All Rights Reserved</p>
<br/>
</div>
</footer>
<br/><br/>

</Layout>

    </div>
  );
}
}

const mapStateToProps = ({ auth,statics  }) => {

  return {
    auth,
    userA: auth.userA,
    staticsDealer: statics.staticsDealer
  };
};

export default connect(
   mapStateToProps,

 {openModal,getUserForAuth,hideBackButton ,fetchCompanysForMainPage,}
)(Landing);
