import React, { Component } from "react";
import { connect } from "react-redux";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading";
import {fetchShortlistResiRent} from "../../actions/propresiAction";

// import {
//   Icon,
//   Button,
// } from "semantic-ui-react";
import PropResiSummary from './PropResiSummary';
import { openModal } from "../../actions/modalAction";


class ShortlistResiRent extends Component {
  state = {
    pageO:'',
    id:'',
  };
  componentDidMount() {
    const{ offsetRRSH,limitRR}=this.props;
    
    if (this.props.auth.isAuthenticated) {
     
      this.props.fetchShortlistResiRent( limitRR, offsetRRSH);
    }else{
      
      this.props.openModal('LoginModal')
    }
  }
  
  // **************
  //^^^^^^^^^^^^^^^^^^^^^^^^^
    onChange=(e) => {
      this.setState({ [e.target.name]: e.target.value });
    }
  //*******************************************************


  renderGoToPageOrNot=()=> {
    if (this.props.propresisRR.length) {
      return (
        <div className='card-attached border-sb '  >
<div className='disflex-paginator card-paginator media-600'>
{this.renderPaginator()} 
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>
</div>
      );
    }
  }

  

  back = () => {
    const {
      offsetRRSH,
      limitRR 
    } = this.props;
    if (offsetRRSH === 0) {
      return;
    }

    this.props.fetchShortlistResiRent( limitRR, (offsetRRSH - limitRR));
    window.scroll(0,0)
  };

  advance = () => {
    const {
      offsetRRSH,
      limitRR,
      countRR
    } = this.props;
    if (offsetRRSH + limitRR > countRR) {
      return;
    }

    this.props.fetchShortlistResiRent( limitRR, (offsetRRSH + limitRR));
    window.scroll(0,0)
  };
//***************** */
handleGoToPage = () => {

    const {
      limitRR,
      countRR 
    } = this.props;
  // use Math.abs for strict equality ===  to work and safe guard against negative value from user
   if (Math.abs(this.state.pageO) === 0) {
    return; 
  }

const page=(Math.abs(this.state.pageO*limitRR) > countRR)?(Math.ceil(countRR/limitRR-1)):(Math.abs(this.state.pageO)-1)


    this.props.fetchShortlistResiRent( limitRR, (page * limitRR));
    window.scroll(0,0)


};

//****************** */
  renderPaginator=()=> {
    if (this.props.propresisRR.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offsetRRSH}
          limit={this.props.limitRR}
          count={this.props.countRR}
          //values={this.props.values}
        />
      );
    }
  }

  renderLoading=()=> {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
  }

  renderNoProperty=()=> {
    if (this.props.propresisRR.length===0) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>NO PROPERTY AVAILABLE </b></span>

        </div>
      );
    }
  }


  render() {
    
    return (
<div>
<div className='maindiv-820'>

<div>



    {/*  */}
<div className='card-attached  border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >

<div className='heading-2'>
<span  > {this.props.countRR} Shortlisted Residential Properties For Rent|PG</span>
</div>
      
</div>

    <div className='card-attached border-sb pxy-05'  >
    {this.renderLoading()}
{this.renderNoProperty()}
{this.props.propresisRR.map((item)=> (<PropResiSummary  key={item._id}   propresi={item}/>))}


    </div>
{/*  */}
{this.renderGoToPageOrNot()}

<br/><br/><br/><br/>

</div>

</div>
</div>


    );
  }
}


const mapStateToProps = ({ propresis,auth,  }) => {
  const { fetching,limitRR, offsetRRSH, countRR } = propresis;

  return {
    auth,
    fetching,
    limitRR,
    offsetRRSH,
    countRR,
    //form: form.MyPropResiSaleSearch,
    propresisRR: propresis.propresisRR,
    userA: auth.userA,
  };
};

export default connect(
  mapStateToProps,
 {fetchShortlistResiRent,openModal}
)(ShortlistResiRent);
