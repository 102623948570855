import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import writtenNumber from 'written-number';
import {capitalizeFirst} from "../functions/Functions";
import {resetOffsetPropComs} from '../../actions/propcomAction';
import { closeModal } from "../../actions/modalAction";
import {  Form, Button,  Popup, } from 'semantic-ui-react';
import NumberInput from "../form/NumberInput";
import SelectInput from '../form/SelectInput';


import {
  propertyType,
 
  locality,
 
} from './Data';


const PropComSaleSearch = props => {
  const { maxPrice, minPrice } =
    props.state.form &&
    props.state.form.PropComSaleSearch !== undefined &&
    props.state.form.PropComSaleSearch.values !== undefined &&
    props.state.form.PropComSaleSearch.values;
  const { handleSubmit,  reset, submitting } = props;

  const projectName = () => {
    return props.state.projectCs.projectCsForData.map(value => ({
      key: value.projectName,
      text: `${value.projectName}` ,
      value: value.projectName,style:{fontSize:'18px'}
    }));
  };
  
  const onSubmit = () => {
      props.closeModal()
    props.resetOffsetPropComs();
    props.closeModal()
    props.history.push(`/propcom/PropComSaleSearchList`);

  };

  return (
<div style={{maxWidth:'700px',margin:'auto'}}>

{/**/}
<div className='margin-auto-H' >
      <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
        {/*  */}
        Search Commercial Property Available for Sale
    {/*  */}
    </div>
    </div>
    {/**/}
  
    <div className="card-form mt-10">
          <div  >

          <Form
          onSubmit={handleSubmit(onSubmit)}
          >
             #  Leave All Field Blank (Clear Values) to View All Available Properties 
           <br /><br />
    <Button
              color="teal"
              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>
            
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="blue"
              type="submit"
              disabled={submitting}
            >
              Search
            </Button>
            <br/><br/>
            <label >Property Type</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search All Available Property Type or
        Enter Single or Multiple Values to Search Those Property Type
            </Popup>
            <Field
              name="propertyTypeA"
              placeholder="Property Type"
              type="text"
              options={propertyType()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />


            <label >
              Minimum Price{' '}
              <span>
                ({minPrice && capitalizeFirst(writtenNumber(minPrice, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Enter Lower Value of your Budget Range
            </Popup>
            <Field
              name="minPrice"
              placeholder="Minimum Price"
              type="number"
              component={NumberInput}
            />
            <label >
              Maximum Price{' '}
              <span>
                ({maxPrice && capitalizeFirst(writtenNumber(maxPrice, {
                        lang: 'enIndian'
                      })) }
                      ){' '}
                
              </span>
            </label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Enter Higher Value of your Budget Range
            </Popup>
            <Field
              name="maxPrice"
              placeholder="Maximum Price"
              type="number"
              component={NumberInput}
            />
            <label >Minimum Carpet Area</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Enter Lower Value of your Carpet Area Range
            </Popup>
            <Field
              name="minArea"
              placeholder="Minimum Carpet Area"
              type="number"
              component={NumberInput}
            />
            <label >
              Maximum Carpet Area
            </label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Enter Higher Value of your Carpet Area Range
            </Popup>
            <Field
              name="maxArea"
              placeholder="Maximum Carpet Area"
              type="number"
              component={NumberInput}
            />


          <label >Complex/Project Name</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search Properties in All Complex/Project of Dwarka or
        Enter Single or Multiple Values to Search Properties in Those Complex/Project
            </Popup>
            <Field
              name="projectNameA"
              placeholder="Enter Key Word To Search"
              type="text"
              options={projectName()}
              search={true}
              multiple={true}
              component={SelectInput}
            />

            <label >Locality</label> {' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Leave it Blank to Search Properties in All Sectors of Dwarka or
        Enter Single or Multiple Values to Search Properties in Those Sectors
            </Popup>
            <Field
              name="localityA"
              placeholder="Enter Key Word To Search"
              type="text"
              options={locality()}
              //search={true}
              multiple={true}
              component={SelectInput}
            />


            <br />

            <Button
              color="blue"
              type="submit"
              disabled={submitting}
              // as={Link}
              // to={`/propcom/PropComSaleSearchList`}
            >
              Search
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={ submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
              color="teal"

              type="button"
              onClick={()=>props.closeModal()}

            >
              Cancel
            </Button>

          </Form>
          <br/>
            </div>
            </div>
            </div>
  );
};
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA,
  };
};
export default connect(
  mapStateToProps,
   {resetOffsetPropComs,closeModal}
)(
  reduxForm({
    form: 'PropComSaleSearch', // a unique identifier for this form
    destroyOnUnmount: false
    // validate
  })(withRouter(PropComSaleSearch)) )
