import React, { Component } from "react";
import { Link, } from "react-router-dom";
import { connect } from "react-redux";
import writtenNumber from "written-number";
import format from "date-fns/format";
import Paginator from "../paginator/Paginator";
import Loading from "../common/Loading"; 
import {searchReqResis} from "../../actions/reqresiAction";
import { openModal } from "../../actions/modalAction";
import { getUserForAuth } from "../../actions/authAction";

import {
  Icon,
  Button,
  Modal,
} from "semantic-ui-react";
import UnlockContactDetail from './UnlockContactDetail';


class ReqResiSearchList extends Component {
  state = {
    pageO:'',
      id:'',
      modalOpen: false,
      modalOpenL: false,
      open: false,
      result: "show the modal to capture a result"
    };
  componentDidMount() {
    const values =
      (this.props.form !== undefined && this.props.form.values) || {};
    
    const {offset, limit} = this.props.reqresis;
    this.props.searchReqResis(values, limit,offset);
  }
    
  handleSearchReqResi = () => {
    this.props.openModal("SearchReqResiModal");
    };
//**************** */
handleRefresh = () =>{
	const {offset,limit} = this.props;
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    this.props.getUserForAuth()
    this.props.searchReqResis(values, limit, offset);
    
}
	//**************** */
  
  handleModalOpen = (value) => {
    if (!this.props.userA) {
      this.props.openModal('LoginModal')
      }else{
      this.setState({ modalOpen:true , valueCUnlock: value});
      }	
  }
  handleModalClose = () => this.setState({ modalOpen:false, });

  //*************************** */
 
    
    capitalizeFirst=(input)=> {
    var words = input.split(' ');
    var CapitalizedWords = [];
    words.forEach(element => {
    CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
    });
    return CapitalizedWords.join(' ');
    }
    //^^^^^^^^^^^^^^^^^^^^^^^^^
    onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
    }
    //*******************************************************
    renderList=(reqresi)=> {
    const {
    _id,
    creditUsed,
    veiwLimit,
    dealers,
    requiredFor,
    propertyType,
    bedroom,
    bathroom,
    additionalRooms,
    furnishedStatus,
    projectName,
    locality,
    budgetMin,
    budgetMax,
    areaCarpetMin,
    areaCarpetMax,
    createdAt,
    } = reqresi;

    const {creditExpiredOn,balanceCreditReq,}=this.props.userA !== null && this.props.userA
  const companyId = this.props.userA !== null && this.props.userA.companyId
  const valueCUnlock = {id:_id,creditExpiredOn,balanceCreditReq,creditUsed}

    const renderpropertyType = () => {
    return propertyType.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };
    const renderbedroom = () => {
    return bedroom.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };

    const renderbathroom = () => {
    return bathroom.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };

    const renderadditionalRooms = () => {
    return additionalRooms.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };
    const renderfurnishedStatus = () => {
    return furnishedStatus.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };
    const renderprojectName = () => {
    return projectName.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };
    const renderlocality = () => {
    return locality.map(item => {
    return (
    <span key={item}>
    {" "}
    <b>{item} <span className='pipe'>|</span></b>
    </span>
    );
    });
    };

    const checkID = dealers !== undefined && dealers.filter(val => {

      return val.toString() === companyId;
      });

      const renderUnlockedButton = () =>{
        if(checkID.length > 0) {
          return (
            <Button
            disabled
            floated="right"
            color="orange"
            >
            Contact Detail Unlocked
            </Button>
            ) 
        } }
      
        const renderUnlockButton = () =>{
          if(checkID.length < 1) {
            return (
              <Button
              floated="right"
              color="orange"
              onClick={() => this.handleModalOpen(valueCUnlock)}
              >
              Unlock Contact Detail
              </Button>
              ) 
          } }
        
    const renderUnlockButtonOrNot = () =>{
    if( (this.props.userA !== null && this.props.userA.userGroup ==='Dealer')
    &&   (dealers !== undefined && dealers.length < veiwLimit) ) {
    return renderUnlockButton()}
    }


    //**************************
    //const daysAgo = Math.round((Date.now()-Date.parse(createdAt))/(24*60*60*1000));

    return (
    <div key={_id}>
      {/*  */}
<div className='card-attached border-radius-top border-db' style={{textAlign:'center',backgroundColor:'skyblue'}} >
	
	<div className='heading-2'>
	<span  > Requirement For Residential Property For {requiredFor}</span>
	</div>

	</div>

	<div className='card-attached border-radius-bottom border-db-bottom border-sb  mb-010'  >

  {/**/}
  <br/>
  <div className="disflex-paginator" >
    
    
    <Button
  // basic
  // inverted
  floated="right"
  color="teal"
  type="button"
  as={Link}
  to={`/reqresi/ReqResiDetail/${_id}`}
  >
  Detail
  </Button> 
  {renderUnlockButtonOrNot()}
  {renderUnlockedButton()}
  
  
  </div>
  
  {/**/}
  		{/*  */}
    

    <div style={{padding:'10px',}} >

    <div >
    {requiredFor && (
    <div className='row'>
    <div className='side'>
    <b>Require For</b>
    </div>
    <div className='main'>
    <b>{requiredFor}</b>
    </div>

    </div>
    )}
    </div>

    <div >
    {propertyType != null &&
    propertyType.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Type Of Property</b>
    </div>
    <div className='main'>
    {renderpropertyType()}
    </div>

    </div>
    )}
    </div>

    <div >
    {bedroom != null &&
    bedroom.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Bedroom</b>
    </div>
    <div className='main'>
    {renderbedroom()}
    </div>

    </div>
    )}
    </div>
    <div >
    {bathroom != null &&
    bathroom.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Bathroom</b>
    </div>
    <div className='main'>
    {renderbathroom()}
    </div>

    </div>
    )}
    </div>
    <div >
    {additionalRooms != null &&
    additionalRooms.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Additional Rooms</b>
    </div>
    <div className='main'>
    {renderadditionalRooms()}
    </div>

    </div>
    )}
    </div>

    <div >
    {furnishedStatus != null &&
    furnishedStatus.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Furnished Status</b>
    </div>
    <div className='main'>
    {renderfurnishedStatus()}
    </div>

    </div>
    )}
    </div>


    <div >
    {budgetMin > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Minimum Budget</b>
    </div>
    <div className='main'>
    <Icon className="rupee" ></Icon>
    <b> {budgetMin}</b>
    <b>
    {' '} ({this.capitalizeFirst(writtenNumber(budgetMin, {
    lang: "enIndian"
    }))}
    ){" "}
    </b>
    </div>

    </div>
    )}
    </div>

    <div >
    {budgetMax > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Maximum Budget</b>
    </div>
    <div className='main'>
    <Icon className="rupee" ></Icon>
    <b> {budgetMax}</b>
    <b>
    {' '} ({this.capitalizeFirst(writtenNumber(budgetMax, {
    lang: "enIndian"
    }))}
    ){" "}
    </b>
    </div>

    </div>
    )}
    </div>
    <div >
    {areaCarpetMin > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Minimum Carpet Area</b>
    </div>
    <div className='main'>

    <b> {areaCarpetMin}</b>
    <b>
    {' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMin, {
    lang: "enIndian"
    }))}
    ){" "}sq ft
    </b>
    </div>

    </div>
    )}
    </div>

    <div >
    {areaCarpetMax > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Maximum Carpet Area</b>
    </div>
    <div className='main'>

    <b> {areaCarpetMax}</b>
    <b>
    {' '} ({this.capitalizeFirst(writtenNumber(areaCarpetMax, {
    lang: "enIndian"
    }))}
    ){" "}sq ft
    </b>
    </div>

    </div>
    )}
    </div>

    <div >
	{projectName  != null &&
	projectName.length > 0 && (
	<div className='rowERP-projName'>
	<div className='side'>
	<b>Complex/Project Preferred</b>
	</div>
	<div className='main display-linebreak'>
	{renderprojectName ()}
	</div>

	</div>
	)}
	</div>

    <div >
    {locality != null &&
    locality.length > 0 && (
    <div className='row'>
    <div className='side'>
    <b>Locality</b>
    </div>
    <div className='main'>
    {renderlocality()}
    </div>
    </div>
    )}
    </div>


    <div >
    {createdAt && (
    <div className='row'>
    <div className='side'>
    <b>Created On</b>
    </div>
    <div className='main'>

    <b>{format(new Date(createdAt), "dd-MM-yyyy")}</b>
    </div>

    </div>
    )}
    </div>
  
    </div >{/* end  3rd div */}

    </div>
    </div>

    );
    }
    back = () => {
    const {
    offset,
    limit

    } = this.props;
    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    if (offset === 0) {
    return;
    }

    this.props.searchReqResis(values, limit, (offset - limit));
    window.scroll(0,0)
    };

    advance = () => {
    const {
    offset,
    limit,
    count

    } = this.props;

    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    if (offset + limit > count) {
    return;
    }

    this.props.searchReqResis(values, limit, (offset + limit));
    window.scroll(0,0)
    };
    //***************** */
    handleGoToPage = () => {
    const {

    limit,
    count

    } = this.props;

    const values =
    (this.props.form !== undefined && this.props.form.values) || {};
    // use Math.abs for strict equality ===  to work and safe guard against negative value from user
    if (Math.abs(this.state.pageO) === 0) {
    return; 
    }

    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)


    this.props.searchReqResis(values, limit, (page * limit));
    window.scroll(0,0)


    };

    //****************** */
    renderPaginator=()=> {
    if (this.props.reqresis.length) {
    return (
    <Paginator
    advance={this.advance}
    back={this.back}
    offset={this.props.offset}
    limit={this.props.limit}
    count={this.props.count}
    //values={this.props.values}
    />
    );
    }
    }

    renderLoading() {
    if (this.props.fetching===true) {
      return (<Loading/>)
    }
    }


    render() {
    return (
    
    <div className='maindiv-820'>

    <div>

    

    <Modal
    open={this.state.modalOpen}
    onClose={this.handleModalClose}
    basic
    size='small'
    >

    <Modal.Content>
    <UnlockContactDetail 
  handleModalClose = {this.handleModalClose}
  handleRefresh ={this.handleRefresh}
  valueCUnlock={this.state.valueCUnlock}

  />
    </Modal.Content>

    </Modal>
{/**/}
<div className='cashbook-H box-shadow mb-10'>

<div
style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',marginLeft:'5px',padding:'5px'}}
onClick={this.handleSearchReqResi}>
      <span className='icon-button2 border1' > <Icon name='search' /></span >
    </div>



<div  style={{display: 'flex',flex: '85%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{this.props.count} Requirement For Residential Property in {this.props.city}
</div>
</div>


</div>
{/**/}
<div className='card-attached'>
    {this.renderLoading()}

    {this.props.reqresis.map(this.renderList)}

    </div> 
{/*  */}

<div className='card-attached'  >

<div className='disflex-paginator card-paginator border media-600'>
{this.renderPaginator()}
<div className="disflex-paginator  " >
  <div className= 'font-paginator'>Go To Page</div>
  <form>
        <input
        className='inputPage'
          placeholder="number"
          name="pageO"
          type="number"
          value={this.state.pageO}
          onChange={this.onChange}
        />
        <span  className="btnNumber"  onClick={this.handleGoToPage} >
          Submit
        </span>
      </form>

        
</div>
<br/>
</div>

</div>
{/*  */}

    <br/><br/><br/><br/> 

    </div>
    


    </div>
   


    );
    }
    }


    const mapStateToProps = ({ reqresis, selection, form,auth }) => {
    const {city, fetching,limit, offset, count } = reqresis;

    return {
      city,
    fetching,
    limit,
    offset,
    count,
    form: form.ReqResiSearch,
    selection,
    reqresis: reqresis.reqresis,
    userA: auth.userA,
    };
    };
    export default connect(
    mapStateToProps,
    {getUserForAuth,searchReqResis,openModal}
    )(ReqResiSearchList);
