import {
  FETCH_REQRESIS,
  FETCH_REQRESI,
  UPDATE_REQRESI,
  SEARCH_REQRESIS,
  FETCHING_REQRESIS,
} from '../actions/types';

const INITIAL_STATE = {
  city:'', 
  fetching:true,
  reqresis: [],
  reqresisULock: [],
  reqresi: null,
  offset: 0,
  offsetULock: 0,
  limit: 10
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REQRESIS:
      return { ...state, ...action.payload };
    case FETCH_REQRESI:
      return { ...state, reqresi: action.payload };
   
    case UPDATE_REQRESI:
      return {
        ...state,
        reqresi: { ...state.reqresi, ...action.payload }
      };
    case SEARCH_REQRESIS:
     
      return { ...state, ...action.payload };
      case FETCHING_REQRESIS:
        return { ...state, fetching: action.payload };
    
    default:
      return state;
  }
};
