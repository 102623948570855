import axios from "axios";
import { toastr } from "react-redux-toastr";
import { openModal, closeModal } from "./modalAction";
import { getUserForAuth } from "./authAction";

import {
    FETCH_USER,
  FETCH_DEALERSUGGESTEDS,
  SEARCH_DEALERSUGGESTEDS,
  FETCHING_DEALERSUGGESTEDS,
} from "./types";

export const resetFetching = () => async dispatch => {
  dispatch({ type:FETCHING_DEALERSUGGESTEDS, payload: true });
};

//search  DealerSuggesteds
export const searchDealerSuggesteds = (
  values,
  limit,
  offset
) => async dispatch => {
  
  try {
    const res = await axios.post("/api/DealerSuggestedSearch", {
      values,
      offset
    });

    dispatch({ type: SEARCH_DEALERSUGGESTEDS, payload: res.data });
    dispatch({ type:FETCHING_DEALERSUGGESTEDS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//*********************************
//fetch all DealerSuggesteds for Advertisement by one Dealer
export const fetchAdByDealer = ( values,
  limit,
  offset ) => async dispatch => {

  try {
    const res = await axios.post("/api/AdByDealer",{
      values,
      offset
    } );

    
    dispatch({ type: FETCH_DEALERSUGGESTEDS, payload: res.data });
    dispatch({ type:FETCHING_DEALERSUGGESTEDS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};
//*********************************
//fetch all DealerSuggesteds for Advertisement by one Dealer
export const fetchAdByDealerToAddPropToStatic = ( values,
  limit,
  offset ) => async dispatch => {

  try {
    const res = await axios.post("/api/AdByDealerToAddPropToStatic",{
      values,
      offset
    } );


    dispatch({ type: FETCH_DEALERSUGGESTEDS, payload: res.data });
    dispatch({ type:FETCHING_DEALERSUGGESTEDS, payload: false });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};
//*****************************  
//fetch all DealerSuggesteds for one property
export const fetchDealerSuggestedsForProp = (
  id,
  limit,
  offset) => async dispatch => {
    
  try {
    const res = await axios.post("/api/DealerSuggestedListForProp",{
      id:id,
      offset
    } );
   
    dispatch({ type: FETCH_DEALERSUGGESTEDS, payload: res.data });
    dispatch({ type:FETCHING_DEALERSUGGESTEDS, payload: false });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again fetchDealerSuggesteds");
  }
};
//fetch all DealerSuggesteds for one project
export const fetchDealerSuggestedsForProj = (
  id,
  limit,
  offset) => async dispatch => {
    
  try {
    const res = await axios.post("/api/DealerSuggestedListForProj",{
      id:id,
      offset
    } );
    
    dispatch({ type: FETCH_DEALERSUGGESTEDS, payload: res.data });
    dispatch({ type:FETCHING_DEALERSUGGESTEDS, payload: false });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again fetchDealerSuggesteds");
  }
};


//update DealerSuggested to extend validity
export const updateDealerSuggestedForRenew = (values) => async dispatch => {
  try {
    const res = await axios.put(`/api/DealerSuggestedEditForRenew`, values);

      if (res.data.msg ==='success') {
    
    toastr.success('Validity Extended');
  }
      dispatch({ type: FETCH_USER, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
dispatch(openModal('LoginModal')); 
toastr.error(`${JSON.stringify(error.response.data) }`);
}else if(error){
toastr.error("Oops", "Something went wrong.Please Try Again");
}
  }

};


//update Dealer Ranking
export const updateDealerRanking = () => async (dispatch) => {
  try {
    dispatch(getUserForAuth());
     await axios.put(`/api/UpdateDealerRanking`);
     
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again updateDealerRanking");
  }
};

// create DealerSuggested
export const createDealerSuggested = (values) => async () => {
  try {
  
    const res = await axios.post(`/api/DealerSuggestedCreate`, values);
    if (res.data.msg ==='success'){
    toastr.success('Ad Created Successfully');
    }
    
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//************************************************************************
